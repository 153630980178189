import React, {Component} from 'react';
import {RouteComponentProps} from "react-router";
import {IonButton, IonContent, IonIcon, IonList, IonListHeader, IonSpinner} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {CenteredColumn} from "../../common/Centered";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {MainStore, MainStoreInjected} from "../../../mainStore";
import {DeviceEditStore, DeviceEditStoreInjected} from "./deviceEditStore";
import {PreventLeavingGuard} from "../../common/PreventLeavingGuard";
import {DeviceEditHeader} from "./components/DeviceEditHeader";
import {getModelInstanceName} from "../devicesCommon";
import {DeviceEditPlace} from "./components/DeviceEditPlace";
import {ConnectionTypeCaption, getEnumCaption, Role} from "../../../cuba/enums";
import {DeviceEditNotifications} from "./components/DeviceEditNotifications";
import {DeviceEditTasks} from "./components/DeviceEditTasks";
import {DeviceEditParams} from "./components/DeviceEditParams";
import {DeviceEditData} from "./components/DeviceEditData";
import {deviceEditStore} from "../../../index";
import {LogCreateModal} from "./LogCreateModal";
import {PrinteraLogList} from "../../common/PrinteraLogList";
import {ConsumablesReplaceModal} from './components/ConsumablesReplaceModal';
import {action, observable} from 'mobx';
import {colorFill} from 'ionicons/icons';

type Props =
  RouteComponentProps<{ deviceId: string }>
  & Pick<MainStore, 'deviceStates' | 'departments' | 'reloadTasks' | 'role'>
  & { deviceEditStore: DeviceEditStore }

@inject((stores: MainStoreInjected & DeviceEditStoreInjected) => ({
  deviceStates: stores.mainStore.deviceStates,
  departments: stores.mainStore.departments,
  role: stores.mainStore.role,

  deviceEditStore: stores.deviceEditStore
}))

@observer
export class ClientDeviceEdit extends Component<Props> {

  @observable showConsModal = false;

  componentDidMount(): void {
    const {match} = this.props;

    if (match && match.params.deviceId) {
      this.props.deviceEditStore.loadDevice(match.params.deviceId);
    }
  }

  render() {

    const {history, role, deviceEditStore} = this.props;
    const {
      device, deviceInitial, saving, hasUnsavedData, activeAssignment, loadDevice, setSaveResult, logItems,
      showConsReplacement, loadDeviceTasks, loadMoreDeviceTasks, hasMoreDeviceTasks, deviceTasks, deviceTasksStatus,
      deviceTasksTotalCount
    } = this.props.deviceEditStore;

    if (!deviceInitial) {
      return (
        <CenteredColumn>
          <IonSpinner/>
        </CenteredColumn>
      )
    }

    const {deviceModel, serialNumber, owner} = deviceInitial;

    return (
      <>
        <PreventLeavingGuard preventLeaving={hasUnsavedData}/>
        <DeviceEditHeader saving={saving} history={history} deviceEditStore={this.props.deviceEditStore}/>

        <IonContent>

          <IonList>
            <IonListHeader>
              Устройство
            </IonListHeader>

            <PrinteraEditItem caption="Модель" readonly={true}
                              value={getModelInstanceName(deviceModel)}/>

            <PrinteraEditItem caption="Серийный номер" value={serialNumber} readonly={true}/>

            <PrinteraEditItem caption="Инв. номер" value={device.deviceNumber} readonly={true}/>

            <PrinteraEditItem caption="Принадлежит" value={owner && owner.clientName} readonly={true}/>
          </IonList>

          <DeviceEditPlace departments={this.props.departments}
                           readonly={role !== Role.CLIENT_ADMIN}
                           deviceEditStore={this.props.deviceEditStore}/>

          {role === Role.CLIENT && //for CLIENT we are show only one field, instead of full section 'Params' for CLIENT_ADMIN
          <IonList>
            <IonListHeader>Параметры</IonListHeader>
            <PrinteraEditItem caption='Как подключено' readonly={true}
                              value={getEnumCaption(activeAssignment.connectionType, ConnectionTypeCaption)}/>
          </IonList>}

          {role === Role.CLIENT_ADMIN && //'Params' and 'Data' for CLIENT_ADMIN only
          <>
            <DeviceEditParams deviceEditStore={deviceEditStore}/>

            <DeviceEditData deviceEditStore={deviceEditStore} readonly={true}/>
          </>}

          <DeviceEditTasks tasks={deviceTasks}
                           activeAssignmentId={activeAssignment.id}
                           role={role}
                           hasMore={hasMoreDeviceTasks}
                           loadMore={loadMoreDeviceTasks}
                           loading={deviceTasksStatus === "pending"}
                           totalCount={deviceTasksTotalCount}
                           onCreateTask={() => {
                             loadDevice(device.id);
                             loadDeviceTasks();
                             setSaveResult('Задача создана');
                           }}/>

          {role === Role.CLIENT_ADMIN && //'Logs' for CLIENT_ADMIN only
          <>
            <LogCreateModal/>

            <PrinteraLogList logs={logItems}/>

            {/* Consumables replacement */}

            {showConsReplacement &&
            <IonButton color="primary" expand='block' onClick={() => this.showConsModal = true}>
              Замена РМ
              <IonIcon icon={colorFill} slot='end' />
            </IonButton>}

            {this.showConsModal &&
            <ConsumablesReplaceModal showConsModal={this.showConsModal} resetConsModal={this.resetConsModal}/>}

          </>}

        </IonContent>

        <DeviceEditNotifications deviceEditStore={this.props.deviceEditStore}/>

      </>);
  }

  @action resetConsModal = () => {
    deviceEditStore.resetConsModel();
    this.showConsModal = false;
  };

}
