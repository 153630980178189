import {action, observable, toJS} from 'mobx';
import {SerializedEntity} from '@cuba-platform/rest';
import {PrinteraUser} from '../../../cuba/entities/printers_PrinteraUser';
import {
  printers_MobileApiService_createTask_params,
  restServices
} from '../../../cuba/services';
import {cubaREST, mainStore} from '../../../index';
import {FileDescriptorInfo} from '../../common/FileUploadItem';
import {PrinteraTask} from '../../../cuba/entities/printers_PrinteraTask';
import {PrinteraTaskTypeCodes} from '../../../cuba/enums';
import {isOffline} from '../../../util/restUtils';
import {PrinteraTaskType} from '../../../cuba/entities/printers_PrinteraTaskType';
import {InputChangeEventDetail} from '@ionic/core';


export class TaskData {
  @observable printeraTaskType?: PrinteraTaskType;
  @observable header: string = '';
  @observable description = '';
  @observable files: Array<FileDescriptorInfo | undefined> = [undefined];
  @observable assigneeId?: string;
  @observable dueDate: string | null = null;
}

export class TaskCreateStore {

  static readonly NAME = 'taskCreateStore';

  @observable taskAssignees: SerializedEntity<PrinteraUser>[] = [];

  taskData = new TaskData();
  @observable errorMsg?: string;
  @observable saving = false;

  @action loadAvailableTaskAssignees = () => {
    return restServices.printers_MobileApiService.getEmployees(cubaREST, {handleAs: 'json'})()
      .then((employees) => {
        //todo rest services return value types - is it possible ?
        const assignees = employees as SerializedEntity<PrinteraUser>[];
        this.taskAssignees = assignees
          ? assignees.sort((a, b) => a._instanceName.localeCompare(b._instanceName)) : [];
      });
  };

  @action saveTask = (deviceAssignmentId: string, onSave: (task: PrinteraTask) => void) => {
    this.saving = true;
    const {printeraTaskType, description, files, header} = this.taskData;
    const typeId = printeraTaskType?.id || '';

    const taskCreationParams: printers_MobileApiService_createTask_params = {
      typeId: typeId,
      header: header,
      deviceAssignmentId: deviceAssignmentId,
      assignToId: null,
      description: toJS(description),
      clientId: null,
      uploadedFilesDescriptors: files.slice().reduce<FileDescriptorInfo[]>((acc, fdi) => {
        if (fdi != null) acc.push(fdi);
        return acc;
      }, []),
    };

    return restServices.printers_MobileApiService
      .createTask(cubaREST, {handleAs: 'json'})(taskCreationParams)
      .then((savedTask) => {
        this.saving = false;
        this.taskData = new TaskData();
        onSave(savedTask);
      })
      .catch(action(e => {
        this.saving = false;
        this.errorMsg = isOffline(e) ? 'Отсутствует интернет соединение' : 'Ошибка сервера при сохранении данных';
      }));
  };


  isInternal = (taskType: PrinteraTaskType | null | undefined) => {
    const entities = mainStore.taskTypesData ? mainStore.taskTypesData.entities : null;
    if (!taskType || !entities) return false;

    const type = entities.find(tt => tt.id === taskType.id);
    return type && type.code === PrinteraTaskTypeCodes.Internal_task;
  };

  changeDataValue = (name: keyof TaskData) => action((event: CustomEvent<InputChangeEventDetail>) => {
    this.taskData[name] = event.detail.value || '' as any;
  });

  resetError = () => this.errorMsg = undefined;
}

export interface TaskCreateStoreInjected {
  taskCreateStore: TaskCreateStore
}


