import React, {Component} from "react";
import {IonButton, IonCheckbox, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {DeviceEditStoreInjected} from "../deviceEditStore";
import {PrinteraEditItem} from "../../../common/PrinteraEditItem";
import {ConnectionTypeCaption} from "../../../../cuba/enums";
import {PrinteraEnumSelect} from "../../../common/PrinteraEnumSelect";
import {inject, observer} from "mobx-react";
import {DeviceSearchModal} from "../DeviceSearchModal";
import {computed, observable} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {DeviceAssignment} from "../../../../cuba/entities/printers_DeviceAssignment";

const injectStoresToProps = (stores: DeviceEditStoreInjected) => {
  return {
    deviceEditStore: stores.deviceEditStore
  }
};

@inject(injectStoresToProps)
@observer
export class DeviceEditParams extends Component<DeviceEditStoreInjected> {

  @observable showDeviceSearchModal: boolean = false;

  @computed
  private get isRedirectionSet() {
    const {activeAssignment} = this.props.deviceEditStore;
    return activeAssignment && activeAssignment.redirection && activeAssignment.redirection.id;
  }

  render() {

    const {deviceEditStore} = this.props;
    const {idnStatusCaption, manualInputDisabled, activeAssignment} = deviceEditStore;

    return <>
      <IonList>
        <IonListHeader>Параметры</IonListHeader>

        <PrinteraEditItem caption="Статус идентификации" value={idnStatusCaption} readonly={true}/>

        <PrinteraEnumSelect caption='Как подключено'
                            value={activeAssignment.connectionType}
                            onValueChange={e => activeAssignment.connectionType = e.detail.value}
                            captions={ConnectionTypeCaption}/>

        {/*disable and set to true for USB and NOT_CONNECTED connection types*/}
        <IonItem><IonLabel>Ручной Ввод:</IonLabel>
          <IonCheckbox
            disabled={manualInputDisabled}
            checked={activeAssignment.manualInput === true || manualInputDisabled}
            onIonChange={e => activeAssignment!.manualInput = e.detail.checked}/>
        </IonItem>

        {/*show ip only for not checked manual input and when manual input is allowed*/}
        {!activeAssignment.manualInput && !manualInputDisabled
        && <PrinteraEditItem caption="IP адрес" value={activeAssignment.currentIpAddress}
                             onValueChange={e => activeAssignment.currentIpAddress = e.detail.value}/>}
      </IonList>

      <IonList>
        {this.isRedirectionSet &&
        <IonListHeader>Перенаправление печати
          <IonButton expand="full" fill="clear" size="small"
                     onClick={() => activeAssignment.redirection = null}>
            отменить
          </IonButton>
        </IonListHeader>}

        <IonItem>
          {this.isRedirectionSet &&
          <IonButton expand="full" fill="clear" size="small" class="ion-text-wrap"
                     onClick={() => this.showDeviceSearchModal = true}>
            {(activeAssignment.redirection as Partial<SerializedEntity<DeviceAssignment>>)._instanceName}
          </IonButton>}

          {!this.isRedirectionSet &&
          <IonButton expand="full" fill="clear" size="default"
                     onClick={() => this.showDeviceSearchModal = true}>
            задать перенаправление печати
          </IonButton>}
        </IonItem>

      </IonList>

      <DeviceSearchModal
        showModal={this.showDeviceSearchModal}
        onClose={() => this.showDeviceSearchModal = false}
        onSave={(deviceAssignment: SerializedEntity<DeviceAssignment>) => {
          activeAssignment.redirection =
            deviceAssignment && deviceAssignment.id ?
              {
                id: deviceAssignment.id,
                _instanceName: deviceAssignment._instanceName
              } as Partial<SerializedEntity<DeviceAssignment>>
              : null;
          this.showDeviceSearchModal = false;
        }}/>
    </>;
  }

}

