import {toJS} from "mobx";
import {
  ConnectionTypeCaption, getEnumCaption,
  getEnumEntries,
  IdentificationStatusCaption,
  PollStatusCaption, Role
} from "../../../cuba/enums";
import {inject, observer} from "mobx-react";
import {Component} from "react";
import {IonButton, IonButtons, IonHeader, IonIcon, IonList, IonSearchbar, IonToolbar} from "@ionic/react";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {PrinteraSelect} from "../../common/PrinteraSelect";
import React from "react";
import {MainStoreInjected} from "../../../mainStore";
import {DeviceListStoreInjected} from "./deviceListStore";
import {DeviceListFilterData} from "./DeviceListFilterData";
import {PrinteraEnumSelect} from "../../common/PrinteraEnumSelect";
import {IdentificationStatus, PollStatus} from "../../../cuba/enums/enums";
import {options} from 'ionicons/icons';


type Props = {
  isExpanded: boolean
  expandFilter: () => void
  applyFilter: (fd: DeviceListFilterData) => void
}


const injectStoresToProps = (stores: MainStoreInjected & DeviceListStoreInjected) => {
  const {role} = stores.mainStore;
  const {filterData, setSearchString} = stores.deviceListStore;
  return {role, filterData, setSearchString};
};

@inject(injectStoresToProps)
@observer
export class DeviceListFilter extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  render() {

    const {isExpanded, expandFilter, applyFilter, role, setSearchString} = this.props;
    const filterData = this.props.filterData as DeviceListFilterData;
    const {caption, getConnectionType, setConnectionType, isFilterSet} = filterData;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonSearchbar
              placeholder='Серийный или инвентарный номер'
              value={toJS(filterData.searchString)}
              onIonChange={e => setSearchString!(e.detail.value)}/>
            <IonButtons slot='end'>

              {!isExpanded &&
              <IonButton slot='icon-only' onClick={expandFilter}>
                <IonIcon icon={options}/>
              </IonButton>}

            </IonButtons>
          </IonToolbar>


          {isExpanded &&
          <IonToolbar>
            <IonList>
              <PrinteraEditItem caption="Номер" value={filterData.number}
                                onValueChange={e => filterData.number = e.detail.value}/>

              <PrinteraEditItem caption="Кабинет" value={filterData.locationName}
                                onValueChange={e => filterData.locationName = e.detail.value}/>

              <PrinteraEditItem caption="Этаж" value={filterData.locationFloor}
                                onValueChange={e => filterData.locationFloor = e.detail.value}/>

              <PrinteraEnumSelect caption='Как подключено' value={getConnectionType()}
                                  captions={ConnectionTypeCaption}
                                  onValueChange={e => {
                                    const id = e.detail.value;
                                    setConnectionType(id, getEnumCaption(id, ConnectionTypeCaption))
                                  }}/>

              {role !== Role.CLIENT && <PrinteraSelect caption='Статус опроса' value={filterData.pollStatus}
                                                      entities={getEnumEntries(PollStatus, PollStatusCaption)}
                                                      onValueChange={e => filterData.pollStatus = e.detail.value}/>}

              {role !== Role.CLIENT && <PrinteraSelect caption='Статус идентификации' value={filterData.idnStatus}
                                                      entities={getEnumEntries(IdentificationStatus, IdentificationStatusCaption)}
                                                      onValueChange={e => filterData.idnStatus = e.detail.value}/>}
            </IonList>
          </IonToolbar>
          }

          {!isExpanded && isFilterSet &&
          <IonToolbar>
            <IonButtons>
              <IonButton style={{textAlign: 'left', height: 'auto', padding: '10px 0 10px 0'}}
                         text-wrap fill='clear' expand='full' onClick={expandFilter}>
                Фильтр: {caption}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          }

          <IonToolbar>
            <IonButtons style={{width: '100%'}}>
              <IonButton onClick={() => applyFilter(filterData)}>
                применить фильтр
              </IonButton>
              <IonButton style={{width: '100%'}} onClick={() => {
                applyFilter(new DeviceListFilterData());
              }}>
                сбросить
              </IonButton>
            </IonButtons>
          </IonToolbar>

        </IonHeader>
      </>)
  }

}
