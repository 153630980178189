import {action, observable, runInAction} from "mobx";
import {cubaREST} from "..";
import {EntitiesLoadOptions, EntityFilter, PredefinedView, SerializedEntity} from "@cuba-platform/rest";

export type DataStatus = 'CLEAN' | 'LOADING' | 'DONE' | 'ERROR';

export type LoadOptions = {
  limit?: number
  offset?: number
}

export class EntityListData<T> {

  @observable entities?: Array<SerializedEntity<T>>;
  @observable status: DataStatus = "CLEAN";
  @observable totalCount = 0;
  @observable view: string;
  @observable sort?: string;
  @observable filter?: EntityFilter;

  constructor(public readonly entityName: string,
              viewName: string = PredefinedView.MINIMAL,
              sort: string | undefined = undefined) {
    this.view = viewName;
    if (sort) {
      this.sort = sort;
    }
  }

  @action
  load(opts?: LoadOptions) {
    this.entities = undefined;
    this.status = "LOADING";
    this.totalCount = 0

    const loadOptions = {...this.entitiesLoadOptions, ...opts};
    if (this.filter) {
      return this.handleLoading(cubaREST.searchEntitiesWithCount<T>(this.entityName, this.filter, loadOptions));
    } else {
      return this.handleLoading(cubaREST.loadEntitiesWithCount<T>(this.entityName, loadOptions));
    }
  }

  @action
  clear() {
    this.entities = undefined;
    this.status = 'CLEAN';
  }

  private get entitiesLoadOptions() {
    const loadOptions: EntitiesLoadOptions = {
      view: this.view,
    };
    if (this.sort) {
      loadOptions.sort = this.sort;
    }
    return loadOptions;
  }

  private handleLoading(promise: Promise<{result: any[], count: number}>) {
    return promise
      .then((resp) => {
        runInAction(() => {
          this.totalCount = resp.count;
          this.entities = resp.result;
          this.status = 'DONE';
        })
      })
      .catch(() => {
        runInAction(() => {
          this.status = 'ERROR'
        })
      })
  }

}
