import {inject, observer} from "mobx-react";
import {Component} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon, IonItem, IonList,
  IonModal,
  IonSearchbar,
  IonToolbar
} from "@ionic/react";
import React from "react";
import {action, computed, observable, toJS} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {isEmpty} from "../../../util/stringUtils";
import {includesIgnoreCase} from "../../../util/stringUtils";
import {Location} from "../../../cuba/entities/printers_Location";
import {LocationCreateModal} from "./LocationCreateModal";
import {MainStoreInjected} from "../../../mainStore";
import {DeviceEditStoreInjected} from "./deviceEditStore";
import {close as closeIcon} from 'ionicons/icons';


type Props = {
  showModal: boolean
  onClose: (e: any) => void
  onSave: (e: SerializedEntity<Location>) => void
}

const injectStoresToProps = (stores: MainStoreInjected & DeviceEditStoreInjected) => {
  const {locations} = stores.deviceEditStore;
  return {
    entities: locations ? locations.entities : [],
  }
};

@inject(injectStoresToProps)
@observer
export class LocationSearchModal extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  @observable searchString: string = '';
  @observable showCreateModal = false;

  @action private handleSearchChange = (e: CustomEvent) => {
    this.searchString = e.detail.value;
  };

  @computed get filteredLocations() {
    const {entities} = this.props;
    if (!entities) {
      return [];
    }
    if (isEmpty(this.searchString)) {
      return toJS(entities);
    }
    return toJS(entities).filter((loc: { _instanceName: string }) => {
      return includesIgnoreCase(loc._instanceName, this.searchString);
    })
  }

  render() {
    const {showModal, onSave, onClose} = this.props;

    return (
      <>
      <IonModal
        isOpen={showModal}
        backdropDismiss={false}
        onDidDismiss={() => {
        }}>

        <IonHeader>
          <IonToolbar>

            <IonSearchbar value={toJS(this.searchString)} onIonChange={this.handleSearchChange}/>

            <IonButtons slot="end">
              <IonButton onClick={onClose} fill='clear'>
                <IonIcon icon={closeIcon} slot='icon-only'/>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {this.filteredLocations.map((loc: SerializedEntity<Location>) => (
              <IonItem button={true} key={loc.id} onClick={() => {onSave(loc)}}>
                {loc._instanceName}
              </IonItem>
            ))}
          </IonList>
        </IonContent>

        <IonButton onClick={() => this.showCreateModal = true} expand='full' fill='clear'>
          Создать новый
        </IonButton>

      </IonModal>
        <LocationCreateModal show={this.showCreateModal}
                             onClose={() => {
                               this.showCreateModal = false;
                             }}
                             onSave={(loc) => {
                               this.showCreateModal = false;
                               onSave(loc as SerializedEntity<Location>);
                             }}/>

      </>
    )
  }

}
