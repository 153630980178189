import {Component} from "react";

import {IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";
import React from "react";
import {PrinteraEditItem} from "./PrinteraEditItem";
import {addCircle} from 'ionicons/icons';

type SelectProps = {
  entities: { id?: string, _instanceName: string }[] | undefined
  value: string | null | undefined
  onValueChange: (e: any) => void
}

type Props = SelectProps & {
  caption: string,
  readonly?: boolean,
  onCreatePress?: (e: any) => void,
}

export class PrinteraSelect extends Component<Props> {

  render() {
    const {onCreatePress, caption, value, onValueChange, entities, readonly} = this.props;

    if (readonly) {
      return <PrinteraEditItem caption={caption} value={value} readonly={true}/>
    }

    return (
      <>
        <IonItem>
          <IonLabel>{caption}</IonLabel>
          <EntitiesSelect value={value} entities={entities} onValueChange={onValueChange}/>
          {onCreatePress && <CreateButton onCreatePress={onCreatePress}/>}
        </IonItem>
      </>
    );
  }
}

function EntitiesSelect(props: SelectProps) {
  const {value, entities, onValueChange} = props;

  return (
    <IonSelect value={value} onIonChange={onValueChange}>

      {entities && entities.map((entity) =>

        <IonSelectOption text-wrap key={entity!.id} value={entity!.id}>
          {entity._instanceName}
        </IonSelectOption>
      )}
    </IonSelect>
  )
}

function CreateButton(props: { onCreatePress: (e: any) => void }) {
  return (
    <IonButtons>
      <IonButton slot='end' onClick={props.onCreatePress} fill='clear' size='small'>
        <IonIcon icon={addCircle} slot='icon-only'/>
      </IonButton>
    </IonButtons>
  )

}

