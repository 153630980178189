import React, {Component} from 'react'
import {IonButton, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/react';
import {observer} from "mobx-react";
import {DeviceEditStore} from "../deviceEditStore";
import {PrinteraSelect} from "../../../common/PrinteraSelect";
import {LocationSearchModal} from "../LocationSearchModal";
import {PrinteraEditItem} from "../../../common/PrinteraEditItem";
import {SerializedEntity} from "@cuba-platform/rest";
import {observable} from "mobx";
import {Location} from "../../../../cuba/entities/printers_Location";
import {EntityListData} from "../../../../data/entityListData";
import {Department} from "../../../../cuba/entities/printers_Department";
import {DeviceAssignment} from "../../../../cuba/entities/printers_DeviceAssignment";

type Props = {
  readonly?: boolean
  departments: EntityListData<Department>
  deviceEditStore: DeviceEditStore
}

//todo inject store
@observer
export class DeviceEditPlace extends Component<Props> {

  @observable showLocationSearch = false;

  render() {

    const activeAssignment = this.props.deviceEditStore.activeAssignment;
    const readonly = this.props.readonly === true;
    const {departments} = this.props;

    return <IonList>
      <IonListHeader>Расположение</IonListHeader>

      <PrinteraSelect caption="Отдел" entities={departments.entities}
                      readonly={readonly}
                      value={activeAssignment.department ? activeAssignment.department.id : null}
                      onValueChange={e => activeAssignment.department = {id: e.detail.value}}/>

      <DeviceEditLocation activeAssignment={activeAssignment} readonly={readonly}
                          onOpenButtonClick={() => this.showLocationSearch = true}/>

      <LocationSearchModal showModal={this.showLocationSearch}
                           onSave={loc => {
                             (activeAssignment.location as Partial<SerializedEntity<Location>>) = {
                               id: loc.id,
                               _instanceName: loc._instanceName
                             };
                             this.showLocationSearch = false;
                           }}
                           onClose={() => {
                             this.showLocationSearch = false;
                           }}/>

      <PrinteraEditItem caption="Ответственный" value={activeAssignment.responsiblePerson}
                        readonly={readonly}
                        onValueChange={e => activeAssignment.responsiblePerson = e.detail.value}/>

      <PrinteraEditItem caption="Электронная почта" value={activeAssignment.responsiblePersonEmail}
                        readonly={readonly}
                        onValueChange={e => activeAssignment.responsiblePersonEmail = e.detail.value}/>

      <PrinteraEditItem caption="Телефон" value={activeAssignment.responsiblePersonPhone}
                        readonly={readonly}
                        onValueChange={e => activeAssignment.responsiblePersonPhone = e.detail.value}/>
    </IonList>;
  }

}

function DeviceEditLocation(props: { activeAssignment: DeviceAssignment, readonly: boolean, onOpenButtonClick: () => any }) {

  const {activeAssignment, readonly, onOpenButtonClick} = props;

  if (!activeAssignment) return null;

  if (readonly) return <PrinteraEditItem caption='Кабинет' value={getLocationName(activeAssignment)} readonly={true}/>;

  return <>
    <IonItem>
    <IonLabel>Кабинет</IonLabel>
    <IonButton onClick={onOpenButtonClick} expand="full" fill="clear" size="small">
      {getLocationName(activeAssignment)}
    </IonButton>
  </IonItem>
  </>;

}

function getLocationName(activeAssign: DeviceAssignment) {
  if (!activeAssign || !activeAssign.location) {
    return 'не задан'
  }
  return (activeAssign.location as SerializedEntity<Location>)._instanceName;
}

