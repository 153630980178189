import React, {Component} from 'react'
import {
  IonButton,
  IonDatetime,
  IonItem, IonLabel, IonListHeader
} from '@ionic/react';
import {DeviceData} from "../../../../cuba/entities/printers_DeviceData";
import {PrinteraEditItem} from "../../../common/PrinteraEditItem";
import {observable, runInAction} from "mobx";
import {DateTime} from "luxon";
import {cubaREST} from '../../../../index';
import {inject, observer} from "mobx-react";
import {CUBA_REST_TIMESTAMP_FORMAT, isEmpty} from "../../../../util/stringUtils";
import {isDigitsOnly} from "../../../../util/validationUtils";
import {DeviceEditStoreInjected} from "../deviceEditStore";
import {PrinteraCreateModal} from "../../../common/PrinteraCreateModal";
import {CreationMethod, DeviceDataStatus} from "../../../../cuba/enums/enums";

type Props = {
  readonly?: boolean
  onSave: (dd: DeviceData) => void
}

const injectStoresToProps = (stores: DeviceEditStoreInjected) => {
  const {activeAssignment, deviceInitial, showPrintedColor, showScanned} = stores.deviceEditStore;
  return {
    assignmentId: activeAssignment ? activeAssignment.id : null,
    deviceModel: deviceInitial ? deviceInitial.deviceModel : {},
    showPrintedColor: showPrintedColor,
    showScanned: showScanned
  }
};

@inject(injectStoresToProps)
@observer
export class DeviceDataModal extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  @observable saving = false;
  @observable errorMsg: string | undefined;
  @observable deviceData: DeviceData = initDeviceData();
  @observable show = false;

  save = () => {

    // validate

    if (!this.deviceData) {
      this.errorMsg = 'Данные не могут быть отправлены';
      return;
    }

    const {printedBlackWhite, printedColor, scanned, scanTimeStamp} = this.deviceData;
    const {showPrintedColor, showScanned} = this.props;

    let errors: string[] = [];
    errors = [...errors, ...validateDataInput(printedBlackWhite, 'Черно-белые')];

    if (showPrintedColor) {
      errors = [...errors, ...validateDataInput(printedColor, 'Цветные')];
    }

    if (showScanned) {
      errors = [...errors, ...validateDataInput(scanned, 'Отсканировано')];
    }
    if (errors.length > 0) {
      this.errorMsg = 'Данные не могут быть сохранены:<br>' + errors.join('<br>');
      this.saving = false;
      return;
    }

    // save

    this.saving = true;

    const deviceDataPatch: DeviceData = {
      scanTimeStamp: DateTime.fromISO(scanTimeStamp).toFormat(CUBA_REST_TIMESTAMP_FORMAT),
      printedBlackWhite,
      creationMethod: CreationMethod.MANUAL,
      deviceDataStatus: DeviceDataStatus.VALID,
      deviceAssignment: {id: this.props.assignmentId}
    };

    if (showPrintedColor) deviceDataPatch.printedColor = printedColor;
    if (showScanned) deviceDataPatch.scanned = scanned;

    cubaREST.commitEntity(DeviceData.NAME, deviceDataPatch)
      .then((dd) => {
        runInAction(() => {
          this.saving = false;
          this.deviceData = initDeviceData();
          this.show = false;
          this.props.onSave(dd);
        });
      })
      .catch((e) => {
        if (!e || !e.response || !e.response.json) {
          this.errorMsg = 'Ошибка сервера при сохранении данных';
          this.saving = false;
          return;
        }

        e.response.json()
          .then((result: any[]) => {
            const errorMessage: string = result.map(error => error.message).join('<br>');
            if (errorMessage.length > 0) {
              this.errorMsg = "Ошибка при сохранении данных<br>" + errorMessage;
              this.saving = false;
            } else {
              this.errorMsg = 'Ошибка сервера при сохранении данных';
              this.saving = false;
            }
          }).catch(() => {
          this.errorMsg = 'Ошибка сервера при сохранении данных';
          this.saving = false;
        });
      });
  };

  close = () => {
    this.deviceData = initDeviceData();
    this.show = false;
  };

  render() {

    const { deviceData } = this;

    return (
      <IonListHeader>
        Данные

        {!this.props.readonly &&
        <IonButton onClick={() => this.show = true} expand="full" fill="clear" size="small">
          ввести значения
        </IonButton>}


        <PrinteraCreateModal header='Ввод данных' caption='Ввод новых данных устройства'
                             onClose={this.close} onSave={this.save}
                             errorMsg={this.errorMsg} saving={this.saving}
                             resetError={() => this.errorMsg = undefined} show={this.show}>

          <PrinteraEditItem caption="Черно-белые" value={deviceData.printedBlackWhite}
                            onValueChange={e => {
                            deviceData.printedBlackWhite = e.detail.value
                          }}/>

          {this.props.showPrintedColor &&
          <PrinteraEditItem caption="Цветные" value={deviceData.printedColor}
                            onValueChange={e => {
                            deviceData.printedColor = e.detail.value
                          }}/>
          }

          {this.props.showScanned &&
          <PrinteraEditItem caption="Отсканировано" value={deviceData.scanned}
                            onValueChange={e => {
                            deviceData.scanned = e.detail.value
                          }}/>
          }

          <IonItem>
            <IonLabel>Время опроса</IonLabel>
            <IonDatetime displayFormat="DD.MM.YYYY, HH:mm" value={deviceData.scanTimeStamp}
                         onIonChange={(e) => deviceData.scanTimeStamp = e.detail!.value!}/>
          </IonItem>

        </PrinteraCreateModal>
      </IonListHeader>
    );
  }
}

function validateDataInput(val: string, caption: string): string[] {
  if (isEmpty(val)) {
    return ['- поле "' + caption + '" не может быть пустым'];
  } else if (!isDigitsOnly(val)) {
    return ['- поле "' + caption + '" должно содержать только цифры'];
  }
  return [];
}

function initDeviceData() {
  const dd = new DeviceData();
  dd.scanTimeStamp = DateTime.local().toISO();
  return dd;
}