import {ContextProps} from "../TaskDoTransitionModal";
import {action, computed, observable, toJS} from "mobx";
import {FileDescriptorInfo, FileUploadItem} from "../../../common/FileUploadItem";
import React, {Component} from "react";
import {IonItem, IonLabel, IonTextarea} from "@ionic/react";
import {observer} from "mobx-react";
import {restServices} from "../../../../cuba/services";
import {cubaREST} from "../../../../index";
import {PrinteraTask, PrinteraTaskView} from "../../../../cuba/entities/printers_PrinteraTask";
import {isOffline} from "../../../../util/restUtils";

type CommentContextStoreConstructorArg = {
  task: PrinteraTaskView<'printeraTask-view'>;
}
export class CommentContextStore {
  @observable comment = "";
  @observable files: Array<FileDescriptorInfo | undefined> = [undefined];
  @observable status: "idle"|"pending"|"success"|"error" = "idle";
  @observable error = "";
  @observable task;

  constructor({task}: CommentContextStoreConstructorArg) {
    this.task = task;
  }
  @action
  setComment(comment: string) {
    this.comment = comment;
  }
  @action
  onFileRemove(i: number) {
    this.files[i] = undefined;
    if (i < this.files.length - 1) {
      this.files.splice(i, 1)
    }
  }
  @action
  onFileUpload(i: number,ufd: FileDescriptorInfo) {
    this.files[i] = ufd;
    this.files.push(undefined)
  }
  @action
  async processContext() {
    if (!this.comment) {
      this.status = "error";
      this.error = "Комментарий не может быть пустым";
      return;
    }
    try {
      this.status = "pending";
      await Promise.all([
        this.addComment(),
        this.addFiles()
      ]);
      this.status = 'success';
    } catch (e) {
      this.status = "error";
      this.error = isOffline(e) ? 'Нет подключения к интернету': `Произошла ошибка сервера`;
    }
  }

  @computed
  get printeraTask() {
    return {
      id: (this.task as PrinteraTask).id
    }
  }

  @action
  async addComment() {
    await restServices.printers_MobileApiService.addCommentToTask(cubaREST)({
      printeraTask: this.printeraTask,
      comment: toJS(this.comment)
    });
  }

  @action
  async addFiles() {
    const uploadedFilesDescriptors = this.files.slice().reduce<FileDescriptorInfo[]>((acc, fdi) => {
      if (fdi != null) acc.push(fdi);
      return acc;
    }, []);
    if (uploadedFilesDescriptors.length === 0) return;
    await restServices.printers_MobileApiService.addFilesToTask(cubaREST)({
      printeraTask: this.printeraTask,
      uploadedFilesDescriptors
    });
  }
}

@observer
export class CommentContextComponent extends Component<ContextProps<CommentContextStore>> {

  render() {
    return (
      <>
        <IonItem>
          <IonLabel position="floating">Комментарий</IonLabel>
          <IonTextarea value={this.props.store.comment}
                       rows={6}
                       onIonChange={(e) => {
                         this.props.store.setComment(e.detail.value!)
                       }}/>
        </IonItem>
        {this.props.store.files.map((fd, i) =>
          <FileUploadItem key={i}
                          fileDescriptorInfo={fd}
                          onFileRemove={() => this.props.store.onFileRemove(i)}
                          onFileUpload={(ufd) => this.props.store.onFileUpload(i, ufd)}/>
        )}
      </>
    )
  }
}