import {DateTime} from "luxon";
import {CUBA_REST_TIMESTAMP_FORMAT, isEmpty} from "./stringUtils";

export function nullSafeCompare(restDate1: string | undefined | null, restDate2: string | undefined | null): Number {

  if (isEmpty(restDate1) && isEmpty(restDate2)) return 0;

  if (isEmpty(restDate1)) return -1;
  if (isEmpty(restDate2)) return 1;

  let date1: DateTime = DateTime.fromFormat(restDate1!, CUBA_REST_TIMESTAMP_FORMAT);
  let date2: DateTime = DateTime.fromFormat(restDate2!, CUBA_REST_TIMESTAMP_FORMAT);

  let duration = date1.diff(date2).as('milliseconds');
  return duration > 0
    ? 1
    : duration === 0 ? 0 : -1;
}

export function toRestDate(date: Date): string {
  return DateTime.fromJSDate(date).toFormat(CUBA_REST_TIMESTAMP_FORMAT);
}

export function inThePast(restDate: string): boolean {
  return nullSafeCompare(restDate, toRestDate(new Date())) < 0;
}

export function hoursDiff(restDate1: string, restDate2: string): number {
  let date1: DateTime = DateTime.fromFormat(restDate1, CUBA_REST_TIMESTAMP_FORMAT);
  let date2: DateTime = DateTime.fromFormat(restDate2, CUBA_REST_TIMESTAMP_FORMAT);
  return Math.trunc(date1.diff(date2).as('hours'));
}
