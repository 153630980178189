import React, {Component} from "react";
import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";

type Props<E> = {
  caption: string,
  value: E,
  onValueChange: (e: any) => void,
  captions: Object //todo
}

export class PrinteraEnumSelect<E> extends Component<Props<E>> {

  render() {

    const {caption, captions, value, onValueChange} = this.props;

    return <>
      <IonItem>
        <IonLabel>{caption}</IonLabel>
        <IonSelect value={value}
                   onIonChange={onValueChange}>
          {Object.entries(captions).map(([key, val]) =>
            <IonSelectOption value={key} key={key}>{val}</IonSelectOption>
          )}
        </IonSelect>
      </IonItem>
    </>;
  }

}