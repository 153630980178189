import { StandardEntity } from "./base/sys$StandardEntity";
import { Client } from "./printers_Client";
import { DeviceAssignment } from "./printers_DeviceAssignment";
import { DeviceState } from "./printers_DeviceState";
import { DeviceModel } from "./printers_DeviceModel";
export class Device extends StandardEntity {
  static NAME = "printers_Device";
  owner?: Client | null;
  managed?: boolean | null;
  activeAssignment?: DeviceAssignment | null;
  deviceState?: DeviceState | null;
  deviceAssignment?: DeviceAssignment[] | null;
  deviceModel?: DeviceModel | null;
  serialNumber?: string | null;
  comment?: string | null;
  deviceNumber?: string | null;
  instanceNameAuto?: string | null;
}
export type DeviceViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "device-browse"
  | "device-edit"
  | "mobile-device-edit"
  | "device-identification-status"
  | "device-decommission"
  | "device-remove"
  | "device-light-view"
  | "device-light-view-with-deviceData"
  | "device-monitoring-view"
  | "for-log-update"
  | "device-check-status-view";
export type DeviceView<V extends DeviceViewName> = V extends "_minimal"
  ? Pick<Device, "id" | "instanceNameAuto">
  : V extends "_local"
  ? Pick<
      Device,
      | "id"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
    >
  : V extends "_base"
  ? Pick<
      Device,
      | "id"
      | "instanceNameAuto"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
    >
  : V extends "device-browse"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceModel"
      | "deviceState"
      | "activeAssignment"
    >
  : V extends "device-edit"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceState"
      | "deviceModel"
    >
  : V extends "mobile-device-edit"
  ? Pick<
      Device,
      | "id"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "owner"
      | "activeAssignment"
      | "deviceModel"
      | "deviceState"
    >
  : V extends "device-identification-status"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceModel"
    >
  : V extends "device-decommission"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "activeAssignment"
      | "deviceState"
    >
  : V extends "device-remove"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "deviceAssignment"
    >
  : V extends "device-light-view"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceModel"
    >
  : V extends "device-light-view-with-deviceData"
  ? Pick<
      Device,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceModel"
      | "deviceModel"
      | "activeAssignment"
    >
  : V extends "device-monitoring-view"
  ? Pick<
      Device,
      | "id"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "deviceModel"
    >
  : V extends "for-log-update"
  ? Pick<
      Device,
      | "id"
      | "managed"
      | "serialNumber"
      | "comment"
      | "deviceNumber"
      | "instanceNameAuto"
      | "activeAssignment"
      | "deviceState"
      | "owner"
    >
  : V extends "device-check-status-view"
  ? Pick<
      Device,
      | "id"
      | "activeAssignment"
      | "deviceModel"
      | "serialNumber"
      | "deviceNumber"
    >
  : never;
