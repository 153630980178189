import React, {Component} from 'react';
import {RouteComponentProps} from "react-router";
import {IonButton, IonContent, IonIcon, IonList, IonListHeader, IonSpinner} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {CenteredColumn} from "../../common/Centered";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {MainStore, MainStoreInjected} from "../../../mainStore";
import {PrinteraSelect} from "../../common/PrinteraSelect";
import {PrinteraLogList} from "../../common/PrinteraLogList";
import {DeviceEditStore, DeviceEditStoreInjected} from "./deviceEditStore";
import {PreventLeavingGuard} from "../../common/PreventLeavingGuard";
import {DeviceEditTasks} from "./components/DeviceEditTasks";
import {LogCreateModal} from "./LogCreateModal";
import {DeviceEditHeader} from "./components/DeviceEditHeader";
import {getModelInstanceName} from "../devicesCommon";
import {DeviceEditPlace} from "./components/DeviceEditPlace";
import {DeviceEditNotifications} from "./components/DeviceEditNotifications";
import {DeviceEditParams} from "./components/DeviceEditParams";
import {DeviceEditData} from "./components/DeviceEditData";
import {colorFill} from 'ionicons/icons';
import {deviceEditStore} from '../../../index';
import {ConsumablesReplaceModal} from './components/ConsumablesReplaceModal';

type Props =
  RouteComponentProps<{ deviceId: string }>
  & Pick<MainStore, 'deviceStates' | 'departments' | 'role' | 'consumablesTypeData'>
  & { deviceEditStore: DeviceEditStore }

@inject((stores: MainStoreInjected & DeviceEditStoreInjected) => ({
  deviceStates: stores.mainStore.deviceStates,
  departments: stores.mainStore.departments,
  role: stores.mainStore.role,
  consumablesTypeData: stores.mainStore.consumablesTypeData,

  deviceEditStore: stores.deviceEditStore,
}))

@observer
export class DeviceEdit extends Component<Props> {

  @observable showLocationSearch = false;
  @observable showConsModal = false;

  componentDidMount(): void {
    const {match} = this.props;

    if (match && match.params.deviceId) {
      this.props.deviceEditStore.loadDevice(match.params.deviceId);
    }
  }

  render() {
    const {deviceStates, history, role, deviceEditStore} = this.props;

    const {
      device, deviceInitial, saving, logItems, setSaveResult, hasUnsavedData, loadDevice, showConsReplacement,
      deviceTasks, loadDeviceTasks, loadMoreDeviceTasks, deviceTasksStatus, hasMoreDeviceTasks, deviceTasksTotalCount
    } = deviceEditStore;

    if (!deviceInitial) {
      return (
        <CenteredColumn>
          <IonSpinner/>
        </CenteredColumn>
      )
    }

    const activeAssignment = deviceEditStore.activeAssignment;
    const {deviceModel, serialNumber, owner} = deviceInitial;

    return (
      <>
        <PreventLeavingGuard preventLeaving={hasUnsavedData}/>

        <DeviceEditHeader saving={saving} history={history} deviceEditStore={deviceEditStore}/>

        <IonContent>

          <IonList>
            <IonListHeader>
              Устройство
            </IonListHeader>

            <PrinteraEditItem caption="Модель" readonly={true}
                              value={getModelInstanceName(deviceModel)}/>

            <PrinteraSelect caption="Состояние" entities={deviceStates.entities}
                            value={device.deviceState && device.deviceState.id}
                            onValueChange={e => device!.deviceState = e.detail.value ? {id: e.detail.value} : null}/>

            <PrinteraEditItem caption="Серийный номер" value={serialNumber} readonly={true}/>

            <PrinteraEditItem caption="Инв. номер" value={device.deviceNumber}
                              onValueChange={e => device!.deviceNumber = e.detail.value}/>

            <PrinteraEditItem caption="Принадлежит" value={owner && owner.clientName}
                              readonly={true}/>
          </IonList>

          {/* Place */}

          <DeviceEditPlace departments={this.props.departments} deviceEditStore={deviceEditStore}/>

          {/* Parameters */}

          <DeviceEditParams deviceEditStore={deviceEditStore}/>

          {/* Device Data */}

          <DeviceEditData deviceEditStore={deviceEditStore}/>

          {/* Tasks */}

          <DeviceEditTasks tasks={deviceTasks}
                           activeAssignmentId={activeAssignment.id}
                           role={role}
                           loading={deviceTasksStatus === "pending"}
                           hasMore={hasMoreDeviceTasks}
                           totalCount={deviceTasksTotalCount}
                           loadMore={loadMoreDeviceTasks}
                           onCreateTask={() => {
                             loadDevice(device.id);
                             loadDeviceTasks();
                             setSaveResult('Задача создана');
                           }}/>

          {/* Logs */}

          <LogCreateModal/>

          <PrinteraLogList logs={logItems}/>

          {/* Consumables replacement */}

          {showConsReplacement &&
          <IonButton color="primary" expand='block' onClick={() => this.showConsModal = true}>
            Замена РМ
            <IonIcon icon={colorFill} slot='end' />
          </IonButton>}

          {this.showConsModal &&
          <ConsumablesReplaceModal showConsModal={this.showConsModal} resetConsModal={this.resetConsModal}/>}
        </IonContent>

        <DeviceEditNotifications deviceEditStore={deviceEditStore}/>

      </>
    );
  };

  @action resetConsModal = () => {
    deviceEditStore.resetConsModel();
    this.showConsModal = false;
  };

}



