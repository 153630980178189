import {isEmpty} from "./stringUtils";

const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

const IP_REGEXP = /^\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b$/;

const DIGIT_ONLY_REGEX = /^\d*$/;

const BLANK_STRING_REGEX = /^\s*$/;

export function isBlank(value: string | null | undefined): boolean {
  if (isEmpty(value)) return true;
  return BLANK_STRING_REGEX.test(value!);
}

export function isEmailInvalid(email: string | null | undefined): boolean {
  if (isEmpty(email)) return false;
  return !EMAIL_REGEXP.test(email!);
}

export function isIpInvalid(ip: string | null | undefined): boolean {
  if (isEmpty(ip)) return false;
  return !IP_REGEXP.test(ip!);
}

export function isPhoneNumberInvalid(phoneNumber: string | null | undefined): boolean {
  //at this moment phone validation on generic ui allows digits only, we make the same
  if (isEmpty(phoneNumber)) return false;
  return !isDigitsOnly(phoneNumber);
}

export function isDigitsOnly(val: string | null | undefined): boolean {
  return isEmpty(val) || DIGIT_ONLY_REGEX.test(val!);
}