import {
  PrinteraTaskPriorityCaption,
  PrinteraTaskStatusCaption
} from "../../cuba/enums";
import {PrinteraTaskPriority, PrinteraTaskStatus} from "../../cuba/enums/enums";
import {toRestDate, nullSafeCompare, hoursDiff} from "../../util/dateUtils";
import {PrinteraTask} from "../../cuba/entities/printers_PrinteraTask";

export enum TaskStateColors {
  LATE_SLA = '#a60202',
  LATE_EXECUTION = '#ff4040',
  DATE_COMING = '#facd02'
}

const DATE_COMING_HOURS = 4;

export const taskPriorityCaption = (priority: PrinteraTaskPriority | undefined | null): string | undefined => {
  if (priority == null) {
    return;
  }
  return PrinteraTaskPriorityCaption[priority];
};

export const taskStatusCaption = (status: PrinteraTaskStatus | undefined | null): string | undefined => {
  if (status == null) {
    return;
  }
  return PrinteraTaskStatusCaption[status];
};

export function getNameById<T>(id: string, entities: Array<{ id?: string, _instanceName: string }> | undefined) {
  const entity = entities == null ? null : entities.find(e => e.id === id);
  return entity && entity._instanceName ? entity._instanceName : ''
}


export function tasksComparator(a: PrinteraTask, b: PrinteraTask) {
  if (isOverdueOrDateComing(a) && !isOverdueOrDateComing(b)) return -1;
  if (isOverdueOrDateComing(b) && !isOverdueOrDateComing(a)) return 1;
  return a.number - b.number;
}

export function isOverdueOrDateComing(task: PrinteraTask): boolean {
  const {takenToWork, dueDateSLA, dueDate, dateOfCompletion} = task;
  return taskStateColor(takenToWork, dueDateSLA, dueDate, dateOfCompletion) != null;
}

/**
 * @param takenToWork - actual response time
 * @param dueDateSLA - expected response time
 * @param dueDate - expected date of completion
 * @param dateOfCompletion - actual date of completion
 */
export function taskStateColor(takenToWork: string | null, dueDateSLA: string | null,
                               dueDate: string | null, dateOfCompletion: string | null): TaskStateColors | undefined {

  // late execution

  // dateOfCompletion > dueDate
  if (dueDate && dateOfCompletion && nullSafeCompare(dateOfCompletion, dueDate) > 0) return TaskStateColors.LATE_EXECUTION;

  // dateOfCompletion not set and dueDate < now
  if (!dateOfCompletion && dueDate && nullSafeCompare(dueDate, toRestDate(new Date())) < 0) return TaskStateColors.LATE_EXECUTION;

  // late SLA

  // takenToWork > dueDateSLA
  if (takenToWork && dueDateSLA && nullSafeCompare(takenToWork, dueDateSLA) > 0) return TaskStateColors.LATE_SLA;
  // takenToWork not set &&  dueDateSLA < now
  if (!takenToWork && dueDateSLA && nullSafeCompare(toRestDate(new Date()), dueDateSLA) > 0) return TaskStateColors.LATE_SLA;

  // date coming

  //(dueDateSLA - NOW < 4hrs) OR (dueDate - NOW < 4hrs)
  if (dueDateSLA && dateComing(dueDateSLA)) return TaskStateColors.DATE_COMING;
  if (dueDate && dateComing(dueDate)) return TaskStateColors.DATE_COMING;

  return undefined;
}

export function dateComing(restDate: string): boolean {
  let nowRestDate = toRestDate(new Date());
  const hours = hoursDiff(restDate, nowRestDate);
  return hours < DATE_COMING_HOURS && hours >= 0;
}
