import {action, computed, observable, reaction, runInAction} from "mobx";
import {Device} from "../../../cuba/entities/printers_Device";
import {cubaREST, deviceListStore, mainStore, taskListStore} from "../../../index";
import {addDeviceNumber} from "../../../routes";
import {DeviceAssignment} from "../../../cuba/entities/printers_DeviceAssignment";
import {isBlank, isEmailInvalid, isIpInvalid, isPhoneNumberInvalid} from "../../../util/validationUtils";
import {DeviceData} from "../../../cuba/entities/printers_DeviceData";
import {formatServerDateTime, includesIgnoreCase, isEmpty, nullSafeEqual} from "../../../util/stringUtils";
import {BaseUuidEntity} from "../../../cuba/entities/base/sys$BaseUuidEntity";
import {Condition, EntityFilter, SerializedEntity} from "@cuba-platform/rest";
import {DeviceLogEntry} from "../../../cuba/entities/printers_DeviceLogEntry";
import {getEnumCaption, IdentificationStatusCaption, PollStatusCaption, Role} from "../../../cuba/enums";
import {
  printers_WarehouseRestService_useForDevice_params,
  restServices
} from "../../../cuba/services";
import {isOffline} from "../../../util/restUtils";
import {ColorMode, ConnectionType} from "../../../cuba/enums/enums";
import {EntityListData} from "../../../data/entityListData";
import {Location} from "../../../cuba/entities/printers_Location";
import {Warehouse} from '../../../cuba/entities/printers_Warehouse';
import {WarehouseStockLine} from "../../../cuba/entities/printers_WarehouseStockLine";
import {PrinteraTask} from "../../../cuba/entities/printers_PrinteraTask";

type ActiveAssignmentPatch = {
  device?: BaseUuidEntity
  department: null | BaseUuidEntity
  location: null | BaseUuidEntity
  connectionType: null | undefined | ConnectionType
  responsiblePerson: null | undefined | string
  responsiblePersonEmail: null | undefined | string
  responsiblePersonPhone: null | undefined | string
  currentIpAddress: null | undefined | string
  manualInput: boolean
  id: null | string
  redirection?: DeviceAssignment | null
}

export type ConsumablesModel = {
  warehouseId?: string;
  selectedConsIds: string[];
}

export class DeviceEditStore {

  static readonly NAME = 'deviceEditStore';

  private static readonly TASKS_LIMIT = 30;

  @observable device: Device = {
    id: null,
    deviceNumber: null,
    deviceState: null
  };
  @observable deviceInitial?: Device;

  @observable activeAssignment: ActiveAssignmentPatch = {
    department: null,
    location: null,
    connectionType: null,
    responsiblePerson: null,
    responsiblePersonEmail: null,
    responsiblePersonPhone: null,
    currentIpAddress: null,
    manualInput: false,
    id: null
  };
  @observable aaInitial: DeviceAssignment = {
    connectionType: null,
    logs: null
  };

  @observable saving = false;
  @observable saveResult?: string;
  @observable loadingError = false;
  @observable validationFail = false;
  @observable latestDeviceData?: DeviceData;
  @observable officeId?: string;

  @observable savingCons: boolean = false;
  @observable warehouses: SerializedEntity<Warehouse>[] = [];
  @observable consumables: SerializedEntity<WarehouseStockLine>[] = [];
  @observable consTypeId?: string;
  @observable consCode?: string;
  @observable filteredCons: WarehouseStockLine[] = [];
  consModel: ConsumablesModel = {selectedConsIds: []};
  @observable deviceTasksStatus: "initial" | "pending" | "success" | "error" = "initial";
  @observable deviceTasks: SerializedEntity<PrinteraTask>[] = [];
  @observable deviceTasksTotalCount = 0;
  @observable deviceTasksOffset = 0;

  @observable.ref locations = new EntityListData<Location>(Location.NAME);
  @observable.ref private redirectionDeviceData = new EntityListData<DeviceAssignment>(DeviceAssignment.NAME);
  @observable.ref private deviceTaskData = new EntityListData(PrinteraTask.NAME);

  constructor() {
    reaction(
      () => this.deviceInitial,
      () => {
        this.loadConsumables();
        this.loadDeviceTasks();
      });
    reaction(
      () => this.deviceTasksOffset,
      () => {
        this.loadDeviceTasks();
      }
    )
    reaction(
      () => [this.consCode, this.consTypeId, this.consumables],
      () => this.filterCons());
  }

  initDevice = (device: Device) => {
    this.deviceInitial = device;
    const {id, deviceState, deviceNumber} = device;
    Object.assign(this.device, {id, deviceState, deviceNumber});
  };

  initActiveAssignment = (activeAssignment: DeviceAssignment | null | undefined) => {
    this.aaInitial = activeAssignment ? activeAssignment : {};
    //set manual input (the same as in cuba client)
    if (this.manualInputDisabled) {
      this.aaInitial.manualInput = true
    }
    Object.assign(this.activeAssignment, composeActiveAssignment(this.aaInitial, this.device.id));
  };

  loadDeviceTasks = () => {
    this.loadDeviceTasksAsync()
      .catch(e => {
        console.log(e)
      });
  }
  @action loadDeviceTasksAsync = async () => {
    try {
      this.deviceTasksStatus = "pending";
      let filter = taskListStore.userFilter;
      if (!filter) return;
      if (!this.activeAssignment.id) return;
      filter = {...filter};
      const deviceFilter: Condition = {
        property: "deviceAssignment.id",
        operator: "=",
        value: this.activeAssignment.id
      };
      filter.conditions = [...filter.conditions, deviceFilter]
      this.deviceTaskData.filter = filter;
      this.deviceTaskData.view = taskListStore.defaultView;
      this.deviceTaskData.sort = taskListStore.defaultSort;
      await this.deviceTaskData.load({limit: DeviceEditStore.TASKS_LIMIT + this.deviceTasksOffset})
      this.deviceTasks = this.deviceTaskData.entities || [];
      this.deviceTasksTotalCount = this.deviceTaskData.totalCount;
      this.deviceTasksStatus = "success";
    } catch (e) {
      this.deviceTasksStatus = "error";
    }
  }

  @computed get hasMoreDeviceTasks() {
    return this.deviceTasks.length < this.deviceTasksTotalCount;
  }

  @action loadMoreDeviceTasks = () => {
    this.deviceTasksOffset += DeviceEditStore.TASKS_LIMIT;
  }

  @action loadDevice = (deviceId: string) => {

    this.consumables = [];

    cubaREST.loadEntity<Device>(Device.NAME, deviceId, {view: 'mobile-device-edit'})
      .then((device) => {

        if (!device.activeAssignment || !device.activeAssignment.office) {
          throw new Error('No office set - could not load locations');
        }
        const officeId = device.activeAssignment.office.id;

        return runInAction(() => {
          this.officeId = officeId;
          this.initDevice(device);
          this.initActiveAssignment(device.activeAssignment);
          if (!isBlank(addDeviceNumber)) {
            this.device.deviceNumber = addDeviceNumber;
          }
          return Promise.all([this.reloadLocations(), this.reloadRedirectionDevices()]);
        })

      })
      .then(() => {
        if (!this.deviceInitial || !this.aaInitial) {
          return
        }

        const params = {deviceAssignment: this.deviceInitial.activeAssignment};
        return restServices.printers_DeviceDataService.getLatestDeviceData(cubaREST)(params)
          .then(latestDeviceData =>
            this.latestDeviceData = latestDeviceData ? JSON.parse(latestDeviceData as string) : {});
      })
      .catch(() => this.loadingError = true);
  };

  @action loadWarehouses = () => {
    restServices.printers_WarehouseRestService
      .getAvailableWarehouses(cubaREST, {handleAs: 'json'})({deviceAssignment: this.activeAssignment})
      .then(result => this.warehouses = result)
  };

  @action loadConsumables = () => {
    restServices.printers_WarehouseRestService
      .getAvailableConsumables(cubaREST, {handleAs: 'json'})({deviceAssignment: this.activeAssignment})
      .then(result => this.consumables = result);
  };

  @action private filterCons = () => {
    this.consModel.selectedConsIds = [];
    const {consumables, consTypeId, consCode} = this;
    this.filteredCons = filterCons(consumables, consTypeId, consCode);
  };

  @action toggleCons = (cons: string, checked: boolean) => {
    const {selectedConsIds} = this.consModel;

    const idx = selectedConsIds.indexOf(cons);
    if (checked) {
      idx === -1 && selectedConsIds.push(cons);
    } else {
      selectedConsIds.splice(idx)
    }
  };

  @action resetConsModel = () => {
    this.consCode = undefined;
    this.consTypeId = undefined;
    this.consModel = {selectedConsIds: []};
  };

  @action
  save = () => {
    this.saving = true;

    const validationMessage = this.validate();

    if (validationMessage.length > 0) {
      const msg = "Ошибка при сохранении устройства.<br>" + validationMessage;
      this.setSaveResult(msg, true);
      this.saving = false;
      return;
    }

    let commitActiveAssignmentPromise: Promise<Partial<Partial<DeviceAssignment>>> = Promise.resolve<any>(null);

    if (this.activeAssignmentChanged) {
      //we need ip address set to null if it is blank, elsewhere got backend validation message
      if (isBlank(this.activeAssignment.currentIpAddress)) {
        this.activeAssignment.currentIpAddress = null;
      }
      commitActiveAssignmentPromise = cubaREST.commitEntity(DeviceAssignment.NAME, this.activeAssignment as Partial<DeviceAssignment>);
    }

    // todo the below is not transactional
    Promise
      .all([
        cubaREST.commitEntity(Device.NAME, this.device),
        commitActiveAssignmentPromise
      ])
      .then(([device]) => {
        runInAction(() => {
          this.saving = false;
          this.setSaveResult('Сохранено');
          this.device.id = null;
          deviceListStore.reloadDevices();
          this.loadDevice(device.id)
        });
      })
      .catch((err) => {
        this.saving = false;
        let msg = isOffline(err) ? 'Отсутствует интернет соединение' : 'Ошибка сервера при сохранении устройства';
        this.setSaveResult(msg, true);
      });
  };

  @action
  setSaveResult = (saveResult: string | undefined, validationFail: boolean = false): void => {
    this.saveResult = saveResult;
    this.validationFail = validationFail
  };

  private validate = (): string => {

    const {
      responsiblePersonEmail: email,
      responsiblePersonPhone: phone,
      currentIpAddress: ip
    } = this.activeAssignment!;

    //validation
    const errors: string[] = [];
    if (isEmailInvalid(email)) {
      errors.push("- поле 'Электронная почта' введено не верно<br>");
    }
    if (isPhoneNumberInvalid(phone)) {
      errors.push("- поле 'Телефон' может содержать только цифры<br>");
    }
    if (isIpInvalid(ip)) {
      errors.push("- некорректно введено поле 'IP адрес'<br>")
    }

    return errors.join('');
  };

  @action setLatestDeviceData = (dd: DeviceData) => {
    this.latestDeviceData = dd;
  };

  @action saveCons = () => {

    const {selectedConsIds} = this.consModel;
    const consumableId = selectedConsIds.map(id => ({id}))[0];
    const consumable = this.consumables.find(cons => cons.consumable?.id === consumableId.id);
    const warehouseId = consumable?.warehouse?.id;

    // validation
    if (selectedConsIds.length < 1) return Promise.reject({message: 'Не выбраны расходные материалы'});

    // saving

    this.savingCons = true;

    const params: printers_WarehouseRestService_useForDevice_params= {
      consumable: consumableId,
      deviceAssignment: {id: this.activeAssignment.id},
      warehouse: {id: warehouseId}
    };

    return restServices.printers_WarehouseRestService
      .useForDevice(cubaREST, {handleAs: 'json'})(params)
      .then(result => {

        if (!result) return Promise.reject();

        this.saveResult = 'Движение расходных материалов проведено успешно';
        this.savingCons = false;

        // reload device - update logs
        this.loadDevice(this.device.id);
      })
      .catch(() => {
        this.savingCons = false;
        return Promise.reject({message: 'Ошибка при сохранении движения расходных материалов'});
      });
  };

  @computed get warehouseReadonly() {
    return mainStore.role !== Role.FULL;
  }

  @computed get showConsReplacement() {
    return mainStore.role === Role.FULL || mainStore.role === Role.CLIENT_ADMIN;
  }

  @computed get deviceInitialCaption() {
    return (this.deviceInitial as SerializedEntity<Device>)._instanceName;
  }

  @computed get logItems() {
    return !this.aaInitial || !this.aaInitial.logs ? [] : this.aaInitial.logs.map(log => {
      return {id: log.id, description: log.description ? formatServerDateTime(log.date) + " " + log.description : ''}
    })
  }

  @computed get manualInputDisabled() {
    const {connectionType} = this.activeAssignment;
    return connectionType === ConnectionType.USB || connectionType === ConnectionType.NOT_CONNECTED
  }

  @computed get hasUnsavedData(): boolean {
    if (!this.device.id || !this.deviceInitial) {
      return false;
    }

    const initialNumber = this.deviceInitial.deviceNumber ? this.deviceInitial.deviceNumber : '';
    const deviceNumber = this.device.deviceNumber ? this.device.deviceNumber : '';
    if (deviceNumber !== initialNumber) {
      return true;
    }

    if (isUuidEntityChanged(this.device.deviceState, this.deviceInitial.deviceState)) return true;

    return this.activeAssignmentChanged;
  };

  @computed get activeAssignmentChanged() {
    const initialAa = composeActiveAssignment(this.aaInitial!, this.device.id);
    return activeAssignmentChanged(initialAa, this.activeAssignment);
  }

  @computed get showPrintedColor() {
    return this.deviceType.colorMode === ColorMode.COLOR;
  }

  @computed get showScanned() {
    return this.deviceType.scanSupported === true;
  }

  @computed get deviceType() {
    const deviceModel = this.deviceInitial && this.deviceInitial.deviceModel
      ? this.deviceInitial.deviceModel : {};
    return deviceModel && deviceModel.deviceType ? deviceModel.deviceType : {};
  }

  @computed get pollStatusCaption() {
    if (!this.deviceInitial || !this.deviceInitial.activeAssignment) return '';
    return getEnumCaption(this.deviceInitial.activeAssignment.pollStatus, PollStatusCaption);
  }

  @computed get idnStatusCaption() {
    if (!this.deviceInitial || !this.deviceInitial.activeAssignment) return '';
    return getEnumCaption(this.deviceInitial.activeAssignment.identificationStatus, IdentificationStatusCaption);
  }

  @computed get redirectionDevices(): SerializedEntity<DeviceAssignment>[] {
    return this.redirectionDeviceData.entities ? this.redirectionDeviceData.entities : [];
  }

  @action addLog = (logEntry: DeviceLogEntry) => {
    if (this.aaInitial && this.aaInitial.logs) this.aaInitial.logs.push(logEntry)
  };

  @action reloadLocations = () => {
    if (!this.officeId) {
      this.locations.clear();
      return;
    }

    this.locations.filter = byOfficeFilter(this.officeId);
    return this.locations.load();
  };

  @action private reloadRedirectionDevices = () => {
    if (!this.officeId) {
      this.redirectionDeviceData.clear();
      return;
    }

    this.redirectionDeviceData.filter = byOfficeFilter(this.officeId);
    return this.redirectionDeviceData.load();
  };
}

function byOfficeFilter(officeId: string): EntityFilter {
  return {
    conditions: [{
      property: 'office.id',
      operator: "=",
      value: officeId
    }]
  }
}

function isUuidEntityChanged(obj1: BaseUuidEntity | null | undefined, obj2: BaseUuidEntity | null | undefined) {
  const id1 = obj1 ? obj1.id : null;
  const id2 = obj2 ? obj2.id : null;
  return !nullSafeEqual(id1, id2);
}

const composeActiveAssignment = (activeAssignment: DeviceAssignment, deviceId: string): ActiveAssignmentPatch => {

  const {
    department,
    location,
    connectionType,
    responsiblePerson,
    responsiblePersonEmail,
    responsiblePersonPhone,
    currentIpAddress,
    manualInput,
    id,
    redirection
  } = activeAssignment;

  const aaPatch: ActiveAssignmentPatch = {
    department: department ? {id: department.id} : null,
    location: location ? {id: location.id, _instanceName: (location as SerializedEntity<Location>)._instanceName} as any: null,
    connectionType,
    responsiblePerson,
    responsiblePersonEmail,
    responsiblePersonPhone,
    currentIpAddress,
    manualInput: manualInput === true,
    id,
    redirection
  };

  if (activeAssignment.id) { //id only for update AA
    aaPatch.id = activeAssignment!.id

  } else { // for create AA we set device.id //todo creation not work now, need client.id
    aaPatch.device = {id: deviceId}
  }

  return aaPatch;
};


const activeAssignmentChanged = (init: ActiveAssignmentPatch, current: ActiveAssignmentPatch) => {
  const {
    department,
    location,
    connectionType,
    responsiblePerson,
    responsiblePersonEmail,
    responsiblePersonPhone,
    currentIpAddress,
    manualInput,
    redirection
  } = current;

  //we are going to create new AA, no comparison required
  if (isEmpty(current.id)) {
    return true;
  }

  const equal = nullSafeEqual(init.connectionType, connectionType)
    && nullSafeEqual(init.responsiblePerson, responsiblePerson)
    && nullSafeEqual(init.responsiblePersonEmail, responsiblePersonEmail)
    && nullSafeEqual(init.responsiblePersonPhone, responsiblePersonPhone)
    && nullSafeEqual(init.currentIpAddress, currentIpAddress)
    && init.manualInput === manualInput
    && !isUuidEntityChanged(init.department, department)
    && !isUuidEntityChanged(init.location, location)
    && !isUuidEntityChanged(init.redirection, redirection);

  return !equal;
};


export interface DeviceEditStoreInjected {
  deviceEditStore: DeviceEditStore
}

function filterCons(consumables: WarehouseStockLine[], consTypeId: string | undefined, consCode: string | undefined) {
  return consumables.filter(cons => {
    if (cons.consumable?.code && !isBlank(consCode) && !includesIgnoreCase(cons.consumable?.code, consCode)) return false;
    if (consTypeId && cons.consumable?.consumablesType && cons.consumable?.consumablesType.id !== consTypeId) return false;
    return true;
  });
}

