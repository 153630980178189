import React from 'react';
import {IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from '@ionic/react';
import {History} from "history";
import {arrowBack} from 'ionicons/icons';



type Props = {
  caption: string,
  history: History
};

export const PrinteraHeader = (props: Props) =>
  <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton onClick={() => props.history.goBack()} fill='clear'>
          <IonIcon icon={arrowBack} slot='icon-only'/>
        </IonButton>
      </IonButtons>

      <IonTitle>{props.caption}</IonTitle>
    </IonToolbar>
  </IonHeader>;
