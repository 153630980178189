import {CubaApp, FetchOptions, SerializedEntity} from "@cuba-platform/rest";
import {PrinteraTask} from './entities/printers_PrinteraTask';
import {Warehouse} from './entities/printers_Warehouse';
import {WarehouseStockLine} from "./entities/printers_WarehouseStockLine";
import {TransitionButton} from "./other/printers_TransitionButton";
import {TransitionUser} from "./other/printers_TransitionUser";
import {PrinteraUser} from "./entities/printers_PrinteraUser";

export type printers_ClientDeviceStatusService_getClientDeviceStatuses_params = {
  stringId: any;
};

export type printers_ClientOrganizationsService_getClientOrganizations_params = {
  stringId: any;
};

export type printers_ClientDevicesService_getClientDevices_params = {
  stringId: any;
};

export type printers_ClientPollersService_getClientPollers_params = {
  stringId: any;
};

export type printers_PollerDeviceStatusService_getPollerDeviceStatuses_params = {
  stringId: any;
};

export type printers_DeviceDataService_getLatestDeviceData_params = {
  deviceAssignment: any;
};

export type printers_DeviceService_checkDevicesStatus_params = {
  devices: any;
};

export type printers_MobileApiService_createTask_params = {
  typeId: any;
  header: any;
  deviceAssignmentId: any;
  description: any;
  assignToId: any;
  clientId: any;
  uploadedFilesDescriptors: any;
};

export type printers_MobileApiService_getTransitionButtons_params = {
  taskId: string;
}

export type printers_MobileApiService_getAllowedUsersForTransition_params = {
  taskId: string;
  transitionId: string;
}

export type printers_MobileApiService_doTransition_params = {
  taskId: string;
  transitionId: string;
  assigneeId: string|null;
}


export type printers_MobileApiService_addCommentToTask_params = {
  printeraTask: any;
  comment: any;
};

export type printers_MobileApiService_addFilesToTask_params = {
  printeraTask: any;
  uploadedFilesDescriptors: any;
};


export type printers_WarehouseRestService_getAvailableWarehouses_params = {
  deviceAssignment: any;
};

export type printers_WarehouseRestService_getAvailableConsumables_params = {
  deviceAssignment: any;
};

export type printers_WarehouseRestService_createDecommission_params = {
  warehouse: any;
  deviceAssignment: any;
  consumablesList: any;
};

export type printers_WarehouseRestService_useForDevice_params = {
  warehouse: any;
  deviceAssignment: any;
  consumable: any;
};

export var restServices = {
  printers_ClientDeviceStatusService: {
    getClientDeviceStatuses: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_ClientDeviceStatusService_getClientDeviceStatuses_params
    ) => {
      return cubaApp.invokeService(
        "printers_ClientDeviceStatusService",
        "getClientDeviceStatuses",
        params,
        fetchOpts
      );
    }
  },
  printers_ClientOrganizationsService: {
    getClientOrganizations: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_ClientOrganizationsService_getClientOrganizations_params
    ) => {
      return cubaApp.invokeService(
        "printers_ClientOrganizationsService",
        "getClientOrganizations",
        params,
        fetchOpts
      );
    }
  },
  printers_ClientDevicesService: {
    getClientDevices: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_ClientDevicesService_getClientDevices_params
    ) => {
      return cubaApp.invokeService(
        "printers_ClientDevicesService",
        "getClientDevices",
        params,
        fetchOpts
      );
    }
  },
  printers_ClientPollersService: {
    getClientPollers: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_ClientPollersService_getClientPollers_params
    ) => {
      return cubaApp.invokeService(
        "printers_ClientPollersService",
        "getClientPollers",
        params,
        fetchOpts
      );
    }
  },
  printers_PollerDeviceStatusService: {
    getPollerDeviceStatuses: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_PollerDeviceStatusService_getPollerDeviceStatuses_params
    ) => {
      return cubaApp.invokeService(
        "printers_PollerDeviceStatusService",
        "getPollerDeviceStatuses",
        params,
        fetchOpts
      );
    }
  },
  printers_DeviceDataService: {
    getLatestDeviceData: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_DeviceDataService_getLatestDeviceData_params
    ) => {
      return cubaApp.invokeService(
        "printers_DeviceDataService",
        "getLatestDeviceData",
        params,
        fetchOpts
      );
    }
  },
  printers_DeviceService: {
    checkDevicesStatus: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_DeviceService_checkDevicesStatus_params
    ) => {
      return cubaApp.invokeService(
        "printers_DeviceService",
        "checkDevicesStatus",
        params,
        fetchOpts
      );
    }
  },
  printers_MobileApiService: {
    getEmployees: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.invokeService<PrinteraUser[]>(
        "printers_MobileApiService",
        "getEmployees",
        {},
        fetchOpts
      );
    },
    createTask: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_createTask_params
    ) => {
      return cubaApp.invokeService<PrinteraTask>(
        "printers_MobileApiService",
        "createTask",
        params,
        fetchOpts
      );
    },
    getTransitionButtons: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_getTransitionButtons_params
    ) => {
      return cubaApp.invokeService<TransitionButton[]>(
        "printers_MobileApiService",
        "getTransitionButtons",
        params,
        fetchOpts
      );
    },
    getAllowedUsersForTransition: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_getAllowedUsersForTransition_params
    ) => {
      return cubaApp.invokeService<TransitionUser[]>(
        "printers_MobileApiService",
        "getAllowedUsersForTransition",
        params,
        fetchOpts
      );
    },
    doTransition: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_doTransition_params
    ) => {
      return cubaApp.invokeService(
        "printers_MobileApiService",
        "doTransition",
        params,
        fetchOpts
      );
    },
    addCommentToTask: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_addCommentToTask_params
    ) => {
      return cubaApp.invokeService(
        "printers_MobileApiService",
        "addCommentToTask",
        params,
        fetchOpts
      );
    },
    addFilesToTask: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_MobileApiService_addFilesToTask_params
    ) => {
      return cubaApp.invokeService(
        "printers_MobileApiService",
        "addFilesToTask",
        params,
        fetchOpts
      )
    }
  },
  printers_UserInfoService: {
    getUserInfo: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.invokeService(
        "printers_UserInfoService",
        "getUserInfo",
        {},
        fetchOpts
      );
    }
  },
  printers_WarehouseRestService: {
    getAvailableWarehouses: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_WarehouseRestService_getAvailableWarehouses_params
    ) => {
      return cubaApp.invokeService<SerializedEntity<Warehouse>[]>(
        "printers_WarehouseRestService",
        "getAvailableWarehouses",
        params,
        fetchOpts
      );
    },
    getAvailableConsumables: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_WarehouseRestService_getAvailableConsumables_params
    ) => {
      return cubaApp.invokeService<SerializedEntity<WarehouseStockLine>[]>(
        "printers_WarehouseRestService",
        "getAvailableConsumables",
        params,
        fetchOpts
      );
    },
    // удалено из бекенда, вместо этого useForDevice
    createDecommission: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_WarehouseRestService_createDecommission_params
    ) => {
      return cubaApp.invokeService<boolean>(
        "printers_WarehouseRestService",
        "createDecommission",
        params,
        fetchOpts
      );
    },
    useForDevice: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: printers_WarehouseRestService_useForDevice_params
    ) => {
      return cubaApp.invokeService<boolean>(
        "printers_WarehouseRestService",
        "useForDevice",
        params,
        fetchOpts
      );
    }
  }
};
