import React, {PropsWithChildren} from "react";

export const CenteredRow = ({children}: PropsWithChildren<{}>) =>
  <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    {children}
  </div>;

export const CenteredColumn = ({children}: PropsWithChildren<{}>) =>
  <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
    {children}
  </div>;
