import { StandardEntity } from "./base/sys$StandardEntity";
import { PrinteraTaskPriority } from "../enums/enums";
import { PrinteraTaskField } from "./printers_PrinteraTaskField";
export class PrinteraTaskType extends StandardEntity {
  static NAME = "printers_PrinteraTaskType";
  name?: string | null;
  code?: string | null;
  defaultPriority?: PrinteraTaskPriority | null;
  customerVisibility?: boolean | null;
  defaultPeriodOfExecution?: number | null;
  manualCreationIsPossible?: boolean | null;
  processDefinitionId?: string | null;
  fields?: PrinteraTaskField[] | null;
  addCommentOnCompletion?: boolean | null;
  addFileOnCompletion?: boolean | null;
  useSmsNotification?: boolean | null;
}
export type PrinteraTaskTypeViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "PrinteraTaskType-view";
export type PrinteraTaskTypeView<
  V extends PrinteraTaskTypeViewName
> = V extends "_minimal"
  ? Pick<PrinteraTaskType, "id" | "name">
  : V extends "_local"
  ? Pick<
      PrinteraTaskType,
      | "id"
      | "name"
      | "code"
      | "defaultPriority"
      | "customerVisibility"
      | "defaultPeriodOfExecution"
      | "manualCreationIsPossible"
      | "processDefinitionId"
      | "addCommentOnCompletion"
      | "addFileOnCompletion"
      | "useSmsNotification"
    >
  : V extends "_base"
  ? Pick<
      PrinteraTaskType,
      | "id"
      | "name"
      | "code"
      | "defaultPriority"
      | "customerVisibility"
      | "defaultPeriodOfExecution"
      | "manualCreationIsPossible"
      | "processDefinitionId"
      | "addCommentOnCompletion"
      | "addFileOnCompletion"
      | "useSmsNotification"
    >
  : V extends "PrinteraTaskType-view"
  ? Pick<
      PrinteraTaskType,
      | "id"
      | "name"
      | "code"
      | "defaultPriority"
      | "customerVisibility"
      | "defaultPeriodOfExecution"
      | "manualCreationIsPossible"
      | "processDefinitionId"
      | "addCommentOnCompletion"
      | "addFileOnCompletion"
      | "useSmsNotification"
      | "fields"
    >
  : never;
