import { BaseUuidEntity } from "./base/sys$BaseUuidEntity";
import { DeviceAssignment } from "./printers_DeviceAssignment";
import { CreationMethod, DeviceDataStatus } from "../enums/enums";
export class DeviceData extends BaseUuidEntity {
  static NAME = "printers_DeviceData";
  receiveTimeStamp?: any | null;
  printedBlackWhite?: any | null;
  printedColor?: any | null;
  scanned?: any | null;
  scanTimeStamp?: any | null;
  rawdata?: string | null;
  deviceAssignment?: DeviceAssignment | null;
  parameters?: any | null;
  creationMethod?: CreationMethod | null;
  deviceDataStatus?: DeviceDataStatus | null;
}
export type DeviceDataViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "deviceData-edit"
  | "deviceData-deviceEdit-view"
  | "deviceData-raw-view"
  | "deviceData-view"
  | "deviceData-light-view"
  | "deviceData-edit-view"
  | "deviceData-import-view"
  | "device-data-recalculate-view";
export type DeviceDataView<V extends DeviceDataViewName> = V extends "_minimal"
  ? Pick<
      DeviceData,
      "id" | "printedBlackWhite" | "printedColor" | "scanned" | "scanTimeStamp"
    >
  : V extends "_local"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
    >
  : V extends "_base"
  ? Pick<
      DeviceData,
      | "id"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "receiveTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
    >
  : V extends "deviceData-edit"
  ? Pick<
      DeviceData,
      "id" | "printedBlackWhite" | "printedColor" | "scanned" | "scanTimeStamp"
    >
  : V extends "deviceData-deviceEdit-view"
  ? Pick<
      DeviceData,
      | "id"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "scanTimeStamp"
      | "parameters"
    >
  : V extends "deviceData-raw-view"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
    >
  : V extends "deviceData-view"
  ? Pick<
      DeviceData,
      | "id"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "deviceAssignment"
      | "scanTimeStamp"
      | "receiveTimeStamp"
      | "deviceDataStatus"
      | "printedBlackWhite"
      | "scanned"
      | "printedColor"
    >
  : V extends "deviceData-light-view"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
      | "deviceAssignment"
    >
  : V extends "deviceData-edit-view"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
      | "deviceAssignment"
    >
  : V extends "deviceData-import-view"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
      | "deviceAssignment"
    >
  : V extends "device-data-recalculate-view"
  ? Pick<
      DeviceData,
      | "id"
      | "receiveTimeStamp"
      | "printedBlackWhite"
      | "printedColor"
      | "scanned"
      | "scanTimeStamp"
      | "rawdata"
      | "parameters"
      | "creationMethod"
      | "deviceDataStatus"
      | "deviceAssignment"
    >
  : never;
