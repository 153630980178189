import {DateTime} from "luxon";

export const CUBA_REST_TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS';
export const DATE_TIME_DISPLAY_FORMAT = 'dd.MM.yyyy HH:mm';

export function includesIgnoreCase(str: number | string | null | undefined, substr: number | string | null | undefined): boolean {
  if (str == null) {
    return false;
  }

  if (substr == null) {
    return true;
  }

  return ('' + str).toLowerCase().includes(('' + substr).toLowerCase())
}

export function formatServerDateTime(date: string | undefined): string {
  if (date == null) {
    return '';
  }
  return DateTime
    .fromFormat(date, CUBA_REST_TIMESTAMP_FORMAT)
    .toFormat(DATE_TIME_DISPLAY_FORMAT);
}


export function nullSafeEqualIgnoreCase(str1: string | null | undefined, str2: string | null | undefined) {
  if (isEmpty(str1) && isEmpty(str2)) return true;
  if (!str1 || !str2) return false;
  return str1.toLocaleLowerCase() === str2.toLocaleLowerCase();
}


export function nullSafeEqual(str1: string | null | undefined, str2: string | null | undefined) {
  if (isEmpty(str1) && isEmpty(str2))  return true;
  return str1 === str2;
}

export function isEmpty(value?: string | null): boolean {
  if (value == null) {
    return true;
  }
  return value.length < 1;
}

export function isURL(value = ''): boolean {
  try {
    new URL(value);
    return true;
  } catch (e) {
    return false;
  }
}

export function getUrlParam(urlString = '', param = ''): string | null {
  if (!isURL(urlString)) return null;
  const url = new URL(urlString);
  return url.searchParams.get(param);
}

