import React, {Component} from 'react';
import {
  IonBadge,
  IonButton,
  IonContent, IonIcon, IonLabel,
  IonSpinner,
  IonTabBar,
  IonTabButton} from "@ionic/react";
import {Redirect, Route, Switch} from "react-router";
import {Settings} from "./settings/Settings";
import {DeviceList} from "./devices/list/DeviceList";
import {inject, observer} from "mobx-react";
import {MainStore, MainStoreInjected} from "../mainStore";
import {action} from "mobx";
import {Login} from "./login/Login";
import {CenteredColumn, CenteredRow} from "./common/Centered";
import {Role} from "../cuba/enums";
import {ClientTaskList} from "./tasks/list/ClientTaskList";
import {DeviceEdit} from "./devices/edit/DeviceEdit";
import {
  CLIENT_DEVICE_EDIT,
  CLIENT_TASKS,
  DEVICE_EDIT,
  DEVICES,
  SETTINGS,
  TASKS,
  DEVICE_DETECTION,
  TASK_EDIT, SUB_TASKS, PARENT_TASK_EDIT
} from "../routes";
import {TaskList} from "./tasks/list/TaskList";
import {DeviceDetection} from "./devices/DeviceDetection";
import {TaskEdit} from "./tasks/edit/TaskEdit";
import {ClientDeviceEdit} from "./devices/edit/ClientDeviceEdit";
import {TaskListStore, TaskListStoreInjected} from "./tasks/list/taskListStore";
import {SubTaskList} from './tasks/list/SubTaskList';
import {switcher, print, filing, qrScanner} from 'ionicons/icons';

@inject(MainStore.NAME, TaskListStore.NAME)
@observer
export class AppBootstrap extends Component<MainStoreInjected & TaskListStoreInjected> {

  render() {

    const {initializationStatus, authenticated, loginStatus} = this.props!.mainStore;
    const {taskCount} = this.props!.taskListStore;

    switch (initializationStatus) {
      case "IN_PROGRESS":
        return (
          <IonContent>
            <CenteredRow>
              <IonSpinner/>
            </CenteredRow>
          </IonContent>
        );
      case 'FAILED':
        return (
          <CenteredColumn>
            Unable to connect to server.
            <IonButton size='small' onClick={this.retryInit}>Retry</IonButton>
          </CenteredColumn>
        );
      case 'INVALID_ROLE':
        return (
          <CenteredColumn>
            <div style={{padding: '10px', textAlign: 'center'}}>
              У вашего пользователя неправильно настроены права доступа в системе, свяжитесь с администратором.
            </div>
            <IonButton size='small' onClick={this.props!.mainStore.logout}>Выйти</IonButton>
          </CenteredColumn>
        );
    }

    if (!authenticated) {
      return <Login loginSubmit={(l, p) => this.props.mainStore!.login(l, p)} loginStatus={loginStatus}/>
    }

    return (
      <>
        <Switch>
          <Route path={SETTINGS} component={Settings}/>
          <Route path={DEVICES} component={DeviceList}/>
          <Route path={DEVICE_EDIT} component={DeviceEdit}/>
          <Route path={TASKS} component={TaskList}/>
          <Route path={TASK_EDIT} component={TaskEdit} key={TASK_EDIT}/>

          {/* workaround - task edit component not refreshed, if only 'id' changed in new location due history#push
          so we need to add new route for parent task,
          key property is obligatory, elsewhere page not refresh even with different path */}
          <Route path={PARENT_TASK_EDIT} component={TaskEdit} key={PARENT_TASK_EDIT}/>

          <Route path={SUB_TASKS} component={SubTaskList}/>
          <Route path={DEVICE_DETECTION} component={DeviceDetection}/>

          <Redirect exact from="/" to={this.tasksRef}/>

          <Route path={CLIENT_TASKS} component={ClientTaskList}/>

          <Route path={CLIENT_DEVICE_EDIT} component={ClientDeviceEdit}/>

        </Switch>

        <div className="ios-safe-area-fix">
          <IonTabBar slot="bottom">
            <IonTabButton tab="settings" href={SETTINGS}>
              <IonIcon icon={switcher}/>
              <IonLabel>Настройки</IonLabel>
            </IonTabButton>

            <IonTabButton tab="devices" href={DEVICES}>
              <IonIcon icon={print}/>
              <IonLabel>Устройства</IonLabel>
            </IonTabButton>

            <IonTabButton tab="tasks" href={this.tasksRef}>
              <IonIcon icon={filing}/>
              <IonLabel>Задачи</IonLabel>
              <IonBadge>{taskCount}</IonBadge>
            </IonTabButton>

            <IonTabButton tab="qr" href={DEVICE_DETECTION}>
              <IonIcon icon={qrScanner}/>
              <IonLabel>QR Сканнер</IonLabel>
            </IonTabButton>

          </IonTabBar>
        </div>

      </>
    );
  }

  get roleFull() {
    return this.props.mainStore.role === Role.FULL;
  }

  get tasksRef() {
    return this.roleFull ? TASKS : CLIENT_TASKS;
  }

  @action
  private retryInit = () => {
    this.props.mainStore!.initialize();
  }
}
