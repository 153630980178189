import { StandardEntity } from "./base/sys$StandardEntity";
import { DeviceData } from "./printers_DeviceData";
import { DeviceLogEntry } from "./printers_DeviceLogEntry";
import { Department } from "./printers_Department";
import {
  IdentificationStatus,
  PollStatus,
  ConnectionType
} from "../enums/enums";
import { Location } from "./printers_Location";
import { Poller } from "./printers_Poller";
import { Device } from "./printers_Device";
import { Client } from "./printers_Client";
import { Office } from "./printers_Office";
import { PrinteraTask } from "./printers_PrinteraTask";
import { Contract } from "./printers_Contract";
export class DeviceAssignment extends StandardEntity {
  static NAME = "printers_DeviceAssignment";
  initialData?: DeviceData | null;
  logs?: DeviceLogEntry[] | null;
  department?: Department | null;
  assignStatus?: IdentificationStatus | null;
  isActive?: boolean | null;
  responsiblePersonPhone?: string | null;
  responsiblePersonEmail?: string | null;
  startDateTime?: any | null;
  endDateTime?: any | null;
  location?: Location | null;
  responsiblePerson?: string | null;
  poller?: Poller | null;
  identificationStatus?: IdentificationStatus | null;
  pollStatus?: PollStatus | null;
  deviceData?: DeviceData[] | null;
  currentIpAddress?: string | null;
  device?: Device | null;
  connectionType?: ConnectionType | null;
  manualInput?: boolean | null;
  lastUpdate?: any | null;
  client?: Client | null;
  office?: Office | null;
  printeraTasks?: PrinteraTask[] | null;
  contract?: Contract | null;
  printedBlackWhite?: any | null;
  printedColor?: any | null;
  scanned?: any | null;
  printedBlackWhitePeriod?: any | null;
  printedColorPeriod?: any | null;
  scannedPeriod?: any | null;
  redirection?: DeviceAssignment | null;
  instanceNameAuto?: string | null;
}
export type DeviceAssignmentViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "mobile-deviceAssignment-list"
  | "with-initial-data"
  | "deviceAssignment-light-view"
  | "deviceAssignment-scanning-view"
  | "deviceAssignment-edit"
  | "deviceAssignment-identification-status"
  | "deviceAssignment-task"
  | "deviceAssignment-connectLAN"
  | "deviceAssignment-light-view-with-deviceData"
  | "deviceData-edit-view"
  | "deviceAssignment-view"
  | "deviceAssignment-with-local-device"
  | "deviceAssignment-import-view"
  | "monitoring-view"
  | "monitoring-view-initial-data"
  | "deviceAssignment-pivot-view"
  | "device-assignment-with-instance-name"
  | "device-assignment-rest-for-warehouse"
  | "device-assignment-with-office"
  | "deviceAssignment-log-update-view"
  | "deviceAssignment-auto-task-view"
  | "device-assignment-client-view"
  | "deviceAssignment-resetCounters-view"
  | "invoice-deviceAssignment-view"
  | "device-assignment-ip-view"
  | "device-assignment-with-device-model"
  | "warehouse-device-assignment";
export type DeviceAssignmentView<
  V extends DeviceAssignmentViewName
> = V extends "_minimal"
  ? Pick<DeviceAssignment, "id" | "instanceNameAuto">
  : V extends "_local"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "instanceNameAuto"
    >
  : V extends "_base"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
    >
  : V extends "mobile-deviceAssignment-list"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "endDateTime"
      | "device"
      | "location"
      | "connectionType"
      | "pollStatus"
      | "identificationStatus"
    >
  : V extends "with-initial-data"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "initialData"
    >
  : V extends "deviceAssignment-light-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "office"
      | "deviceData"
      | "initialData"
      | "poller"
      | "device"
      | "location"
    >
  : V extends "deviceAssignment-scanning-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "instanceNameAuto"
      | "device"
    >
  : V extends "deviceAssignment-edit"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "contract"
      | "client"
      | "office"
      | "initialData"
      | "poller"
      | "device"
      | "location"
      | "department"
      | "logs"
      | "redirection"
    >
  : V extends "deviceAssignment-identification-status"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "initialData"
      | "location"
      | "poller"
      | "device"
      | "client"
      | "office"
    >
  : V extends "deviceAssignment-task"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "office"
      | "device"
      | "contract"
      | "location"
    >
  : V extends "deviceAssignment-connectLAN"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "poller"
    >
  : V extends "deviceAssignment-light-view-with-deviceData"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "deviceData"
      | "poller"
      | "device"
      | "client"
      | "office"
    >
  : V extends "deviceData-edit-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "device"
      | "lastUpdate"
      | "currentIpAddress"
      | "startDateTime"
      | "initialData"
    >
  : V extends "deviceAssignment-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "device"
      | "poller"
      | "location"
      | "client"
      | "office"
      | "department"
      | "initialData"
      | "contract"
      | "redirection"
    >
  : V extends "deviceAssignment-with-local-device"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "instanceNameAuto"
      | "device"
    >
  : V extends "deviceAssignment-import-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "initialData"
      | "device"
      | "office"
      | "department"
      | "location"
      | "client"
    >
  : V extends "monitoring-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "printedBlackWhite"
      | "scanned"
      | "printedColor"
      | "printedBlackWhitePeriod"
      | "scannedPeriod"
      | "printedColorPeriod"
      | "device"
      | "client"
      | "location"
      | "office"
      | "department"
      | "redirection"
    >
  : V extends "monitoring-view-initial-data"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "printedBlackWhite"
      | "scanned"
      | "printedColor"
      | "printedBlackWhitePeriod"
      | "scannedPeriod"
      | "printedColorPeriod"
      | "device"
      | "client"
      | "location"
      | "office"
      | "department"
      | "redirection"
      | "initialData"
    >
  : V extends "deviceAssignment-pivot-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "endDateTime"
      | "location"
      | "identificationStatus"
      | "pollStatus"
      | "device"
      | "connectionType"
      | "manualInput"
      | "client"
      | "office"
      | "startDateTime"
      | "department"
    >
  : V extends "device-assignment-with-instance-name"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "device"
    >
  : V extends "device-assignment-rest-for-warehouse"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "device"
      | "office"
    >
  : V extends "device-assignment-with-office"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "office"
      | "location"
      | "contract"
    >
  : V extends "deviceAssignment-log-update-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "currentIpAddress"
      | "device"
      | "connectionType"
      | "location"
      | "responsiblePerson"
      | "office"
      | "department"
      | "redirection"
    >
  : V extends "deviceAssignment-auto-task-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "client"
      | "office"
    >
  : V extends "device-assignment-client-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "device"
      | "client"
      | "contract"
    >
  : V extends "deviceAssignment-resetCounters-view"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "instanceNameAuto"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "initialData"
      | "location"
      | "poller"
      | "device"
      | "client"
      | "office"
      | "contract"
      | "printeraTasks"
      | "department"
      | "scannedPeriod"
      | "printedBlackWhitePeriod"
      | "printedColorPeriod"
    >
  : V extends "invoice-deviceAssignment-view"
  ? Pick<DeviceAssignment, "id" | "instanceNameAuto" | "device">
  : V extends "device-assignment-ip-view"
  ? Pick<DeviceAssignment, "id" | "instanceNameAuto" | "device">
  : V extends "device-assignment-with-device-model"
  ? Pick<DeviceAssignment, "id" | "instanceNameAuto" | "device">
  : V extends "warehouse-device-assignment"
  ? Pick<
      DeviceAssignment,
      | "id"
      | "isActive"
      | "responsiblePersonPhone"
      | "responsiblePersonEmail"
      | "startDateTime"
      | "endDateTime"
      | "responsiblePerson"
      | "identificationStatus"
      | "pollStatus"
      | "currentIpAddress"
      | "connectionType"
      | "manualInput"
      | "lastUpdate"
      | "instanceNameAuto"
      | "contract"
      | "device"
    >
  : never;
