export const ConnectionTypeCaption = {
  NOT_CONNECTED: 'Не подключено',
  USB: 'USB',
  LAN_THROUGH_USB: 'LAN через USB',
  LAN: 'LAN'
};

export enum Role {
  FULL = 'accessMobileAppAsTechnic',
  CLIENT = 'accessMobileAppAsClient',
  CLIENT_ADMIN = 'accessMobileAppAsClientAdmin'
}

export const PrinteraTaskPriorityCaption = {
  HIGH: 'Высокий',
  NORMAL: 'Обычный',
  LOW: 'Низкий'
};

export const PrinteraTaskStatusCaption = {
  NEW: 'Новая задача',
  IN_PROGRESS: 'В работе',
  COMPLETE: 'Выполнена',
  CANCELED: 'Отменена',
  ASSIGNED_TO_GROUP: 'Назначена на группу',
  ASSIGNED_OPERATOR: 'Назначен оператор',
  ASSIGNED_MANAGER: 'Назначен менеджер',
  ASSIGNED_TECHNICIAN: 'Назначен техник',
  ASSIGNED_DRIVER: 'Назначен водитель',
  ASSIGNED_TO_USER: 'Назначена на пользователя',
  VERIFICATION: "Проверка оператором"
};

export const PollStatusCaption = {
  LESS_ONE_DAY: "Доступен",
  LESS_THREE_DAYS: "<3 дней",
  LESS_TWO_WEEKS: "<2 недель",
  LESS_ONE_MONTH: "<1 месяца",
  MORE_ONE_MONTH: ">1 месяца",
  NOT_AVAILABLE: 'Не доступен',
  MANUAL: 'Ручной ввод',
};

export const IdentificationStatusCaption = {
  INACTIVE_ASSIGNMENT: 'Архивная привязка',
  LAN_WITH_MANUAL_INPUT: 'LAN ручной ввод',
  NOT_CONNECTED: 'Не подключено',
  NO_CLIENT: 'Не задан клиент',
  NO_INITIAL_VALUES_FOR_PRINTED_BW: 'Нет начальных значений (ч.б.)',
  NO_INITIAL_VALUES_FOR_PRINTED_COLOR: 'Нет начальных значений (цвет.)',
  NO_INITIAL_VALUES_FOR_SCANNED: 'Нет начальных значений (скан.)',
  NO_INITIAL_VALUES_SET: 'Нет начальных значений',
  NO_IP: 'Не задан IP',
  NO_LOCATION: 'Не указано местоположение',
  NO_MODEL: 'Не задана модель',
  NO_OFFICE: 'Не задан офис',
  NO_POLLER: 'Не выбран поллер',
  TOTAL_PRINTED_BW_NOT_SET: 'Не настроен счетчик (ч.б.)',
  TOTAL_PRINTED_COLOR_NOT_SET: 'Не настроен счетчик (цвет.)',
  TOTAL_SCANNED_NOT_SET: 'Не настроен счетчик (скан.)',
  SNMP_MODEL_NAME_NOT_SET: 'Не задано имя модели в SNMP',
  SNMP_VERSION_NOT_SET: 'Не задана версия SNMP',
  OK: 'ОК'
};

export enum TaskState {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export const TaskStateCaption = {
  ACTIVE: 'Активна',
  COMPLETED: 'Завершена',
};

export enum PrinteraTaskTypeCodes {
  Internal_task = 'Internal_task',
  Incident = 'Incident',
  request = 'request',
  sub_task = 'sub_task'
}

export function getEnumEntries(en: any, enCaptions: any) {
  return Object.entries(en).map(([key, value]) => {
    return {
      id: key,
      _instanceName: '' + getEnumCaption(value, enCaptions)
    }
  })
}

export function getEnumCaption(en: any, enCaptions: any) {
  return en ? enCaptions[en] : '';
}


