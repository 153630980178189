import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {DeviceEditStoreInjected} from '../deviceEditStore';
import {PrinteraCreateModal} from '../../../common/PrinteraCreateModal';
import {SerializedEntity} from '@cuba-platform/rest';
import {observable, runInAction} from 'mobx';
import {PrinteraSelect} from '../../../common/PrinteraSelect';
import {PrinteraEditItem} from '../../../common/PrinteraEditItem';
import {IonRadio, IonRadioGroup, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/react';
import {MainStoreInjected} from '../../../../mainStore';
import {Consumables} from '../../../../cuba/entities/printers_Consumables';
import {Manufacturer} from '../../../../cuba/entities/printers_Manufacturer';

type Props = {
  resetConsModal:  () => void
  showConsModal: boolean
}

const injectStoresToProps = (stores: DeviceEditStoreInjected & MainStoreInjected) => {
  const{ cartridgeConsumablesTypesEntities } = stores.mainStore;

  return {
    consumablesTypeData: cartridgeConsumablesTypesEntities,
    deviceEditStore: stores.deviceEditStore
  }
};

@inject(injectStoresToProps)
@observer
export class ConsumablesReplaceModal extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  @observable savingConsError?: string;

  componentDidMount() {
    const { deviceEditStore, consumablesTypeData } = this.props;
    if (deviceEditStore && consumablesTypeData && consumablesTypeData.length > 0) {
      deviceEditStore.consTypeId = consumablesTypeData[0].id;
    }
  }

  render() {

    const {
      consumablesTypeData, resetConsModal, showConsModal, deviceEditStore
    } = this.props;

    if (!deviceEditStore) return <></>;

    const {
      filteredCons, savingCons, toggleCons, deviceInitialCaption, saveCons
    } = deviceEditStore;

    return (
      <PrinteraCreateModal header='Замена РМ'
                           caption={'Устройство ' + deviceInitialCaption}
                           show={showConsModal}
                           saving={savingCons === true}
                           errorMsg={this.savingConsError}
                           resetError={() => this.savingConsError = undefined}
                           onClose={resetConsModal}
                           onSave={() => saveCons()
                             .then(() => {
                               runInAction(() => {
                                 resetConsModal();
                               });
                             })
                             .catch(e => {
                               this.savingConsError = e.message
                             })}>



        <IonList>

          <IonListHeader>Расходные материалы</IonListHeader>

          <IonListHeader>Фильтр</IonListHeader>


          {consumablesTypeData && consumablesTypeData.length === 1 ? <IonListHeader>Тип РМ: {consumablesTypeData[0].name}</IonListHeader>:
            <PrinteraSelect entities={consumablesTypeData} value={deviceEditStore.consTypeId}
                            onValueChange={e => deviceEditStore.consTypeId = e.detail.value} caption='Тип РМ' />
          }

          {/*<PrinteraSelect entities={[]} value={deviceEditStore.consCode} onValueChange={e => deviceEditStore.consCode = e.detail.value} caption='Код' />*/}
          <PrinteraEditItem caption='Код' value={deviceEditStore.consCode}
                            onValueChange={e => deviceEditStore.consCode = e.detail.value} placeholder={'Введите для фильтрации'}/>

          {filteredCons && (<IonRadioGroup onIonChange={e => toggleCons && toggleCons(e.detail.value, true)}>
            {filteredCons.map(wareHouse => {
                const cons = wareHouse.consumable || {};
                return (<IonItem key={cons.id}>
                  <IonRadio value={cons.id} style={{marginRight: '5px'}}
                    // onChange={e => toggleCons && toggleCons(e, e.checked)}
                  />
                  <IonLabel>{consCaption(cons)}</IonLabel>
                </IonItem>)
              }
            )}
          </IonRadioGroup>)}
          {!filteredCons || filteredCons.length === 0 ? <IonListHeader>Не найдено подходящих РМ</IonListHeader>: ''}

        </IonList>

      </PrinteraCreateModal>

    );
  }

}

function consCaption(cons: Consumables) {
  const color = cons.color ? cons.color : '';
  const manufacturer = cons.manufacturer ? (cons.manufacturer as SerializedEntity<Manufacturer>)._instanceName: '';
  return [cons.code, color, manufacturer].join(' ');
}



