import React, {Component} from "react";
import {action, computed, observable, reaction} from "mobx";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon, IonItem, IonList,
  IonModal, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {restServices} from "../../../cuba/services";
import {cubaREST} from "../../../index";
import {ContextName, TransitionButton} from "../../../cuba/other/printers_TransitionButton";
import {TransitionUser} from "../../../cuba/other/printers_TransitionUser";
import {PrinteraTaskView} from "../../../cuba/entities/printers_PrinteraTask";
import {close as iconClose} from "ionicons/icons";
import {observer} from "mobx-react";
import {CommentContextComponent, CommentContextStore} from "./contexts/Comment";

type Props = {
  showModal: boolean,
  userId: string | undefined,
  transitionButton: TransitionButton,
  task: PrinteraTaskView<'printeraTask-view'>,
  onClose: (e: any) => void;
  onSave: () => void;
};

interface ContextStoreConstructor {
  new (config: any): ContextStore
}
interface ContextStore {
  processContext: () => Promise<void>;
  status: "success"| "error" | "idle" | "pending";
  error: string;
}

type ContextsDictionary = {
  [id in ContextName]: {
    component: any;
    store: ContextStoreConstructor;
  } 
}

export type ContextProps<T> = {
  store: T,
};

const contexts: ContextsDictionary = {
  'comment': {
    store: CommentContextStore,
    component: CommentContextComponent
  }
}

@observer
export class TaskDoTransitionModal extends Component<Props> {
  @observable usersStatus: "idle"|"pending"|"error"|"success" = "idle";
  @observable users: TransitionUser[] = [];
  @observable selectedUser?: TransitionUser;
  @observable contextStore?: ContextStore;
  @observable status = "idle";
  @observable error = "";

  componentDidMount() {
    const transitionIDReaction = () => {
      const {context, isResetAssignee} = this.props.transitionButton.transitionDto
      this.createContextConfig();
      if (!isResetAssignee) {
        this.loadUsers();
      } else if(isResetAssignee && !context) {
        this.doTransaction()
          .then((e) => {
            console.log(e)
          })
      }
    };
    reaction(
      () => this.props.transitionButton.transitionDto.id,
      transitionIDReaction
    );
    transitionIDReaction();
  }

  @action
  createContextConfig() {
    const { context } = this.props.transitionButton.transitionDto;

    if (context) {
      const contextConfig = contexts[context];

      this.contextStore = new contextConfig.store({
        task: this.props.task
      });
    }
  }


  @action
  loadUsers() {
    this.users = [];
    this.selectedUser = undefined;

    this.usersStatus = "pending";
    const {id, context} = this.props.transitionButton.transitionDto;
    restServices.printers_MobileApiService.getAllowedUsersForTransition(cubaREST, {handleAs: 'json'})({
      transitionId: id,
      taskId: this.props.task.id
    })
      .then(action((users) => {
        this.usersStatus = "success";
        this.users = users;
        if (this.users.length === 1 && !context) {
          this.doTransaction()
            .catch(e => { console.log(e) })
        }
      }))
      .catch(action((e) => {
        this.usersStatus = "error";
      }));
  }

  @computed get submitDisabled() {
    return !this.props.transitionButton.transitionDto.isResetAssignee && this.users.length === 0;
  }

  @action
  async doTransaction() {
    const {isResetAssignee, context} = this.props.transitionButton.transitionDto;
    if (context && !this.contextStore) return;
    try {
      if (!this.selectedUser && this.users.length > 1) throw new Error("Не указан ответственный");
      let assigneeId = this.selectedUser?.id || null;
      if (!assigneeId && this.users.length === 1) assigneeId = this.users[0].id;
      if (!isResetAssignee && !assigneeId) throw new Error('Не удалось указать ответственного');
      this.status = "pending";
      if (context && this.contextStore) {
        await this.contextStore.processContext();
        if (this.contextStore.status === "error") {
          this.status = "error";
          this.error = this.contextStore.error;
          return;
        }
      }
      await restServices.printers_MobileApiService.doTransition(cubaREST)({
        taskId: this.props.task.id,
        transitionId: this.props.transitionButton.transitionDto.id,
        assigneeId
      });
      this.status = "success";
      this.props.onSave();
    } catch (e) {
      this.status = "error";
      this.error = `${e}`;
    }
  }

  @action
  onUserChange(e: CustomEvent) {
    this.selectedUser = this.users.find(user => e.detail.value === user.id);
  }

  @computed private get contextComponent() {
    if (this.contextStore) {
      const { context } = this.props.transitionButton.transitionDto;
      const contextConfig = contexts[context]
      const ContextComponent = contextConfig.component;
      return (<ContextComponent store={this.contextStore} />)
    }
    return '';
  }

  render() {
    return (
      <IonModal
        isOpen={this.props.showModal}
        backdropDismiss={false}
        onDidDismiss={() => {
        }}
      >
        <IonHeader>
          <IonToolbar>

            <IonTitle>
              {this.props.transitionButton.name}
            </IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={this.props.onClose} fill='clear'>
                <IonIcon icon={iconClose} slot='icon-only'/>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {this.users.length > 1 && <IonItem>
              Ответственный
              <IonSelect onIonChange={(e) => this.onUserChange(e)}>
                {this.users.map(user => (<IonSelectOption value={user.id} key={user.id}>{user.fullName}</IonSelectOption>))}
              </IonSelect>
            </IonItem>}
            {this.contextComponent}
          </IonList>
        </IonContent>
        <IonButton disabled={this.submitDisabled} onClick={() => this.doTransaction()}>{this.props.transitionButton.name}</IonButton>

        <IonAlert
          isOpen={this.status === "pending"}
          onDidDismiss={() => {
          }}
          message='Выполняем действие'/>

        <IonAlert
          isOpen={this.status === "error" && !!this.error}
          onDidDismiss={() => {
            this.error = '';
          }}
          buttons={['OK']}
          message={this.error}/>
      </IonModal>
    )
  }
}