import {PrinteraEditItem} from "./PrinteraEditItem";
import {formatServerDateTime} from "../../util/stringUtils";
import React from "react";

type Props = {
  caption: string
  date: any
};

export const PrinteraReadonlyDate = (props: Props) =>
  <PrinteraEditItem caption={props.caption}
                    value={props.date ? formatServerDateTime(props.date) : ''}
                    readonly={true}/>;