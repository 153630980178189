import React, {FunctionComponent} from "react";
import {IonList} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {DeviceEditStore, DeviceEditStoreInjected} from "../deviceEditStore";
import {DeviceDataModal} from "./DeviceDataModal";
import {PrinteraEditItem} from "../../../common/PrinteraEditItem";
import {formatServerDateTime} from "../../../../util/stringUtils";

type Props =  DeviceEditStoreInjected & {
  readonly?: boolean
};

export const DeviceEditData: FunctionComponent<Props> =
  inject(DeviceEditStore.NAME)(observer(({readonly, deviceEditStore}: Props) => {

    const {
      showPrintedColor, showScanned, latestDeviceData, setLatestDeviceData, setSaveResult, pollStatusCaption
    } = deviceEditStore;

    return <>
      <IonList>

        {/* todo do not allow if no initial data */}
        <DeviceDataModal
          readonly={readonly}
          onSave={(dd) => {
          setLatestDeviceData(dd);
          setSaveResult('Данные сохранены');
        }}/>

        <PrinteraEditItem caption="Статус опроса" value={pollStatusCaption} readonly={true}/>

        <PrinteraEditItem caption="Черно-белые" readonly={true}
                          value={latestDeviceData && latestDeviceData.printedBlackWhite}/>

        {showPrintedColor &&
        <PrinteraEditItem caption="Цветные" readonly={true}
                          value={latestDeviceData && latestDeviceData.printedColor}/>
        }

        {showScanned &&
        <PrinteraEditItem caption="Отсканировано" readonly={true}
                          value={latestDeviceData && latestDeviceData.scanned}/>

        }

        <PrinteraEditItem caption="Время опроса" readonly={true}
                          value={latestDeviceData && formatServerDateTime(latestDeviceData.scanTimeStamp)}/>

      </IonList>
    </>;

}));
