import React, {Component} from 'react';
import {IonContent, IonIcon, IonInput, IonItem, IonButton, IonAlert, IonLabel, IonList} from "@ionic/react";
import QrReader from 'react-qr-reader';
import {inject, observer} from "mobx-react";
import {action, observable, toJS} from "mobx";
import {cubaREST} from "../../index";
import {Condition, EntityFilter, SerializedEntity} from "@cuba-platform/rest";
import {pathToDevice, pathToDeviceAddNumber} from "../../routes";
import {Device} from "../../cuba/entities/printers_Device";
import {Subject} from "rxjs";
import {isBlank} from "../../util/validationUtils";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {RouteComponentProps} from "react-router";
import {MainStore, MainStoreInjected} from "../../mainStore";
import {Role} from "../../cuba/enums";
import {DeviceAssignment} from "../../cuba/entities/printers_DeviceAssignment";
import {search} from 'ionicons/icons';
import {getUrlParam} from "../../util/stringUtils";

type Props = RouteComponentProps<void> & Pick<MainStore, 'role' | 'client'>

@inject((stores: MainStoreInjected) => ({
  role: stores.mainStore.role,
  client: stores.mainStore.client,
}))
@observer
export class DeviceDetection extends Component<Props> {

  @observable deviceNumber = '';
  @observable addingDevice = false;
  @observable deviceNotFound = false;
  @observable selectedDeviceId?: string;

  @observable filteredDevices: SerializedEntity<Device>[] = [];

  @observable error?: string;

  snInputRef = React.createRef<HTMLIonInputElement>();

  serialNumberSubj = new Subject<string>();

  componentDidMount(): void {
    this.serialNumberSubj.asObservable().pipe(
      debounceTime(500),
      filter(sn => {
        return sn.length > 2
      }),
      switchMap((sn) => {
        const condition: Condition = {property: 'serialNumber', operator: 'contains', value: toJS(sn)};
        const loadOpts = {view: '_minimal'};
        return cubaREST.searchEntities<Device>(Device.NAME, {conditions: [condition]}, loadOpts);
      })
    ).subscribe((res) => {
      this.filteredDevices = res;
    });

  }

  render() {

    const {filteredDevices} = this;
    const {history} = this.props;

    return (
      <IonContent>
        <IonItem>
          <IonLabel>Инв. номер</IonLabel>
          <IonInput value={this.deviceNumber}
                    onIonChange={this.handleDeviceNumberInputChange}/>
          <IonButton onClick={this.searchDeviceByNumber}
                     fill="clear"
                     shape="round"
                     slot='end'>
            {!!this.deviceNumber && !this.addingDevice &&
            <IonIcon icon={search}
                     slot='icon-only'/>}
          </IonButton>
        </IonItem>

        {!this.addingDevice && !this.deviceNotFound &&
        <div style={{width: '100vw', maxWidth: '70vh'}}>
          <QrReader
            delay={500}
            onError={this.handleQrReadingError}
            onScan={this.handleQrRead}
            style={{width: "100%"}}
          />
        </div>}

        {this.deviceNotFound &&
        <IonButton fill='clear' size='default' expand='full' onClick={() => this.deviceNotFound = false}>
          устройство не найдено
        </IonButton>
        }

        {this.addingDevice &&
        <>
          <IonItem>
            <IonInput placeholder='Введите серийный номер'
                      ref={this.snInputRef}
                      onIonChange={this.handleSerialNumberInputChange}/>
          </IonItem>
          <IonList>
            {filteredDevices && filteredDevices.map((dev) => (
              <IonItem button={true}
                       detail={true}
                       onClick={() => {
                         if (!isBlank(dev.deviceNumber)) {
                           this.selectedDeviceId = dev.id;
                         } else {
                           history.push(pathToDeviceAddNumber(dev.id, this.deviceNumber))
                         }
                       }}>
                {dev._instanceName}
              </IonItem>
            ))}
          </IonList>
        </>
        }
        <IonAlert
          isOpen={this.selectedDeviceId != null}
          onDidDismiss={() => this.selectedDeviceId = undefined}
          message='Устройство уже содержит номер, хотите заменить его?'
          buttons={[{
            text: 'Да', handler: () => {
              history.push(pathToDeviceAddNumber(this.selectedDeviceId!, this.deviceNumber))
            }
          }, 'Нет']}/>

        <IonAlert
          isOpen={this.error != null}
          onDidDismiss={() => this.error = undefined}
          message={this.error}
          buttons={['OK']}/>
      </IonContent>
    );
  }

  @action
  private handleQrRead = (res: string | null) => {
    if (res) {
      const subjectParam = getUrlParam(res, 'subject');
      const deviceNumber = subjectParam || res;
      if (deviceNumber !== this.deviceNumber) {
        this.deviceNumber = deviceNumber;
        this.addingDevice = false;
        this.searchDeviceByNumber();
      }
    }
  };

  @action
  private handleDeviceNumberInputChange = (e: CustomEvent) => {
    this.deviceNumber = e.detail.value;
    this.addingDevice = false;
  };

  private handleSerialNumberInputChange = (e: CustomEvent) => {
    this.serialNumberSubj.next(e.detail.value);
  };

  @action
  private searchDeviceByNumber = () => {
    const entityFilter: EntityFilter = {
      conditions: [{property: 'device.deviceNumber', operator: '=', value: toJS(this.deviceNumber)}]
    };

    if (this.props.client && this.isClientOrAdmin) {
      entityFilter.conditions.push({
        property: 'client.id',
        operator: '=',
        value: this.props.client.id
      });
    }

    const loadOpts = {view: 'mobile-deviceAssignment-list'};
    cubaREST.searchEntities<DeviceAssignment>(DeviceAssignment.NAME, entityFilter, loadOpts).then((res) => {
      if (res.length > 0) {
        this.props.history.push(pathToDevice(res[0].device!.id, this.props.role));
      } else {
        if (this.isClientOrAdmin) {
          this.deviceNotFound = true;
          return;
        }

        this.addingDevice = true;
        setTimeout(() => {
          if (this.snInputRef.current) {
            this.snInputRef.current.setFocus();
          }
        }, 5);

      }
    })
  };

  @action
  private addDeviceNumber = () => {
    this.addingDevice = true;
  };

  private handleQrReadingError = () => {
    // noop;
  };

  private get isClientOrAdmin() {
    return this.props.role === Role.CLIENT || this.props.role === Role.CLIENT_ADMIN;
  }
}
