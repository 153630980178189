import React, {FunctionComponent} from "react";
import {IonButton, IonButtons, IonHeader, IonIcon, IonSpinner, IonTitle, IonToolbar} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {DeviceEditStore, DeviceEditStoreInjected} from "../deviceEditStore";
import * as H from "history";
import {save as saveIcon, arrowBack} from 'ionicons/icons';

type Props =  DeviceEditStoreInjected & {
  saving: boolean,
  history: H.History
}

export const DeviceEditHeader: FunctionComponent<Props> =
  inject(DeviceEditStore.NAME)(observer(({saving, history, deviceEditStore}: Props) => {

    const {save, hasUnsavedData} = deviceEditStore;

    return <IonHeader>
    <IonToolbar>

      <IonButtons slot="start">
        <IonButton onClick={() => history.goBack()} fill='clear'>
          <IonIcon icon={arrowBack} slot='icon-only'/>
        </IonButton>
      </IonButtons>

      <IonTitle>Устройство</IonTitle>

      {hasUnsavedData && <IonButtons slot="end">
        <IonButton onClick={save} disabled={saving} fill='clear'>
          {!saving && <IonIcon icon={saveIcon} slot='icon-only'/>}
          {saving && <IonSpinner/>}
        </IonButton>
      </IonButtons>
      }

    </IonToolbar>
  </IonHeader>;
}));
