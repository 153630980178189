import React, {Component} from 'react';
import {IonDatetime, IonItem, IonLabel, IonList, IonTextarea} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import {PrinteraTask} from "../../../cuba/entities/printers_PrinteraTask";
import {MainStoreInjected} from "../../../mainStore";
import {PrinteraSelect} from "../../common/PrinteraSelect";
import {PrinteraTaskType} from "../../../cuba/entities/printers_PrinteraTaskType";
import {isEmpty} from "../../../util/stringUtils";
import {FileUploadItem} from "../../common/FileUploadItem";
import {PrinteraTaskTypeCodes, Role} from "../../../cuba/enums";
import {SerializedEntity} from '@cuba-platform/rest';
import {TaskCreateStoreInjected, TaskData} from './taskCreateStore';
import {PrinteraCreateModal} from '../../common/PrinteraCreateModal';
import {isBlank} from '../../../util/validationUtils';
import {PrinteraEditItem} from '../../common/PrinteraEditItem';

enum TaskCreateMode {
  TASK = 'TASK',
  SUB_TASK = 'SUB_TASK'
}

type Props<T extends TaskCreateMode.TASK | TaskCreateMode.SUB_TASK> = {
  showModal: boolean
  onClose: () => void
  onSave: (savedTask: PrinteraTask) => void

  // todo rewrite with single param - context
  // on create task we need deviceAssignmentId
  deviceAssignmentId: T extends TaskCreateMode.TASK ? string : undefined
  // on create SUB task we need parentTaskId
  parentTaskId: T extends TaskCreateMode.SUB_TASK ? string : undefined
}


const injectStoresToProps = (stores: MainStoreInjected & TaskCreateStoreInjected) => {
  const {userInfo, client, office, taskTypesData, role} = stores.mainStore;
  const {
    taskAssignees, saveTask, taskData, saving, errorMsg, isInternal, changeDataValue, resetError
  } = stores.taskCreateStore;

  return {
    taskTypesData,
    userId: userInfo!.id,
    clientId: client ? client.id : undefined,
    officeId: office ? office.id : undefined,
    role: role,
    taskAssignees, saveTask, taskData, saving, errorMsg, isInternal, changeDataValue, resetError
  }
};

@inject(injectStoresToProps)
@observer
export class TaskCreateModal<T extends TaskCreateMode.TASK | TaskCreateMode.SUB_TASK> extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props<T>> {

  @computed private get mode(): TaskCreateMode {
    return  this.props.deviceAssignmentId && !this.props.parentTaskId
      ? TaskCreateMode.TASK : TaskCreateMode.SUB_TASK
  }

  @computed private get header(): string {
    return this.mode === TaskCreateMode.TASK ? 'Создание задачи' : 'Создание подзадачи'
  }

  @computed private get showAssignee(): boolean {
    if (this.props.isInternal!(this.props.taskData!.printeraTaskType)) return true;
    return this.mode === TaskCreateMode.SUB_TASK;

  }

  @computed private get isSaveEnabled() {
    const {taskData} = this.props;
    if (!taskData || isBlank(taskData.description) || isBlank(taskData.header)) return false;

    if (this.mode === TaskCreateMode.TASK && taskData.printeraTaskType != null) return true;

    if (this.mode === TaskCreateMode.SUB_TASK
      && !isEmpty(taskData.assigneeId) && !isEmpty(taskData.dueDate)) return true;

    return false;
  }

  render() {

    const {taskTypesData, role, taskAssignees, saveTask, deviceAssignmentId, saving, errorMsg,
      changeDataValue, resetError} = this.props;
    const taskTypes = taskTypesData!.entities
      ? taskTypesData!.entities.filter(taskType => taskType.manualCreationIsPossible)
      : [];

    const taskData: TaskData = this.props.taskData!;

    return (
      <PrinteraCreateModal
        header={this.header}
        caption={'Ввод информации о задаче'}
        show={this.props.showModal}
        saving={saving!}
        errorMsg={errorMsg}
        resetError={resetError!}
        disabled={!this.isSaveEnabled}
        onClose={this.props.onClose}
        onSave={() => {
          if (deviceAssignmentId) {
            saveTask!(deviceAssignmentId!, this.props.onSave);
            return;
          }
        }}>

        <IonList>

          <PrinteraEditItem caption='Заголовок' value={taskData.header}
                            onValueChange={e => taskData.header = e.detail.value}/>

          {this.mode === TaskCreateMode.TASK &&
          <PrinteraSelect entities={filterTaskTypes(taskTypes, role!)}
                          value={taskData.printeraTaskType ? taskData.printeraTaskType.id : null}
                          onValueChange={e => {
                            taskData.printeraTaskType = {id: e.detail.value};
                            taskData.assigneeId = undefined;
                          }}
                          caption="Вид задачи"/>}

          <IonItem>
            <IonLabel position="floating">Описание задачи:</IonLabel>
            <IonTextarea value={taskData.description}
                         rows={6}
                         onIonChange={changeDataValue!('description')}/>
          </IonItem>

          {role === Role.FULL && this.showAssignee &&
          <PrinteraSelect entities={taskAssignees}
                          value={taskData.assigneeId}
                          onValueChange={e => taskData.assigneeId = e.detail.value}
                          caption={'Назначить на'}/>}

          {this.mode === TaskCreateMode.SUB_TASK &&
          <IonItem>
            <IonLabel>Завершить до</IonLabel>
            <IonDatetime displayFormat="DD.MM.YYYY, HH:mm" value={taskData.dueDate}
                         onIonChange={e => taskData.dueDate = e.detail!.value!}/>
          </IonItem>}

          {this.mode === TaskCreateMode.TASK && taskData.files.map((fd, i) =>
            <FileUploadItem key={i}
                            fileDescriptorInfo={fd}
                            onFileRemove={() => {
                              taskData.files[i] = undefined;
                              if (i < taskData.files.length - 1) {
                                taskData.files.splice(i, 1)
                              }
                            }}
                            onFileUpload={(ufd) => {
                              taskData.files[i] = ufd;
                              taskData.files.push(undefined);
                            }}/>
          )}
        </IonList>


      </PrinteraCreateModal>
    );
  }

}

function filterTaskTypes(taskTypes: SerializedEntity<PrinteraTaskType>[], role: Role) {
  if (role === Role.FULL) return taskTypes;
  return taskTypes.filter(
    tt => tt.code === PrinteraTaskTypeCodes.Incident || tt.code === PrinteraTaskTypeCodes.request);
}

