import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {initializeApp} from "@cuba-platform/rest";
import {CUBA_REST_URL} from "./config";
import {MainStore} from "./mainStore";
import {DeviceEditStore} from "./components/devices/edit/deviceEditStore";
import {DeviceListStore} from "./components/devices/list/deviceListStore";
import {TaskListStore} from "./components/tasks/list/taskListStore";
import {TaskCreateStore} from './components/tasks/create/taskCreateStore';
import {IonReactRouter} from '@ionic/react-router';

export const cubaREST = initializeApp({
  name: 'printera',
  apiUrl: CUBA_REST_URL,
  storage: window.localStorage
});

export const mainStore = new MainStore();
mainStore.initialize();

export const deviceEditStore = new DeviceEditStore();
export const deviceListStore = new DeviceListStore();
export const taskListStore = new TaskListStore();
export const taskCreateStore = new TaskCreateStore();

ReactDOM.render(
  <IonReactRouter basename={process.env.PUBLIC_URL}>
    <App/>
  </IonReactRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
