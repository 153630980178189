import React, {Component, ReactNode} from "react";

import {IonButton, IonItem, IonList, IonListHeader} from "@ionic/react";
import {observer} from "mobx-react";
import {PrinteraTask} from "../../../../cuba/entities/printers_PrinteraTask";
import {TaskCreateModal} from "../../../tasks/create/TaskCreateModal";
import {action, observable} from "mobx";
import {Link} from "react-router-dom";
import {pathToTask} from "../../../../routes";
import {SerializedEntity} from "@cuba-platform/rest";
import {taskPriorityCaption} from "../../../tasks/tasksCommon";
import {Role} from "../../../../cuba/enums";

type Props = {
  tasks: SerializedEntity<PrinteraTask>[] | null | undefined
  role?: Role;
  activeAssignmentId: string | null | undefined
  onCreateTask: () => void
  hasMore: boolean
  loadMore: () => void
  loading: boolean
  totalCount: number
}

@observer
export class DeviceEditTasks extends Component<Props> {

  @observable showCreateTaskModal = false;

  render() {
    const {tasks, activeAssignmentId, onCreateTask, role, hasMore, loadMore, loading, totalCount} = this.props;
    // remove margin before bottom 'create task' button on CLIENT version
    const listStyle = role === Role.CLIENT ? {'marginBottom': '0'} : undefined;
    const filteredTasks = !tasks ? [] : tasks;
    const tasksCount = hasMore ? `${filteredTasks.length}/${totalCount}`: `${filteredTasks.length}`;

    return (
      <>
        <IonList style={listStyle}>
          <IonListHeader>
            Задачи {filteredTasks && filteredTasks.length > 0 && <>({tasksCount})</>}

            {activeAssignmentId && role !== Role.CLIENT &&
            <IonButton onClick={() => this.showCreateTaskModal = true} expand="full" fill="clear" size="small">
              создать задачу
            </IonButton>}

            <TaskCreateModal showModal={this.showCreateTaskModal}
                             deviceAssignmentId={activeAssignmentId!}
                             parentTaskId={undefined}
                             onClose={action(() => {
                               this.showCreateTaskModal = false;
                             })}
                             onSave={action(() => {
                               this.showCreateTaskModal = false;
                               onCreateTask()
                             })}/>
          </IonListHeader>

          {filteredTasks && filteredTasks!.map((task) => (
            <Link to={pathToTask(task.id)} key={task.id}>
              <IonItem button={true}
                       detail={true}>
                {renderTaskCaption(task as SerializedEntity<PrinteraTask>)}
              </IonItem>
            </Link>
          ))}
          {loading && "Загрузка"}
          {hasMore && !loading && <IonButton fill="clear" size='default' onClick={() => loadMore()}>
            загрузить еще
          </IonButton>}
        </IonList>

        {activeAssignmentId && role === Role.CLIENT &&
        <IonButton fill='clear' size='default' expand='full' onClick={() => this.showCreateTaskModal = true}>
          создать задачу
        </IonButton>
        }
      </>
    );
  }
}

function renderTaskCaption(task: SerializedEntity<PrinteraTask>): ReactNode {
  return (
    <>
      {`#${task.number} ${task._instanceName}`}<br/>
      Приоритет: {taskPriorityCaption(task.priority)}<br/>
    </>
  );
}
