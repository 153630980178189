import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonToolbar
} from "@ionic/react";
import {SerializedEntity} from "@cuba-platform/rest";
import {DeviceEditStoreInjected} from "./deviceEditStore";
import {DeviceAssignment} from "../../../cuba/entities/printers_DeviceAssignment";
import {close as closeIcon} from "ionicons/icons";


type Props = {
  showModal: boolean
  onClose: (e: any) => void
  onSave: (e: SerializedEntity<DeviceAssignment>) => void
}

const injectStoresToProps = (stores: DeviceEditStoreInjected) => {
  const {redirectionDevices} = stores.deviceEditStore;
  return {
    redirectionDevices
  }
};

@inject(injectStoresToProps)
@observer
export class DeviceSearchModal extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  render() {
    const {showModal, onSave, onClose, redirectionDevices} = this.props;

    return (
      <>
        <IonModal isOpen={showModal}
          backdropDismiss={false}
          onDidDismiss={() => {}}>

          <IonHeader>
            <IonToolbar>

              <IonButtons slot="end">
                <IonButton onClick={onClose} fill='clear'>
                  <IonIcon icon={closeIcon} slot='icon-only'/>
                </IonButton>
              </IonButtons>

            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList>
              {redirectionDevices && redirectionDevices.map((da: SerializedEntity<DeviceAssignment>) => (
                <IonItem button={true} key={da.id} onClick={() => onSave(da)}>
                  {da._instanceName}
                </IonItem>
              ))}
            </IonList>
          </IonContent>

        </IonModal>
      </>)
  }

}
