import {MainStoreInjected} from "../../../mainStore";
import {inject, observer} from "mobx-react";
import {Component} from "react";
import React from "react";
import {PrinteraCreateModal} from "../../common/PrinteraCreateModal";
import {observable, runInAction} from "mobx";
import {DeviceLogEntry} from "../../../cuba/entities/printers_DeviceLogEntry";
import {DateTime} from "luxon";
import {CUBA_REST_TIMESTAMP_FORMAT} from "../../../util/stringUtils";
import {DeviceEditStoreInjected} from "./deviceEditStore";
import {cubaREST} from "../../../index";
import {isBlank} from "../../../util/validationUtils";
import {IonButton, IonItem, IonLabel, IonListHeader, IonTextarea} from "@ionic/react";
import {DeviceLogEntryType} from "../../../cuba/enums/enums";

const injectStoresToProps = (stores: MainStoreInjected & DeviceEditStoreInjected) => {
  const {userInfo, client, office} = stores.mainStore;
  const {activeAssignment, addLog} = stores.deviceEditStore;
  return {
    userId: userInfo!.id,
    clientId: client ? client.id : undefined,
    officeId: office ? office.id : undefined,
    assignmentId: activeAssignment ? activeAssignment.id : undefined,
    addLog: addLog
  }
};

@inject(injectStoresToProps)
@observer
export class LogCreateModal extends Component<Partial<ReturnType<typeof injectStoresToProps>>> {

  @observable saving = false;
  @observable errorMsg: string | undefined;
  @observable show = false;
  @observable description: string | null | undefined;

  save = () => {

    if (isBlank(this.description)) {
      this.errorMsg = 'Поле "Запись" должно быть заполнено';
      return;
    }

    const logEntry: DeviceLogEntry = {
      type: DeviceLogEntryType.USER_COMMENT,
      user: {id: this.props.userId},
      date: DateTime.local().toFormat(CUBA_REST_TIMESTAMP_FORMAT),
      description: this.description,
      deviceAssignment: {id: this.props.assignmentId}
    };

    // save

    this.saving = true;
    cubaREST.commitEntity(DeviceLogEntry.NAME, logEntry)
      .then((savedEntity) => {
        runInAction(() => {
          this.saving = false;
          this.description = undefined;
          this.props.addLog!(savedEntity);
          this.show = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.saving = false;
          this.errorMsg = 'Ошибка сервера при сохранении'
        })
      });

  };

  render() {
    return (<>
      <IonListHeader>
        Лог
        <IonButton fill='clear' size='small' expand='full' onClick={() => {
          this.show = true
        }}>
          Добавить запись в лог
        </IonButton>

        <PrinteraCreateModal header='Лог' caption='Добавить запись в лог'
                             show={this.show} errorMsg={this.errorMsg}
                             saving={this.saving}
                             onClose={() => {
                               this.description = undefined;
                               this.show = false;
                             }}
                             onSave={this.save}
                             resetError={() => {
                               this.errorMsg = undefined;
                             }}>

          <IonItem>
            <IonLabel position="floating">Запись: </IonLabel>
            <IonTextarea value={this.description}
                         rows={6}
                         onIonChange={(e) => this.description = e.detail.value}/>
          </IonItem>

        </PrinteraCreateModal>
      </IonListHeader>
    </>);
  }

}