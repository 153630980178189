import React, {Component} from 'react'
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {Location} from "../../../cuba/entities/printers_Location";
import {cubaREST} from "../../../index";
import {observable, runInAction} from "mobx";
import {inject, observer} from "mobx-react";
import {isDigitsOnly, isBlank} from "../../../util/validationUtils";
import {SerializedEntity} from "@cuba-platform/rest";
import {nullSafeEqualIgnoreCase} from "../../../util/stringUtils";
import {MainStoreInjected} from "../../../mainStore";
import {PrinteraCreateModal} from "../../common/PrinteraCreateModal";
import {DeviceEditStoreInjected} from "./deviceEditStore";

type Props = {
  show: boolean
  onClose: () => void
  onSave: (location: Location) => void
}

const injectStoresToProps = (stores: MainStoreInjected & DeviceEditStoreInjected) => {
  const {officeId, locations, reloadLocations} = stores.deviceEditStore;
  return {
    officeId: officeId,
    entities: locations ? locations.entities : [],
    reloadLocations: reloadLocations
  }
};

@inject(injectStoresToProps)
@observer
export class LocationCreateModal extends Component<Partial<ReturnType<typeof injectStoresToProps>> & Props> {

  location: Location = {
    office: {id: this.props.officeId}
  };

  @observable saving = false;
  @observable errorMsg: string | undefined;

  save = () => {

    //validate
    let {name, locationFloor} = this.location;

    const errors: string[] = [];
    if (!name || isBlank(name)) {
      errors.push('- поле "Наименование" не может быть пустым<br>')
    }

    if (!locationFloor || isBlank("" + locationFloor)) {
      errors.push('- поле "Этаж" не может быть пустым<br>')
    } else if (!isDigitsOnly("" + locationFloor)) {
      errors.push('- поле "Этаж" должно содержать только цифры<br>')
    }

    if (errors.length > 0) {
      this.errorMsg = 'Расположение не может быть сохрнено:<br>' + errors.join('');
      return;
    }

    if (this.props.entities && this.props.entities.find(loc => {
      return nullSafeEqualIgnoreCase(loc.name, name) && loc.locationFloor === locationFloor
    })) {
      this.errorMsg = 'Такое расположение уже существует, пожалуйста, выберите его из списка';
      return;
    }

    // save

    this.saving = true;
    cubaREST.commitEntity(Location.NAME, this.location)
      .then((loc) => {
        runInAction(() => {
          this.saving = false;
          this.location.name = null;
          this.location.locationFloor = null;
          //todo workaround - cuba rest should return _instanceName in location, but not
          (loc as SerializedEntity<Location>)._instanceName = '' + loc.name + ', ' + loc.locationFloor + ' этаж';
          this.props.onSave(loc);
          this.props.reloadLocations!()
        });
      })
      .catch(() => {
        runInAction(() => {
          this.saving = false;
          this.errorMsg = 'Ошибка при сохранении расположения.'
        })
      });

  };

  render() {

    const {show, onClose} = this.props;

    return (
      <PrinteraCreateModal header='Создать расположение' caption='Создать новое расположение'
                           show={show} errorMsg={this.errorMsg}
                           saving={this.saving} onClose={onClose}
                           onSave={this.save} resetError={() => {this.errorMsg = undefined}}>

          <PrinteraEditItem caption="Наименование" value={this.location.name}
                            onValueChange={(e) => this.location.name = e.detail.value}/>

          <PrinteraEditItem caption="Этаж" value={this.location.locationFloor ? "" + this.location.locationFloor : ""}
                            onValueChange={(e) => this.location.locationFloor = e.detail.value}/>
      </PrinteraCreateModal>
    );

  }
}
