import {DeviceEditStore, DeviceEditStoreInjected} from "../deviceEditStore";
import React, {FunctionComponent} from "react";
import {inject, observer} from "mobx-react";
import {IonAlert, IonToast} from "@ionic/react";

type Props =  DeviceEditStoreInjected;

export const DeviceEditNotifications: FunctionComponent<Props> =
  inject(DeviceEditStore.NAME)(observer(({deviceEditStore}: Props) => {

    const {saveResult, validationFail, setSaveResult} = deviceEditStore;

    return <>

      <IonAlert
        isOpen={saveResult != null && validationFail}
        onDidDismiss={() => {
          setSaveResult(undefined)
        }}
        message={saveResult}
        buttons={['OK']}/>

      <IonToast
        isOpen={saveResult != null && !validationFail}
        onDidDismiss={() => {
          setSaveResult(undefined)
        }}
        message={saveResult}
        duration={2000}/>

    </>;

  }));
