import {action, computed, observable} from "mobx";
import {PrinteraTask, PrinteraTaskView} from "../../../cuba/entities/printers_PrinteraTask";
import {cubaREST} from "../../..";
import {
  printers_MobileApiService_addCommentToTask_params,
  printers_MobileApiService_addFilesToTask_params,
  restServices
} from "../../../cuba/services";
import {isEmpty} from "../../../util/stringUtils";
import {FileDescriptorInfo} from '../../common/FileUploadItem';
import {TransitionButton} from "../../../cuba/other/printers_TransitionButton";

export class TaskEditStore {

  @observable task?: PrinteraTask;
  @observable isLoading = false;

  @observable savingComment = false;
  @observable commentSaveError: string | undefined = undefined;
  @observable newComment = '';
  @observable commentFiles: Array<FileDescriptorInfo | undefined> = [undefined];
  @observable buttons: TransitionButton[] = [];

  @computed get allSubTasksComplete() {
    const {task} = this;
    if (!task || !task.subTasks) return true;
    return task.subTasks.every(t => t.completed === true);
  }

  @computed get notCompleteSubTaskCount() {
    const {task} = this;
    if (!task || !task.subTasks) return 0;

    let counter = 0;
    task.subTasks.forEach(st => !st.completed ? counter++ : null);
    return counter;
  }

  @action loadTask(taskId: string) {
    this.isLoading = true;
    return cubaREST.loadEntity<PrinteraTaskView<'printeraTask-view'>>(PrinteraTask.NAME, taskId, {view: 'printeraTask-mobile-view'})
      .then(action((task: PrinteraTask) => {
        this.task = task;
        this.loadTransitionButtons().catch(e => {
          console.error(e);
        })
          .finally(() => {
            this.isLoading = false;
          });
      }))
      .catch(action(() => {
        this.isLoading = false;
      }))
  }

  @action loadTransitionButtons() {
    return restServices.printers_MobileApiService.getTransitionButtons(cubaREST, {handleAs: 'json'})({taskId: this.task?.id})
      .then(result => {
        this.buttons = result;
      })
  }


  @action saveComment = () => {
    const comment = this.newComment;

    if (isEmpty(comment)) {
      this.commentSaveError = 'Комментарий не может быть пустым';
      return Promise.reject(this.commentSaveError);
    }

    this.savingComment = true;

    //TODO Why 'id' does not exist in task:PrinteraTaskView<'printeraTask-view'> ?
    const id = this.task ? (this.task as any).id : null;

    if (!id) {
      this.commentSaveError = 'Ошибка при сохранении комментария - задача не найдена';
      return Promise.reject(this.commentSaveError);
    }

    const commentPayload: printers_MobileApiService_addCommentToTask_params = {
      printeraTask: {id},
      comment
    };

    let fileUploadPromise;
    if (this.commentFiles.length > 0) {
      const filesPayload: printers_MobileApiService_addFilesToTask_params = {
        printeraTask: {id},
        uploadedFilesDescriptors: this.commentFiles.slice().reduce<FileDescriptorInfo[]>((acc, fdi) => {
          if (fdi != null) acc.push(fdi);
          return acc;
        }, [])
      };
      fileUploadPromise = restServices.printers_MobileApiService.addFilesToTask(cubaREST)(filesPayload)
    } else {
      fileUploadPromise = Promise.resolve({});
    }

    return Promise.all([
      restServices.printers_MobileApiService.addCommentToTask(cubaREST)(commentPayload),
      fileUploadPromise
    ]).then(() => {
      this.savingComment = false;
      this.newComment = '';
      this.loadTask(id);
    }).catch(() => {
      this.commentSaveError = 'Ошибка сервера при сохранении комментария';
      return Promise.reject(this.commentSaveError);
    })

  };
}
