import { StandardEntity } from "./base/sys$StandardEntity";
export class ConsumablesType extends StandardEntity {
  static NAME = "printers_ConsumablesType";
  name?: string | null;
  isCartridge?: boolean;
}
export type ConsumablesTypeViewName = "_minimal" | "_local" | "_base";
export type ConsumablesTypeView<
  V extends ConsumablesTypeViewName
> = V extends "_minimal"
  ? Pick<ConsumablesType, "id" | "name">
  : V extends "_local"
  ? Pick<ConsumablesType, "id" | "name" | "isCartridge">
  : V extends "_base"
  ? Pick<ConsumablesType, "id" | "name">
  : never;
