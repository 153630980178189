import {Prompt} from "react-router-dom";
import React, {Component} from "react";


interface Props {
  preventLeaving: boolean;
}

const LEAVING_MESSAGE = 'Вы уверены, что хотите закрыть страницу? На странице есть несохранённые данные, которые будут утеряны.';

export class PreventLeavingGuard extends Component<Props> {
  leavingListener = (event: BeforeUnloadEvent) => {
    if (this.props.preventLeaving) {
      event.returnValue = LEAVING_MESSAGE;
      return LEAVING_MESSAGE;
    }

    return;
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.leavingListener);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.leavingListener);
  }

  render() {
    return <Prompt when={this.props.preventLeaving} message={LEAVING_MESSAGE} />;
  }
}