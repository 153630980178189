import React, {Component} from "react";

import {IonButton, IonItem, IonList} from "@ionic/react";
import {observable} from "mobx";
import {observer} from "mobx-react";

type Props = { logs: LogItem[] }
type LogItem = {
  id: string,
  description: string
}

@observer
export class PrinteraLogList extends Component<Props> {

  @observable collapseLogs = true;

  render() {
    const {logs} = this.props;
    const hasLogs = logs && logs.length > 0;

    return (
      !hasLogs
        ? <>
          <IonButton fill='clear' size='default' expand='full'>В логе нет записей</IonButton>
        </>

        : <>
          <IonButton fill='clear' size='default' expand='full' onClick={() => this.collapseLogs = !this.collapseLogs}>
            {this.collapseLogs ? 'посмотреть' : 'скрыть'} лог
          </IonButton>

          {
            !this.collapseLogs
              ? <IonList>
                {logs!.map((log) => {
                  return <IonItem key={log.id}> {log.description} </IonItem>
                })}
              </IonList>
              : null
          }
        </>
    );
  }
}

