import {Role} from "./cuba/enums";

export const SETTINGS = '/settings';
export const DEVICES = '/devices';
export const DEVICE_EDIT = '/device/:deviceId';
export const CLIENT_DEVICE_EDIT = '/client/device/:deviceId';

export let addDeviceNumber: string | undefined; // todo ideally rework with query params

export function pathToDevice (deviceId: string, role: Role) {
  addDeviceNumber = undefined;
  return role === Role.FULL ? '/device/' + deviceId : '/client/device/' + deviceId;
}

export function pathToDeviceAddNumber (deviceId: string, deviceNumber: string) {
  addDeviceNumber = deviceNumber;
  return '/device/' + deviceId;
}

export const TASKS = '/tasks';
export const SUB_TASKS = '/sub-tasks/:parentTaskId';
export const TASK_EDIT = '/task/:taskId';
export const PARENT_TASK_EDIT = '/parent-task/:taskId';
export const CLIENT_TASKS = '/client/tasks';

export function pathToTask(taskId: string) {
  return '/task/' + taskId;
}

export function pathToParentTask(taskId: string) {
  return '/parent-task/' + taskId;
}

export function pathToSubTasks(parentTaskId: string) {
  return '/sub-tasks/' + parentTaskId;
}

export const DEVICE_DETECTION = '/device-detection';
