import React, {Component} from 'react';
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonProgressBar
} from "@ionic/react";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import {pathToDevice} from "../../../routes";
import {MainStore, MainStoreInjected} from "../../../mainStore";
import {action, observable} from "mobx";
import {DeviceListFilter} from "./DeviceListFilter";
import {deviceCaption} from "../devicesCommon";
import {DeviceListStore, DeviceListStoreInjected} from "./deviceListStore";
import {DeviceListFilterData} from "./DeviceListFilterData";

type Props = RouteComponentProps<void> & Pick<MainStore, 'role'>
  & Pick<DeviceListStore, | 'filterData' | 'applyFilter' | 'deviceList' | 'loading' | 'hasMore' | 'loadMore'>

@inject((stores: MainStoreInjected & DeviceListStoreInjected) => ({
  role: stores.mainStore.role,
  filterData: stores.deviceListStore.filterData,
  applyFilter: stores.deviceListStore.applyFilter,
  deviceList: stores.deviceListStore.deviceList,
  loading: stores.deviceListStore.loading,
  hasMore: stores.deviceListStore.hasMore,
  loadMore: stores.deviceListStore.loadMore,
}))
@observer
export class DeviceList extends Component<Props> {

  @observable expandFilter = false;

  render() {

    const {deviceList, loading, hasMore, loadMore} = this.props;

    return (
      <>
        <DeviceListFilter isExpanded={this.expandFilter}
                          expandFilter={() => this.expandFilter = true}
                          applyFilter={this.applyFilter}/>

        {loading &&
        <IonProgressBar type='indeterminate'/>
        }
        <IonContent>
          <IonList>
            {deviceList && deviceList.map((deviceAssignment) => (
              <Link to={pathToDevice(deviceAssignment.device!.id, this.props.role)} key={deviceAssignment.id}>
                <IonItem button={true}
                         style={{padding: '5px 0 5px 0'}}
                         detail={true}>
                  {deviceCaption(deviceAssignment, this.props.role)}
                </IonItem>
              </Link>
            ))}

            {hasMore && !loading &&
            <IonButton fill='clear' size='default' expand='full' onClick={() => loadMore()}>
              загрузить ещё
            </IonButton>}

          </IonList>
        </IonContent>
      </>
    );
  }

  @action
  private applyFilter = (fd: DeviceListFilterData) => {
    this.expandFilter = false;
    this.props.applyFilter(fd);
  };

}

