import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import {
  IonAvatar,
  IonButton,
  IonContent, IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonSpinner
} from "@ionic/react";

import avatar from './avatar.svg';
import {inject, observer} from "mobx-react";
import {MainStore, MainStoreInjected} from "../../mainStore";
import {UserInfo} from "@cuba-platform/rest";
import {SelectChangeEventDetail} from "@ionic/core";
import {Role} from "../../cuba/enums";
import {logOut} from 'ionicons/icons';


type Props = MainStoreInjected & {
  onLogout: () => void
}

const setDefaultClient = (mainStore: MainStore) => (event: CustomEvent<SelectChangeEventDetail>) => {
  if (!mainStore || !mainStore.userInfo || !mainStore.userInfo.clients) {
    return;
  }
  const client = mainStore.userInfo.clients.find((e) => e.id === event.detail.value);
  mainStore.resetOffice();
  mainStore.setDefaultClient(client);
};

const setDefaultOffice = (mainStore: MainStore) => (event: CustomEvent<SelectChangeEventDetail>) => {
  if (!mainStore || !mainStore.offices.entities) {
    return;
  }
  const office = mainStore.offices.entities.find((e) => e.id === event.detail.value);
  mainStore.setDefaultOffice(office);
};

const resetDefaults = (mainStore: MainStore) => () => {
  mainStore.resetOffice();
  mainStore.resetClient();
}

export const Settings: FunctionComponent<Props> = inject(MainStore.NAME)(observer(({mainStore}: Props) => {

  const {userInfo, client, offices, office, role} = mainStore;

  return (
    <IonContent>
      <IonList>
        <IonItem>
          <IonAvatar slot="start">
            <img src={avatar} alt='avatar'/>
          </IonAvatar>
          <IonLabel>
            {renderUserName(mainStore!.userInfo)}
          </IonLabel>
          <IonButton color="light"
                     slot="end"
                     onClick={mainStore.logout}>
            Выйти
            <IonIcon icon={logOut} slot='end'/>
          </IonButton>
        </IonItem>

        {role === Role.FULL &&
        <IonItem>
          <IonLabel>Клиент</IonLabel>
          <IonSelect onIonChange={setDefaultClient(mainStore)}
                     value={client ? client.id : 'undefined'}>
            {userInfo!.clients && userInfo!.clients.map((client) =>
              <IonSelectOption key={client.id} value={client.id}>
                {client._instanceName}
              </IonSelectOption>
            )}
          </IonSelect>
        </IonItem>}

        <IonItem>
          <IonLabel>Офис</IonLabel>
          <IonSelect onIonChange={setDefaultOffice(mainStore)}
                     value={office ? office.id : 'undefined'}>
            {offices.entities && offices.entities.map((office) =>
              <IonSelectOption key={office.id} value={office.id}>
                {office._instanceName}
              </IonSelectOption>
            )}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonButton onClick={resetDefaults(mainStore)}>Сбросить</IonButton>
        </IonItem>
      </IonList>
    </IonContent>
  )
}));

function renderUserName(userInfo?: UserInfo): ReactNode {
  if (!userInfo) {
    return <IonSpinner/>
  }
  if (userInfo.firstName || userInfo.lastName) {
    return `${userInfo.firstName} ${userInfo.lastName ? userInfo.lastName : ''}`;
  }
  return userInfo.login;
}
