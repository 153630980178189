import React, {Component} from "react";
import Upload from 'rc-upload';
import {IonAlert, IonButton, IonIcon, IonItem, IonLabel, IonSpinner} from "@ionic/react";
import {cubaREST} from "../..";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {camera, close as iconClose} from 'ionicons/icons';


export interface FileDescriptorInfo {
  id: string;
  name: string;
  size: number;
}

type Props = {
  fileDescriptorInfo?: FileDescriptorInfo;
  onFileUpload: (fdi: FileDescriptorInfo) => void;
  onFileRemove: () => void
}

@observer
export class FileUploadItem extends Component<Props> {

  @observable uploading = false;
  @observable uploadError = false;

  render() {

    const {fileDescriptorInfo} = this.props;

    if (fileDescriptorInfo) {
      return (
        <IonItem>
          <IonLabel>{fileDescriptorInfo.name}</IonLabel>
          <IonButton size='small'
                     slot='end'
                     fill='clear'
                     onClick={() => {this.props.onFileRemove()}}>
            <IonIcon icon={iconClose} slot='icon-only'/>
          </IonButton>
        </IonItem>
      )
    }

    return (
      <Upload action={cubaREST.apiUrl + 'v2/files'}
              multiple={false}
              headers={{
                Authorization: "Bearer " + cubaREST.restApiToken
              }}
              onStart={this.onUploadStart}
              onError={this.onUploadError}
              onSuccess={this.onUploadSuccess}>
        <IonItem>
          {this.uploading &&
            <IonSpinner/>
          }
          {!this.uploading &&
          <IonButton slot="end">
            Загрузить фаил
            <IonIcon icon={camera} slot='end'/>
          </IonButton>}
        </IonItem>
        <IonAlert
          isOpen={this.uploadError}
          onDidDismiss={() => this.uploadError = false}
          buttons={['OK']}
          message='Ошибка при загрузке фаила. Максимальный размер фаила 20Мб.'/>
      </Upload>
    )
  }

  @action
  onUploadStart = () => {
    this.uploading = true;
  };

  @action
  onUploadError = () => {
    this.uploading = false;
    this.uploadError = true;
  };

  @action
  onUploadSuccess = (file: FileDescriptorInfo) => {
    this.uploading = false;
    this.props.onFileUpload(file);
  };
}
