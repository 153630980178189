import {toJS} from "mobx";
import {Condition, ConditionsGroup, SerializedEntity} from "@cuba-platform/rest";
import {includesIgnoreCase, isEmpty} from "../../../util/stringUtils";
import {PrinteraTask} from "../../../cuba/entities/printers_PrinteraTask";
import {PrinteraTaskType} from "../../../cuba/entities/printers_PrinteraTaskType";
import {getEnumCaption, TaskState, TaskStateCaption} from "../../../cuba/enums";
import {taskPriorityCaption} from "../tasksCommon";
import {PrinteraTaskPriority} from "../../../cuba/enums/enums";
import {contains, equal} from "../../../util/restUtils";

export class TaskListFilter {
  searchString = '';
  deviceNumber = '';
  client = {id: '', name: ''};
  office = {id: '', name: ''};
  floor = '';
  cabinet = '';
  taskNumber = '';
  taskType?: PrinteraTaskType;
  priority?: PrinteraTaskPriority;
  myTasksOnly?: boolean;
  userId?: string;
  deviceAssignmentId = '';
  taskState?: TaskState;

  constructor(userId?: string) {
    this.userId = userId;
  }

  get caption() {
    let caption = [];
    if (!isEmpty(this.taskNumber)) caption.push('номер задачи ' + this.taskNumber);
    if (this.taskType != null) caption.push('вид задачи ' + this.taskType.name);
    if (this.priority != null) caption.push('приоритет ' + taskPriorityCaption(this.priority));
    if (this.myTasksOnly === true) caption.push('только мои задачи');
    if (!isEmpty(this.deviceNumber)) caption.push('Инв. номер ' + this.deviceNumber);
    if (!isEmpty(this.client.id)) caption.push('клиент ' + this.client.name);
    if (!isEmpty(this.office.id)) caption.push('офис ' + this.office.name);
    if (!isEmpty(this.floor)) caption.push('этаж ' + this.floor);
    if (!isEmpty(this.cabinet)) caption.push('кабинет ' + this.cabinet);
    if (!isEmpty(this.taskState)) caption.push('состояние задачи ' + getEnumCaption(this.taskState, TaskStateCaption));
    return caption.join(', ');
  }

  get isFilterSet() {
    return !isEmpty(this.caption) || !isEmpty(this.deviceAssignmentId);
  }

  get conditionsREST(): Array<ConditionsGroup | Condition> {
    //todo: to complete
    const conditions = [];

    if (isEmpty(this.searchString) && !this.isFilterSet) {
      return [];
    }

    if (!isEmpty(this.searchString)) {
      conditions.push(contains('header', this.searchString));
    }

    if (this.taskState) {
      conditions.push(equal('completed', `${this.taskState !== TaskState.ACTIVE}`));
    }

    if (!isEmpty(this.deviceAssignmentId)) {
      conditions.push(equal('deviceAssignment.id', this.deviceAssignmentId));
    }

    if (!isEmpty(this.deviceNumber)) {
      const condition: ConditionsGroup = {
        group: 'OR',
        conditions: [
          contains('deviceAssignment.device.deviceNumber', this.deviceNumber),
          contains('deviceAssignment.device.serialNumber', this.deviceNumber),
        ]
      };
      conditions.push(condition);
    }

    if (!isEmpty(this.client?.id)) {
      conditions.push(equal('client.id', this.client.id));
    }

    if (!isEmpty(this.office?.id)) {
      conditions.push(equal('office.id', this.office.id));
    }

    if (!isEmpty(this.floor)) {
      conditions.push(equal('deviceAssignment.location.locationFloor', this.floor));
    }

    if (!isEmpty(this.cabinet)) {
      conditions.push(equal('deviceAssignment.location.name', this.cabinet));
    }

    if (!isEmpty(this.taskNumber)) {
      conditions.push(equal('number', this.taskNumber));
    }

    if (this.taskType) {
      conditions.push(equal('printeraTaskType.id', this.taskType.id));
    }

    if (this.priority) {
      conditions.push(equal('priority', this.priority));
    }

    /*
    this.searchString
    this.myTasksOnly
*/
    return conditions;
  }
}


export function filterTasks(tasks: SerializedEntity<PrinteraTask>[] | undefined, filter: TaskListFilter) {

  if (!tasks) {
    return [];
  }

  const {searchString} = filter;

  if (isEmpty(searchString) && !filter.isFilterSet) {
    return toJS(tasks);
  }

  return toJS(tasks).filter((task: SerializedEntity<PrinteraTask>) => {

      //search string
      if (!isEmpty(searchString)) {
        if (!task._instanceName) return false;

        if (!includesIgnoreCase(task._instanceName, searchString)) {
          return false;
        }
      }

      //filter fields
      const {
        taskNumber, deviceNumber, floor, cabinet, deviceAssignmentId, taskType, priority,
        taskState, client, office, userId, myTasksOnly
      } = filter;

      if (!isEmpty(deviceAssignmentId) && task.deviceAssignment
        && deviceAssignmentId !== task.deviceAssignment.id) {
        return false;
      }

      if (!isEmpty(taskNumber) && !includesIgnoreCase(task.number, taskNumber)) {
        return false;
      }

      if (!isEmpty(deviceNumber)) {
        if (task.deviceAssignment == null
          || task.deviceAssignment.device == null
          || (
            !includesIgnoreCase(task.deviceAssignment.device.deviceNumber, deviceNumber)
            && !includesIgnoreCase(task.deviceAssignment.device.serialNumber, deviceNumber)
          )) {
          return false
        }
      }

      if (!isEmpty(floor)) {
        if (task.deviceAssignment == null
          || task.deviceAssignment.device == null
          || task.deviceAssignment.location == null
          || !includesIgnoreCase(task.deviceAssignment.location.locationFloor, floor)) {
          return false;
        }
      }

      if (!isEmpty(cabinet)) {
        if (task.deviceAssignment == null
          || task.deviceAssignment.device == null
          || task.deviceAssignment.location == null
          || !includesIgnoreCase(task.deviceAssignment.location.name, cabinet)) {
          return false;
        }
      }

      if (taskType != null) {
        if (task.printeraTaskType == null || task.printeraTaskType.id !== taskType.id) {
          return false;
        }
      }

      if (priority != null) {
        if (task.priority !== priority) {
          return false;
        }
      }

      if (taskState != null) {
        if (taskState === TaskState.ACTIVE && task.completed) return false;
        if (taskState === TaskState.COMPLETED && !task.completed) return false;
      }

      if (!isEmpty(office.id) && (!task.office || task.office.id !== office.id)) {
        return false;
      }

      if (!isEmpty(client.id) && (!task.client || task.client.id !== client.id)) {
        return false;
      }

      if (myTasksOnly === true) {
       if (!task.initiator || isEmpty(userId) || task.initiator.id !== userId)  return false;
      }

      return true;
    }
  )
}
