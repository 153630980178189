import {SerializedEntity} from "@cuba-platform/rest";
import {DeviceAssignment} from "../../cuba/entities/printers_DeviceAssignment";
import {Device} from "../../cuba/entities/printers_Device";
import {getEnumCaption, IdentificationStatusCaption, PollStatusCaption, Role} from "../../cuba/enums";
import {DeviceModel} from "../../cuba/entities/printers_DeviceModel";

export function deviceCaption(da: SerializedEntity<DeviceAssignment>, role: Role, showClient = false): string {
  const {_instanceName, location, device, client, office, pollStatus, identificationStatus} = da;

  let caption = device ? (device as SerializedEntity<Device>)._instanceName : _instanceName;

  if (showClient) {
    if (client && client.clientName) caption += ', ' + client.clientName;
    if (office && office.officeName) caption += ', ' + office.officeName;
  }

  if (location && location.locationFloor) caption += ', ' + location.locationFloor + ' этаж';

  if (location && location.name) caption += ', ' + location.name;

  if (pollStatus && role !== Role.CLIENT) caption += ', опрос: ' + getEnumCaption(pollStatus, PollStatusCaption);

  if (identificationStatus && role !== Role.CLIENT) caption += ', статус: ' + getEnumCaption(identificationStatus, IdentificationStatusCaption);

  return caption;
}

export function getModelInstanceName(model: DeviceModel | null | undefined) {
  if (!model) return '';

  const {manufacturer, deviceModelName} = model;

  let name = manufacturer ? manufacturer.shortName + ' ' : '';
  name += deviceModelName ? deviceModelName : '';
  return name;
}

export function deviceSortingComparator(a: SerializedEntity<DeviceAssignment>, b: SerializedEntity<DeviceAssignment>): number {

  if (!a.location && !b.location) return 0;
  if (!a.location) return 1;
  if (!b.location) return -1;

  if (a.location.locationFloor !== b.location.locationFloor) {
    if (a.location.locationFloor == null) return 1;
    if (b.location.locationFloor == null) return -1;

    return a.location.locationFloor > b.location.locationFloor ? 1 : -1;
  }

  if (!a.location.name) return 1;
  if (!b.location.name) return -1;
  return a.location.name.localeCompare(b.location.name);

}
