import {observer} from "mobx-react";
import {Component} from "react";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon, IonList, IonListHeader, IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import {close as closeIcon} from 'ionicons/icons';


type Props = {
  header: string
  caption: string
  show: boolean
  saving: boolean
  errorMsg: string | undefined
  disabled?: boolean
  resetError: () => void
  onClose: () => void
  onSave: () => void
}

@observer
export class PrinteraCreateModal extends Component<Props> {

  render() {

    const {header, caption, saving, onSave, onClose, errorMsg, resetError, show} = this.props;

    return (
      <IonModal
        isOpen={show}
        backdropDismiss={false}
        onDidDismiss={() => {
        }}>

        <IonHeader>
          <IonToolbar>

            <IonTitle>{header}</IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={onClose} fill='clear'>
                <IonIcon icon={closeIcon} slot='icon-only'/>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonListHeader>
            {caption}
          </IonListHeader>

          <IonList>
            {this.props.children}
          </IonList>
        </IonContent>

        <IonButton onClick={onSave} disabled={this.props.disabled}>
          Сохранить
        </IonButton>

        <IonAlert
          isOpen={saving}
          onDidDismiss={() => {
          }}
          message='Сохраняем'/>

        <IonAlert
          isOpen={errorMsg != null}
          onDidDismiss={resetError}
          buttons={['OK']}
          message={errorMsg}/>

      </IonModal>
    );
  }

}
