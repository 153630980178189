export enum CountersValuesType {
  ADJUSTMENT = "ADJUSTMENT",
  ACCOUNT = "ACCOUNT"
}

export enum DeadlineType {
  CURRENT_MONTH = "CURRENT_MONTH",
  PREVIOUS_MONTH = "PREVIOUS_MONTH"
}

export enum InvoiceState {
  NEW = "NEW",
  CALCULATED = "CALCULATED",
  EDITED = "EDITED"
}

export enum InvoiceStatus {
  NEW = "NEW",
  INVOICED = "INVOICED",
  PAID = "PAID",
  WRITTEN_OFF = "WRITTEN_OFF"
}

export enum Month {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December"
}

export enum ClientType {
  PARTNER = "PARTNER",
  CLIENT = "CLIENT"
}

export enum ClientStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum ColorMode {
  BLACK = "BLACK",
  COLOR = "COLOR"
}

export enum ConsumablesStockControlType {
  WITHOUT_CONTROL = "WITHOUT_CONTROL",
  GENERAL_BY_DEVICE = "GENERAL_BY_DEVICE"
}

export enum ConnectionType {
  LAN = "LAN",
  USB = "USB",
  LAN_THROUGH_USB = "LAN_THROUGH_USB",
  NOT_CONNECTED = "NOT_CONNECTED"
}

export enum IdentificationStatus {
  INACTIVE_ASSIGNMENT = "INACTIVE_ASSIGNMENT",
  NO_MODEL = "NO_MODEL",
  NO_CLIENT = "NO_CLIENT",
  NO_OFFICE = "NO_OFFICE",
  NO_INITIAL_VALUES_SET = "NO_INITIAL_VALUES_SET",
  NO_INITIAL_VALUES_FOR_PRINTED_BW = "NO_INITIAL_VALUES_FOR_PRINTED_BW",
  NO_INITIAL_VALUES_FOR_PRINTED_COLOR = "NO_INITIAL_VALUES_FOR_PRINTED_COLOR",
  NO_INITIAL_VALUES_FOR_SCANNED = "NO_INITIAL_VALUES_FOR_SCANNED",
  NO_POLLER = "NO_POLLER",
  NO_IP = "NO_IP",
  TOTAL_PRINTED_BW_NOT_SET = "TOTAL_PRINTED_BW_NOT_SET",
  TOTAL_PRINTED_COLOR_NOT_SET = "TOTAL_PRINTED_COLOR_NOT_SET",
  TOTAL_SCANNED_NOT_SET = "TOTAL_SCANNED_NOT_SET",
  NO_LOCATION = "NO_LOCATION",
  LAN_WITH_MANUAL_INPUT = "LAN_WITH_MANUAL_INPUT",
  NOT_CONNECTED = "NOT_CONNECTED",
  SNMP_MODEL_NAME_NOT_SET = "SNMP_MODEL_NAME_NOT_SET",
  SNMP_VERSION_NOT_SET = "SNMP_VERSION_NOT_SET",
  OK = "OK"
}

export enum PollStatus {
  LESS_ONE_DAY = "LESS_ONE_DAY",
  LESS_THREE_DAYS = "LESS_THREE_DAYS",
  LESS_TWO_WEEKS = "LESS_TWO_WEEKS",
  LESS_ONE_MONTH = "LESS_ONE_MONTH",
  MORE_ONE_MONTH = "MORE_ONE_MONTH",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  MANUAL = "MANUAL"
}

export enum CreationMethod {
  POLLER = "POLLER",
  MANUAL = "MANUAL",
  SYSTEM = "SYSTEM",
  IMPORT = "IMPORT"
}

export enum DeviceDataStatus {
  VALID = "VALID",
  INVALID = "INVALID"
}

export enum DeviceCategory {
  PRINTER = "PRINTER",
  SCANNER = "SCANNER",
  MFP = "MFP",
  RISOGRAPH = "RISOGRAPH",
  PLOTTER = "PLOTTER",
  OTHER = "OTHER"
}

export enum DeviceLogEntryType {
  IP_CHANGE = "IP_CHANGE",
  LOCATION_CHANGE = "LOCATION_CHANGE",
  CONNECTION_TYPE_CHANGE = "CONNECTION_TYPE_CHANGE",
  RESET_COUNTERS = "RESET_COUNTERS",
  ENTER_INITIAL_VALUES = "ENTER_INITIAL_VALUES",
  ASSIGNMENT_CHANGE = "ASSIGNMENT_CHANGE",
  OWNER_CHANGE = "OWNER_CHANGE",
  SERIAL_NUMBER_CHANGE = "SERIAL_NUMBER_CHANGE",
  DECOMMISSIONING = "DECOMMISSIONING",
  USER_COMMENT = "USER_COMMENT",
  MANUAL_DATA_INPUT = "MANUAL_DATA_INPUT",
  STATE_CHANGE = "STATE_CHANGE",
  OFFICE_CHANGE = "OFFICE_CHANGE",
  RESPONSIBLE_PERSON_CHANGE = "RESPONSIBLE_PERSON_CHANGE",
  DEPARTMENT_CHANGE = "DEPARTMENT_CHANGE",
  DEVICE_NUMBER = "DEVICE_NUMBER",
  INCIDENT_CREATED = "INCIDENT_CREATED",
  TECHNICIAN_COUNTERS_RESET = "TECHNICIAN_COUNTERS_RESET",
  RECALCULATE_VALUES = "RECALCULATE_VALUES",
  CONSUMABLE_CHANGE = "CONSUMABLE_CHANGE",
  FIXING_COUNTERS = "FIXING_COUNTERS",
  REDIRECTION_CHANGE = "REDIRECTION_CHANGE"
}

export enum DeviceModelStatus {
  EMPTY = "EMPTY",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  APPROVED = "APPROVED"
}

export enum DeviceStatus {
  CONFIGURED = "CONFIGURED",
  IGNORED = "IGNORED",
  ANOTHER_POLLER = "ANOTHER_POLLER",
  MANUAL_INPUT = "MANUAL_INPUT",
  NEW = "NEW"
}

export enum DiscoveryScanStatus {
  SCAN_IN_PROGRESS = "SCAN_IN_PROGRESS",
  SCAN_NOT_ALLOWED = "SCAN_NOT_ALLOWED",
  SCAN_START_ERROR = "SCAN_START_ERROR",
  SCAN_ERROR = "SCAN_ERROR",
  SCAN_FINISHED = "SCAN_FINISHED"
}

export enum MonitoringVisualizationType {
  CURRENT_COUNTERS = "CURRENT_COUNTERS",
  DATE_COUNTERS = "DATE_COUNTERS",
  PERIOD_COUNTERS = "PERIOD_COUNTERS",
  CURRENT_MONTH = "CURRENT_MONTH",
  CURRENT_YEAR = "CURRENT_YEAR",
  PREVIOUS_MONTH = "PREVIOUS_MONTH",
  PREVIOUS_YEAR = "PREVIOUS_YEAR"
}

export enum PaperSize {
  A4 = "A4",
  A3 = "A3",
  A2 = "A2",
  A1 = "A1",
  A0 = "A0",
  A5 = "A5",
  A6 = "A6",
  Other = "Other"
}

export enum ParameterType {
  STRING = "STRING",
  NUMERIC = "NUMERIC",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  SCALE = "SCALE"
}

export enum PollerDeviceStatus {
  NEW = "NEW",
  ANOTHER_POLLER = "ANOTHER_POLLER",
  MANUAL_INPUT = "MANUAL_INPUT",
  CONFIGURED = "CONFIGURED"
}

export enum PollerStatus {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE"
}

export enum PrintMethod {
  LASER = "LASER",
  INK = "INK",
  OFFSET = "OFFSET",
  SCREEN_PRINT = "SCREEN_PRINT"
}

export enum ScanResultType {
  REGULAR_SCAN = "REGULAR_SCAN",
  DISCOVERY_SCAN = "DISCOVERY_SCAN",
  MANUAL_SCAN = "MANUAL_SCAN"
}

export enum ScanTargetType {
  REGULAR_TARGET = "REGULAR_TARGET",
  MIB_REQUEST = "MIB_REQUEST"
}

export enum RequestType {
  API_VERSION_REQUEST = "API_VERSION_REQUEST",
  BALANCE_REQUEST = "BALANCE_REQUEST",
  SENDER_REQUEST = "SENDER_REQUEST",
  SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST",
  CHECK_MESSAGE_STATUS_REQUEST = "CHECK_MESSAGE_STATUS_REQUEST",
  UNDEFINED_REQUEST = "UNDEFINED_REQUEST",
  WRONG_PHONE_NUMBER = "WRONG_PHONE_NUMBER"
}

export enum SnmpVersion {
  version1 = "version1",
  version2c = "version2c",
  version3 = "version3"
}

export enum StandardParameterType {
  CALCULATE = "CALCULATE",
  DOWNLOADABLE = "DOWNLOADABLE"
}

export enum StartType {
  MANUAL = "MANUAL",
  AUTO_SCHEDULED = "AUTO_SCHEDULED",
  SCHEDULED_TASK = "SCHEDULED_TASK"
}

export enum SupportedInterfaces {
  USB = "USB",
  LAN = "LAN"
}

export enum NotificationType {
  SMS = "SMS",
  EMAIL = "EMAIL",
  SMS_AND_EMAIL = "SMS_AND_EMAIL"
}

export enum ServerErrorType {
  Core = "Core",
  Parse = "Parse",
  Scan = "Scan",
  Configuration = "Configuration",
  Utils = "Utils",
  Poller = "Poller",
  Rest = "Rest"
}

export enum TypeOfRestriction {
  CLIENTS = "CLIENTS",
  OFFICES = "OFFICES"
}

export enum MovementType {
  ADDITION = "ADDITION",
  DECOMMISSION = "DECOMMISSION",
  MOVEMENT = "MOVEMENT",
  CORRECTION_ADDITION = "CORRECTION_ADDITION",
  CORRECTION_DECOMMISSION = "CORRECTION_DECOMMISSION"
}

export enum ReplenishmentPositionType {
  MAIN = "MAIN",
  ANALOG = "ANALOG"
}

export enum WarehouseType {
  PARTNER = "PARTNER",
  CLIENT = "CLIENT"
}

export enum CreationSource {
  SERVER = "SERVER",
  MOBILE = "MOBILE"
}

export enum CreationType {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC"
}

export enum PrinteraTaskItemLinkType {}

export enum PrinteraTaskLogItemType {
  SYSTEM = "SYSTEM",
  COMMENT = "COMMENT",
  ASSIGNMENT = "ASSIGNMENT",
  ACTION = "ACTION"
}

export enum PrinteraTaskPriority {
  LOW = "LOW",
  NORMAL = "NORMAL",
  HIGH = "HIGH"
}

export enum PrinteraTaskStatus {
  NEW = "NEW",
  ASSIGNED_TO_USER = "ASSIGNED_TO_USER",
  ASSIGNED_TO_GROUP = "ASSIGNED_TO_GROUP",
  ASSIGNED_OPERATOR = "ASSIGNED_OPERATOR",
  ASSIGNED_MANAGER = "ASSIGNED_MANAGER",
  ASSIGNED_TECHNICIAN = "ASSIGNED_TECHNICIAN",
  ASSIGNED_DRIVER = "ASSIGNED_DRIVER",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  CANCELED = "CANCELED",
  VERIFICATION = "VERIFICATION"
}

export enum TaskOutcome {}
