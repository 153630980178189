import { StandardEntity } from "./base/sys$StandardEntity";
export class DeviceState extends StandardEntity {
  static NAME = "printers_DeviceState";
  state?: string | null;
  decommissioned?: boolean | null;
}
export type DeviceStateViewName = "_minimal" | "_local" | "_base";
export type DeviceStateView<
  V extends DeviceStateViewName
> = V extends "_minimal"
  ? Pick<DeviceState, "id" | "state">
  : V extends "_local"
  ? Pick<DeviceState, "id" | "state" | "decommissioned">
  : V extends "_base"
  ? Pick<DeviceState, "id" | "state" | "decommissioned">
  : never;
