import {Component} from "react";

import {
  IonButton,
  IonButtons, IonHeader,
  IonIcon,
  IonItem,
  IonList, IonProgressBar,
  IonSearchbar,
  IonToolbar
} from "@ionic/react";
import React from "react";
import {action, observable, toJS} from "mobx";
import {isEmpty} from "../../util/stringUtils";
import {observer} from "mobx-react";
import {options} from 'ionicons/icons';


type ListFilter = {
  searchString: string,
  searchPlaceholder?: string;
  caption: string
}

type Props<T extends ListFilter> = {
  loading?: boolean
  filter: T
  filterChange: (reset?: boolean) => void
}

@observer
export class PrinteraListFilter<T extends ListFilter> extends Component<Props<T>> {

  @observable expandFilter = false;

  render() {

    const {filter, loading} = this.props;
    let {handleSearchChange, expandFilter} = this;
    const {searchPlaceholder = 'Поиск по заголовку'} = filter;

    return <>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar placeholder={searchPlaceholder} value={toJS(filter.searchString)}
                        onIonChange={handleSearchChange}/>
          <IonButtons slot='end'>

            {!this.expandFilter &&
            <IonButton slot='icon-only' onClick={this.openFilter}>
              <IonIcon icon={options}/>
            </IonButton>}

          </IonButtons>
        </IonToolbar>

        {expandFilter &&
        <IonToolbar>
          <IonList>
            {this.props.children}

            <IonItem>
              <IonButtons style={{width: '100%'}}>
                <IonButton onClick={this.applyFilter}>
                  применить фильтр
                </IonButton>
                <IonButton style={{width: '100%'}} onClick={this.resetFilter}>
                  сбросить
                </IonButton>
              </IonButtons>
            </IonItem>

          </IonList>
        </IonToolbar>
        }


        {!expandFilter && !isEmpty(filter.caption) &&
        <IonToolbar>
          <IonButtons>
            <IonButton style={{textAlign: 'left', height: 'auto', padding: '10px 0 10px 0'}}
                       text-wrap fill='clear' expand='full' onClick={this.openFilter}>
              Фильтр: {filter.caption}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        }

      </IonHeader>

      {loading === true &&
      <IonProgressBar type='indeterminate'/>
      }
    </>;
  }

  @action
  private handleSearchChange = (e: CustomEvent) => {
    this.props.filter.searchString = e.detail.value;
    this.props.filterChange();
  };

  @action
  private resetFilter = () => {
    this.expandFilter = false;
    this.props.filterChange(true);
  };

  @action
  private applyFilter = () => {
    this.expandFilter = false;
    this.props.filterChange();
  };

  @action
  private openFilter = () => {
    this.expandFilter = true;
  }

}
