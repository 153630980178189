import {Component} from "react";

import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React from "react";

type Props = { caption: string, value: string | null | undefined, readonly?: boolean, onValueChange?:(e: any) => void, placeholder?: string };

export class PrinteraEditItem extends Component<Props> {

  render() {
    const {caption, readonly, value, onValueChange, placeholder} = this.props;

    return (
      <IonItem>
        <IonLabel>
          { caption }:
        </IonLabel>
        <IonInput className="ion-input custom" readonly={readonly} value={value} onIonChange={onValueChange} placeholder={placeholder}>
        </IonInput>
      </IonItem>
    );
  }
}

