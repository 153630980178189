import { StandardEntity } from "./base/sys$StandardEntity";
import { Client } from "./printers_Client";
export class Department extends StandardEntity {
  static NAME = "printers_Department";
  name?: string | null;
  client?: Client | null;
}
export type DepartmentViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "department-view";
export type DepartmentView<V extends DepartmentViewName> = V extends "_minimal"
  ? Pick<Department, "id" | "name">
  : V extends "_local"
  ? Pick<Department, "id" | "name">
  : V extends "_base"
  ? Pick<Department, "id" | "name">
  : V extends "department-view"
  ? Pick<Department, "id" | "name" | "client">
  : never;
