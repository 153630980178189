import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {MainStoreInjected} from "../../../mainStore";
import {IonButton, IonContent, IonHeader} from "@ionic/react";
import {action, observable, toJS} from "mobx";
import {pathToTask} from "../../../routes";
import {Link} from "react-router-dom";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {TaskCard} from "./TaskCard";
import {PrinteraSelect} from "../../common/PrinteraSelect";
import {getEnumEntries, PrinteraTaskPriorityCaption, TaskState, TaskStateCaption} from "../../../cuba/enums";
import {PrinteraListFilter} from "../../common/PrinteraListFilter";
import {PrinteraEnumSelect} from "../../common/PrinteraEnumSelect";
import {EntityListData} from "../../../data/entityListData";
import {Office} from "../../../cuba/entities/printers_Office";
import {getNameById} from "../tasksCommon";
import {TaskListStoreInjected} from "./taskListStore";
import {PredefinedView} from "@cuba-platform/rest";


const mapStoresToProps = (stores: MainStoreInjected & TaskListStoreInjected) => ({
  role: stores.mainStore.role,
  taskTypesData: stores.mainStore.taskTypesData,
  clients: stores.mainStore.userInfo!.clients,
  getFilter: stores.taskListStore.getFilter,
  filterChange: stores.taskListStore.filterChange,
  filteredTasks: stores.taskListStore.filteredTasks,
  tasks: stores.taskListStore.tasks,
  loading: stores.taskListStore.loading,
  hasMore: stores.taskListStore.hasMore,
  loadMore: stores.taskListStore.loadMore
});

@inject(mapStoresToProps)
@observer
export class TaskList extends Component<ReturnType<typeof mapStoresToProps>> {

  @observable offices = new EntityListData<Office>(Office.NAME, PredefinedView.MINIMAL, '+officeName');

  render() {

    const {clients, tasks, filterChange, loading, hasMore, loadMore} = this.props;
    const {entities: taskTypes} = this.props.taskTypesData;
    const filter = this.props.getFilter();

    return (
      <>
        <IonHeader>
          <PrinteraListFilter filter={filter} filterChange={filterChange} loading={loading}>

            <PrinteraEditItem caption="Номер задачи" value={filter.taskNumber}
                              onValueChange={e => filter.taskNumber = e.detail.value}/>
            <PrinteraSelect caption="Вид задачи"
                            entities={taskTypes}
                            value={filter.taskType ? filter.taskType.id : null}
                            onValueChange={(e) => {
                              filter.taskType = taskTypes!.find(t => t.id === e.detail.value)
                            }}/>

            <PrinteraSelect caption='Состояние задачи' value={filter.taskState}
                            entities={getEnumEntries(TaskState, TaskStateCaption)}
                            onValueChange={e => filter.taskState = e.detail.value}/>

            <PrinteraEnumSelect caption='Приоритет' value={filter.priority}
                                captions={PrinteraTaskPriorityCaption}
                                onValueChange={e => filter.priority = e.detail.value}/>

            <PrinteraEditItem caption="Инв. номер" value={filter.deviceNumber}
                              onValueChange={e => filter.deviceNumber = e.detail.value}/>

            <PrinteraSelect caption={'Клиент'} value={filter.client.id} entities={clients}
                            onValueChange={this.setClient}/>

            <PrinteraSelect caption={'Офис'} value={filter.office.id} entities={this.offices.entities}
                            onValueChange={e => filter.office = {
                              id: e.detail.value,
                              name: getNameById(e.detail.value, this.offices.entities)
                            }}/>

            <PrinteraEditItem caption="Этаж" value={filter.floor}
                              onValueChange={e => filter.floor = e.detail.value}/>

            <PrinteraEditItem caption="Кабинет" value={filter.cabinet}
                              onValueChange={e => filter.cabinet = e.detail.value}/>

          </PrinteraListFilter>
        </IonHeader>
        <IonContent>



          {tasks.map((task) => (
            <Link to={pathToTask(task.id)} key={task.id}>
              <TaskCard task={task} role={this.props.role}/>
            </Link>
          ))}
          {hasMore && !loading &&
          <IonButton fill="clear" size="default" expand="full" onClick={() => loadMore()}>
            загрузить еще
          </IonButton>}
        </IonContent>
      </>
    );
  }

  @action
  private setClient = (e: any) => {
    const filter = this.props.getFilter();

    filter.client = {
      id: e.detail.value,
      name: getNameById(e.detail.value, this.props.clients)
    };

    filter.office = {id: '', name: ''};

    this.offices.filter = {
      conditions: [{
        property: 'client.id',
        operator: "=",
        value: toJS(filter.client.id)
      }]
    };
    this.offices.load();
  };

}
