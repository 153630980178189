import {
  getEnumCaption,
  IdentificationStatusCaption,
  PollStatusCaption
} from "../../../cuba/enums";
import {isEmpty} from "../../../util/stringUtils";
import {IdentificationStatus, PollStatus} from "../../../cuba/enums/enums";

export class DeviceListFilterData {

  searchString: string = '';

  number: string = '';
  locationName: string = '';
  locationFloor: string = '';

  pollStatus?: PollStatus;
  idnStatus?: IdentificationStatus;

  private connectionType: string = '';
  private connectionTypeCaption?: string = '';

  getConnectionType = () => {
    return this.connectionType
  };

  setConnectionType = (val: string, caption: string) => {
    this.connectionType = val;
    this.connectionTypeCaption = caption
  };

  get caption() {
    let caption = [];
    if (!isEmpty(this.number)) caption.push('номер ' + this.number);
    if (!isEmpty(this.locationName)) caption.push('кабинет ' + this.locationName);
    if (!isEmpty(this.locationFloor)) caption.push('этаж ' + this.locationFloor);
    if (!isEmpty(this.connectionTypeCaption)) caption.push('подключение ' + this.connectionTypeCaption);
    if (!isEmpty(this.pollStatus)) caption.push('опрос ' + getEnumCaption(this.pollStatus, PollStatusCaption));
    if (!isEmpty(this.idnStatus)) caption.push('статус ' + getEnumCaption(this.idnStatus, IdentificationStatusCaption));
    return caption.join(', ');
  }

  get isFilterSet() {
    return !isEmpty(this.caption);
  }

}
