import { StandardEntity } from "./base/sys$StandardEntity";
import { DeviceLogEntryType } from "../enums/enums";
import { User } from "./base/sec$User";
import { DeviceAssignment } from "./printers_DeviceAssignment";
export class DeviceLogEntry extends StandardEntity {
  static NAME = "printers_DeviceLogEntry";
  type?: DeviceLogEntryType | null;
  user?: User | null;
  date?: any | null;
  description?: string | null;
  deviceAssignment?: DeviceAssignment | null;
}
export type DeviceLogEntryViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "deviceLogEntry-view"
  | "deviceLogEntry-device-edit-view";
export type DeviceLogEntryView<
  V extends DeviceLogEntryViewName
> = V extends "_minimal"
  ? Pick<DeviceLogEntry, "id" | "date">
  : V extends "_local"
  ? Pick<DeviceLogEntry, "id" | "type" | "date" | "description">
  : V extends "_base"
  ? Pick<DeviceLogEntry, "id" | "date" | "type" | "description">
  : V extends "deviceLogEntry-view"
  ? Pick<
      DeviceLogEntry,
      "id" | "deviceAssignment" | "type" | "user" | "date" | "description"
    >
  : V extends "deviceLogEntry-device-edit-view"
  ? Pick<DeviceLogEntry, "id" | "type" | "user" | "date" | "description">
  : never;
