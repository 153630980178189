import { StandardEntity } from "./base/sys$StandardEntity";
import { Office } from "./printers_Office";
export class Location extends StandardEntity {
  static NAME = "printers_Location";
  name?: string | null;
  locationFloor?: number | null;
  office?: Office | null;
  highPriority?: boolean | null;
  locationWithOffice?: string | null;
}
export type LocationViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "location-browse"
  | "location-edit";
export type LocationView<V extends LocationViewName> = V extends "_minimal"
  ? Pick<Location, "id" | "name" | "locationFloor">
  : V extends "_local"
  ? Pick<Location, "id" | "name" | "locationFloor" | "highPriority">
  : V extends "_base"
  ? Pick<Location, "id" | "name" | "locationFloor" | "highPriority">
  : V extends "location-browse"
  ? Pick<
      Location,
      | "id"
      | "name"
      | "locationFloor"
      | "highPriority"
      | "office"
      | "locationWithOffice"
    >
  : V extends "location-edit"
  ? Pick<Location, "id" | "name" | "locationFloor" | "highPriority" | "office">
  : never;
