import React, {Component} from 'react';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import {IonApp} from "@ionic/react";
import {Route} from "react-router-dom";
import {Provider} from "mobx-react";
import {MainStore} from "../mainStore";
import {deviceEditStore, deviceListStore, mainStore, taskCreateStore, taskListStore} from "../index";
import {AppBootstrap} from "./AppBootstrap";
import {DeviceEditStore} from "./devices/edit/deviceEditStore";
import './App.css';
import {DeviceListStore} from "./devices/list/deviceListStore";
import {TaskListStore} from "./tasks/list/taskListStore";
import {TaskCreateStore} from './tasks/create/taskCreateStore';

class App extends Component {
  render() {
    return (
      <IonApp>
          <Provider {...{
            [MainStore.NAME]: mainStore,
            [DeviceEditStore.NAME]: deviceEditStore,
            [TaskListStore.NAME]: taskListStore,
            [TaskCreateStore.NAME]: taskCreateStore,
            [DeviceListStore.NAME]: deviceListStore}}>
            <Route path='/' component={AppBootstrap}/>
          </Provider>
      </IonApp>
    );
  }
}

export default App;
