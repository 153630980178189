import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {RouteComponentProps} from "react-router";
import {TaskEditStore} from "./taskEditStore";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar
} from "@ionic/react";
import {CenteredColumn} from "../../common/Centered";
import {SerializedEntity} from "@cuba-platform/rest";
import {DeviceAssignment} from "../../../cuba/entities/printers_DeviceAssignment";
import {Link} from "react-router-dom";
import {pathToDevice, pathToParentTask, pathToSubTasks, TASKS} from "../../../routes";
import {PrinteraUser} from "../../../cuba/entities/printers_PrinteraUser";
import {action, computed, observable} from "mobx";
import {taskPriorityCaption, taskStatusCaption} from "../tasksCommon";
import {formatServerDateTime} from "../../../util/stringUtils";
import {deviceCaption} from "../../devices/devicesCommon";
import {FileDescriptor} from "../../../cuba/entities/base/sys$FileDescriptor";
import {cubaREST} from "../../../index";
import {MainStoreInjected} from "../../../mainStore";
import {FILE_DOWNLOAD_URL} from "../../../config";
import {PrinteraLogList} from "../../common/PrinteraLogList";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {PrinteraTaskTypeCodes} from "../../../cuba/enums";
import {PrinteraCreateModal} from "../../common/PrinteraCreateModal";
import {PrinteraTaskLogItemType} from "../../../cuba/enums/enums";
import {PrinteraReadonlyDate} from "../../common/PrinteraReadonlyDate";
import {TaskCreateModal} from "../create/TaskCreateModal";
import {FileUploadItem} from '../../common/FileUploadItem';
import {arrowBack, text } from 'ionicons/icons';
import {TransitionButton} from "../../../cuba/other/printers_TransitionButton";
import {TaskDoTransitionModal} from "./TaskDoTransitionModal";

type Props = RouteComponentProps<{ taskId: string }>

const mapStoresToProps = (stores: MainStoreInjected) => {
  return {
    reloadTasks: stores.mainStore.reloadTasks,
    role: stores.mainStore.role,
    userId: stores.mainStore.userId
  }
};

@inject(mapStoresToProps)
@observer
export class TaskEdit extends Component<Props & ReturnType<typeof mapStoresToProps>> {

  @observable showTaskActionModal = false;

  @observable showCommentModal = false;

  @observable showTaskDoTransitionModal = false;
  @observable transitionType?: TransitionButton = undefined;
  @observable loading = false;
  @observable toastMessage?: string = undefined;
  @observable showCreateSubTaskModal = false;
  @observable errorMsg?: string;

  private store = new TaskEditStore();

  @computed private get logItems() {
    const {task} = this.store;
    return (task && task.logItems ? task.logItems : [])
      .filter(log => log.type !== PrinteraTaskLogItemType.SYSTEM)

      .map(log => {
        //show user for COMMENT only
        const user = log.user && (log.user as any)._instanceName && log.type === PrinteraTaskLogItemType.COMMENT
          ? ' ' + (log.user as any)._instanceName + ':' : '';

        return {
          id: log.id,
          description: formatServerDateTime(log.date) + user + ' ' + log.comment
        }
      })
  }

  @computed private get isSubTask() {
    return this.store.task && this.store.task.parentTask
  }

  componentDidMount(): void {
    this.store.loadTask(this.props.match.params.taskId);
  }

  @computed private get transitionsButtons() {
    const {buttons} = this.store;

    const stylesMap = {
      'friendly': 'success'
    };

    return buttons?.slice().sort((btnA, btnB) => btnA.position - btnB.position).map(btn => {
      const onClick = () => {
        this.doTransition(btn)
      }

      const key = btn.style as keyof typeof stylesMap;

      const style: string | undefined = stylesMap[key] ?? btn.style;

      return (<IonButton color={style} expand="block" onClick={onClick} key={btn.transitionDto.id} data-position={btn.position}>
        {btn.name}
      </IonButton>)
    });
  }

  @action
  doTransition(btn: TransitionButton) {
    this.transitionType = btn;
    this.showTaskDoTransitionModal = true;
  }

  render() {
    const {history, role} = this.props;
    const {task} = this.store;
    const {commentFiles} = this.store;

    if (!task || this.loading) {
      return (
        <CenteredColumn>
          <IonSpinner/>
        </CenteredColumn>
      )
    }

    return (
      <>
        <IonHeader>
          <IonToolbar>

            <IonButtons slot="start">
              <IonButton onClick={() => history.goBack()} fill='clear'>
                <IonIcon icon={arrowBack} slot='icon-only'/>
              </IonButton>
            </IonButtons>
            <IonTitle>Задача {task.number}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>

            {this.isSubTask &&
            <IonItem button={true} detail={true}
                     onClick={() => history.push(pathToParentTask(task.parentTask!.id))}>
              Родительская задача: {task.parentTask!.number}
            </IonItem>}

            <PrinteraEditItem caption='Заголовок' value={task.header} readonly={true}/>

            <PrinteraEditItem caption='Вид задачи' value={task.printeraTaskType ? task.printeraTaskType.name : ''}
                              readonly={true}/>

            <PrinteraEditItem caption='Приоритет' value={taskPriorityCaption(task.priority)} readonly={true}/>

            <PrinteraReadonlyDate caption='Завершить до' date={task.dueDate}/>

            {task.printeraTaskType && task.printeraTaskType.code === PrinteraTaskTypeCodes.Incident &&
            <PrinteraReadonlyDate caption='Взято в работу' date={task.takenToWork}/>}

            {task.printeraTaskType && task.printeraTaskType.code === PrinteraTaskTypeCodes.Incident &&
            <PrinteraReadonlyDate caption='Срок реакции SLA' date={task.dueDateSLA}/>}

            {task.deviceAssignment && task.deviceAssignment.device &&
            <IonItem>
              <IonTextarea readonly={true}
                           value={deviceCaption(task.deviceAssignment as SerializedEntity<DeviceAssignment>, role)}/>
              <Link to={pathToDevice(task.deviceAssignment.device!.id, role)} slot='end'>
                <IonButton>открыть</IonButton>
              </Link>
            </IonItem>
            }

            <PrinteraEditItem caption='Инициатор' readonly={true}
                              value={task.initiator ? (task.initiator as SerializedEntity<PrinteraUser>)._instanceName : ''}/>

            <PrinteraEditItem caption='Статус' value={taskStatusCaption(task.status)} readonly={true}/>

            <IonItem>
              <IonLabel style={{alignSelf: 'auto'}}>Описание:</IonLabel>
              <IonTextarea style={{marginTop: '4px'}}
                           readonly={true} value={task.description} rows={1}/>
            </IonItem>

            <PrinteraReadonlyDate caption='Дата создания' date={task.creationDate}/>

            {this.isSubTask &&
            <PrinteraEditItem caption='Назначена на' value={task.assignedToUser ? task.assignedToUser.name : ''} readonly={true}/>}

            <PrinteraLogList logs={this.logItems}/>

            {task && task.subTasks && task.subTasks.length > 0 &&
            <IonButton
              fill='clear' size='default' expand='full'
              onClick={() => history.push(pathToSubTasks(task.id))}
            >
              подзадачи ({task.subTasks.length} / {task.subTasks.length - this.store.notCompleteSubTaskCount})
            </IonButton>}

            <IonListHeader>Файлы:</IonListHeader>
            {task.printeraTaskUploadedFiles && task.printeraTaskUploadedFiles.map(file =>
              <IonItem key={file.id}>
                <a
                  href={FILE_DOWNLOAD_URL + 'v2/files/' + file.fileDescriptor!.id + '?access_token=' + cubaREST.restApiToken}
                  target='_blank' rel='noopener noreferrer'>
                  <IonButton fill='clear'>
                    {(file.fileDescriptor as SerializedEntity<FileDescriptor>)._instanceName}
                  </IonButton>
                </a>
              </IonItem>
            )}
          </IonList>
          <IonListHeader>Действия:</IonListHeader>

          <IonButton color='light' expand='block' onClick={() => this.showCommentModal = true}>
            Добавить комментарий
            <IonIcon icon={text} slot='end'/>
          </IonButton>


          {this.transitionsButtons}

          {/* Task actions */}

          {this.transitionType && <TaskDoTransitionModal showModal={this.showTaskDoTransitionModal}
                                 transitionButton={this.transitionType}
                                 userId={this.props.userId}
                                 task={task}
                                 onClose={action(() => {
                                   this.showTaskDoTransitionModal = false;
                                 })}
                                 onSave={() => {
                                   this.props.reloadTasks();
                                   this.showTaskDoTransitionModal = false;
                                   history.push(TASKS);
                                 }}/>}

          {/* Comments */}

          <PrinteraCreateModal header={'Задача ' + task.number + ' - комментарий'}
                               caption='Комментарий'
                               show={this.showCommentModal}
                               saving={this.store.savingComment}
                               errorMsg={this.store.commentSaveError}
                               resetError={() => this.store.commentSaveError = undefined}
                               onClose={() => this.showCommentModal = false}
                               onSave={() => {
                                 this.store.saveComment()
                                   .then(() => {
                                     this.showCommentModal = false;
                                   });
                               }}>
            <IonItem>
              <IonTextarea value={this.store.newComment}
                           onIonChange={e => this.store.newComment = e.detail.value ? e.detail.value : ''}/>
            </IonItem>

            {commentFiles.map((fd, i) => //todo above could be extracted as common component
              <FileUploadItem key={i}
                              fileDescriptorInfo={fd}
                              onFileRemove={() => {
                                commentFiles[i] = undefined;
                                if (i < commentFiles.length - 1) {
                                  commentFiles.splice(i, 1)
                                }
                              }}
                              onFileUpload={(ufd) => {
                                commentFiles[i] = ufd;
                                commentFiles.push(undefined);
                              }}/>
            )}

          </PrinteraCreateModal>

          {/* Sub task creation */}

          <TaskCreateModal showModal={this.showCreateSubTaskModal}
                           deviceAssignmentId={undefined}
                           parentTaskId={task.id}
                           onClose={action(() => {
                             this.showCreateSubTaskModal = false;
                           })}
                           onSave={action(() => {
                             this.showCreateSubTaskModal = false;
                             //reload task to update sub tasks count
                             this.store.loadTask(this.props.match.params.taskId);
                           })}/>

        </IonContent>

        <IonAlert
          isOpen={this.errorMsg != null}
          onDidDismiss={() => this.errorMsg = undefined}
          buttons={['OK']}
          message={this.errorMsg}/>

        <IonToast
          isOpen={this.toastMessage != null}
          onDidDismiss={() => {
            this.toastMessage = undefined;
          }}
          message={this.toastMessage}
          duration={2000}/>
      </>
    );
  }

}
