import {action, computed, observable, reaction, runInAction, toJS} from "mobx";
import {cubaREST, deviceListStore, taskListStore, taskCreateStore} from "./index";
import {EntityFilter, PredefinedView, UserInfo} from "@cuba-platform/rest";
import {EntityListData} from "./data/entityListData";
import {createBrowserHistory, History} from "history";
import {Client} from "./cuba/entities/printers_Client";
import {Office} from "./cuba/entities/printers_Office";
import {Department} from "./cuba/entities/printers_Department";
import {DeviceState} from "./cuba/entities/printers_DeviceState";
import {PrinteraTaskType} from "./cuba/entities/printers_PrinteraTaskType";
import {Role} from "./cuba/enums";
import {interval, merge, Subject} from "rxjs";
import {filter} from "rxjs/operators";
import {ConsumablesType} from './cuba/entities/printers_ConsumablesType';

export type InitializationStatus = 'IN_PROGRESS' | 'COMPLETED' | 'FAILED' | 'INVALID_ROLE';
export type LoginStatus = 'IN_PROGRESS' | 'ERROR' | 'SUCCESS' | 'INVALID_ROLE';
//todo PrinteraUserInfo type from model
type PrinteraUserInfo = UserInfo & {permission: Role} & {clients?: Array<Client & {_instanceName: string}>};

export class MainStore {

  static readonly NAME = 'mainStore';
  private static readonly RELOAD_TASKS_MINUTES: number = 5;

  private static readonly CLIENT_STORAGE_KEY = 'PRINTERA_DEFAULT_CLIENT';
  private static readonly OFFICE_STORAGE_KEY = 'PRINTERA_DEFAULT_OFFICE';

  @observable initializationStatus: InitializationStatus = 'IN_PROGRESS';
  @observable loginStatus?: LoginStatus;
  @observable authenticated?: boolean;
  @observable userInfo?: PrinteraUserInfo;
  @observable client?: Client;
  @observable office?: Office;

  @observable.ref offices = new EntityListData<Office>(Office.NAME,  PredefinedView.MINIMAL, '+officeName');

  @observable.ref deviceStates = new EntityListData<DeviceState>(DeviceState.NAME);
  @observable.ref departments = new EntityListData<Department>(Department.NAME);
  @observable.ref taskTypesData = new EntityListData<PrinteraTaskType>(PrinteraTaskType.NAME, PredefinedView.LOCAL);
  @observable.ref consumablesTypeData = new EntityListData<ConsumablesType>(ConsumablesType.NAME, '_local');

  private taskReloadSubject = new Subject<void>();
  private readonly history: History;

  constructor() {
    this.history = createBrowserHistory();

    reaction(
      () => this.authenticated,
      () => {
        if (this.authenticated) {
          this.initializeAuthenticated();
        } else {
          taskListStore.clean();
        }
      }
    );
    reaction(
      () => this.client,
      (client) => this.reactOnClientChange(client)
    );
    reaction(
      () => ([this.client, this.office]),
      () => {
        deviceListStore.reloadDevices();
        this.reloadTasks();
      }
    );

    // Tasks Polling
    merge(
      this.taskReloadSubject.asObservable(),
      interval(MainStore.RELOAD_TASKS_MINUTES * 60 * 1000)
    )
      .pipe(
        filter(() => {
          return !!this.authenticated
        })
      )
      .subscribe(
        () => taskListStore.doReloadTasks()
      );
  }

  @action
  initialize() {
    this.initializationStatus = 'IN_PROGRESS';
    return loadUserInfo()
      .then((userInfo) => {
        runInAction(() => {
          this.userInfo = userInfo;
          this.authenticated = true;
          this.initializationStatus = "COMPLETED";
        })
      })
      .catch((err) => {
        runInAction(() => {
          this.authenticated = false;
          if (err.response && err.response.status === 401) {
            this.initializationStatus = "COMPLETED";
          } else if (err.response && err.response.status === 500) {
            this.initializationStatus = "INVALID_ROLE";
          } else {
            this.initializationStatus = "FAILED";
          }
        });
      })
  }

  @action
  login(login: string, password: string) {
    this.loginStatus = "IN_PROGRESS";
    cubaREST.login(login, password)
      .then(action(loadUserInfo))
      .then((userInfo) => {
        this.userInfo = userInfo;
        this.loginStatus = "SUCCESS";
        this.authenticated = true;
      })
      .catch((err) => {
        runInAction(() => {
          if (err.response && err.response.status === 500) {
            this.loginStatus = "INVALID_ROLE";
          } else {
            this.loginStatus = "ERROR";
          }
        });
      })
  }

  @action
  logout = () => {
    return cubaREST.logout().then(() => {
      this.authenticated = false;
      this.initializationStatus = 'COMPLETED';
      this.client = undefined;
      this.office = undefined;
      window.localStorage.removeItem(MainStore.CLIENT_STORAGE_KEY);
      window.localStorage.removeItem(MainStore.OFFICE_STORAGE_KEY);
      this.history.push('/');
    });
  };

  @action
  setDefaultClient = (client?: Client) => {
    this.client = client;
    if (!client) {
      window.localStorage.removeItem(MainStore.CLIENT_STORAGE_KEY);
    }
    window.localStorage.setItem(MainStore.CLIENT_STORAGE_KEY, JSON.stringify(client));
  };

  @action
  setDefaultOffice = (office?: Office) => {
    if (office) {
      this.office = office;
      window.localStorage.setItem(MainStore.OFFICE_STORAGE_KEY, JSON.stringify(office));
    }
  };

  @action resetOffice = () => {
    this.office = undefined;
    window.localStorage.removeItem(MainStore.OFFICE_STORAGE_KEY);
  };

  @action resetClient = () => {
    this.client = undefined;
    window.localStorage.removeItem(MainStore.CLIENT_STORAGE_KEY);
  };

  @computed get userId() {
    return this.userInfo ? this.userInfo.id : undefined;
  }

  @computed get role() {
    return (this.userInfo as PrinteraUserInfo).permission;
  }

  @computed get cartridgeConsumablesTypesEntities() {
    return this.consumablesTypeData.entities?.filter(cons => cons.isCartridge);
  }

  @action
  private initializeAuthenticated() {
    //we always should receive only one client in clients for 'client' roles
    if (this.role !== Role.FULL && this.userInfo && this.userInfo.clients) {
      this.setDefaultClient(this.userInfo.clients[0])
    }

    const persistedClient = localStorage.getItem(MainStore.CLIENT_STORAGE_KEY);
    const persistedOffice = localStorage.getItem(MainStore.OFFICE_STORAGE_KEY);
    if (persistedClient) {
      this.client = JSON.parse(persistedClient);
    }

    //check that saved office in list offices, if not - remove from saved
    if (persistedOffice) {
      this.office = JSON.parse(persistedOffice);
    }

    this.deviceStates.load();
    this.taskTypesData.load();
    this.consumablesTypeData.load();
    taskListStore.doReloadTasks();

    //devices loaded as reaction on client\office defined in constructor
    //if no client\office we need to load it manually on init
    if (!this.client && !this.office) deviceListStore.reloadDevices();

    if (this.role === Role.FULL) taskCreateStore.loadAvailableTaskAssignees();
  }

  @action
  private reactOnClientChange(client: any) {
    if (!client) {
      this.offices.clear();
      this.departments.clear();
      return;
    }
    const clientFilter: EntityFilter = {
      conditions: [{
        property: 'client.id',
        operator: "=",
        value: toJS(client.id)
      }]
    };
    this.offices.filter = clientFilter;
    this.offices.load();

    this.departments.filter = clientFilter;
    this.departments.load();
  }

  reloadTasks = (): void => {
    if (!this.userInfo) {
      return;
    }
    this.taskReloadSubject.next();
  };
}

function loadUserInfo() {
  return cubaREST.invokeService<PrinteraUserInfo>('printers_UserInfoService', 'getUserInfo', null, {handleAs: 'json'})
    .then((userInfo) => {
      if (userInfo.clients) {
        //todo workaround - server not returned instance name
        userInfo.clients.forEach(client => client._instanceName = client.clientName ? client.clientName : '');
      }
      return Promise.resolve<PrinteraUserInfo>(userInfo)
    });
}

export interface MainStoreInjected {
  mainStore: MainStore
}
