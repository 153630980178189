import React, {Dispatch, FormEvent, FunctionComponent, SetStateAction, useState} from 'react';
import {IonAlert, IonButton, IonContent, IonImg, IonInput, IonItem, IonList, IonSpinner} from "@ionic/react";
import {CenteredColumn} from "../common/Centered";
import {InputChangeEventDetail} from '@ionic/core';
import {LoginStatus} from "../../mainStore";

import logoSrc from './logo.svg';

interface Props {
  loginSubmit: (login: string, password: string) => any;
  loginStatus?: LoginStatus;
}


export const Login: FunctionComponent<Props> = ({loginSubmit, loginStatus}) => {

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleInputChange = (setState: Dispatch<SetStateAction<string>>) => (e: CustomEvent<InputChangeEventDetail>) => {
    setState(e.detail.value as string)
  };

  return (
    <>
      <IonContent>
        <CenteredColumn>
          <IonImg src={logoSrc}/>
          <form onSubmit={(e: FormEvent) => {
            e.preventDefault();
            loginSubmit(login, password)
          }}>
            <IonList>
              <IonItem>
                <IonInput placeholder="Логин"
                          onIonChange={handleInputChange(setLogin)}/>
              </IonItem>
              <IonItem>
                <IonInput placeholder="Пароль"
                          type="password"
                          onIonChange={handleInputChange(setPassword)}/>
              </IonItem>
            </IonList>
            <div>
              <IonButton expand='block'
                         type='submit'
                         disabled={loginStatus === "IN_PROGRESS"}>
                Войти
                {loginStatus === "IN_PROGRESS" && <IonSpinner slot='end'/>}
              </IonButton>
            </div>
          </form>
        </CenteredColumn>
      </IonContent>
      <IonAlert
        isOpen={loginStatus === "ERROR"}
        onDidDismiss={() => loginStatus = undefined}
        message={'Wrong login or password'}
        buttons={['OK']}/>
      <IonAlert
        isOpen={loginStatus === 'INVALID_ROLE'}
        onDidDismiss={() => loginStatus = undefined}
        message={'У вашего пользователя неправильно настроены права доступа в системе, свяжитесь с администратором'}
        buttons={['OK']}/>
    </>
  );
};
