import { StandardEntity } from "./base/sys$StandardEntity";
import { Location } from "./printers_Location";
import { Client } from "./printers_Client";
import { City } from "./printers_City";
import { PrinteraUser } from "./printers_PrinteraUser";
import { Warehouse } from "./printers_Warehouse";
export class Office extends StandardEntity {
  static NAME = "printers_Office";
  officeLocations?: Location[] | null;
  client?: Client | null;
  officeName?: string | null;
  actualAddress?: string | null;
  city?: City | null;
  printeraUsers?: PrinteraUser[] | null;
  warehouses?: Warehouse[] | null;
}
export type OfficeViewName = "_minimal" | "_local" | "_base" | "office-view";
export type OfficeView<V extends OfficeViewName> = V extends "_minimal"
  ? Pick<Office, "id" | "officeName">
  : V extends "_local"
  ? Pick<Office, "id" | "officeName" | "actualAddress">
  : V extends "_base"
  ? Pick<Office, "id" | "officeName" | "actualAddress">
  : V extends "office-view"
  ? Pick<
      Office,
      | "id"
      | "officeName"
      | "actualAddress"
      | "city"
      | "officeLocations"
      | "client"
    >
  : never;
