import {action, computed, observable} from "mobx";
import {EntityListData} from "../../../data/entityListData";
import {DeviceAssignment} from "../../../cuba/entities/printers_DeviceAssignment";
import {DeviceListFilterData} from "./DeviceListFilterData";
import {Condition, ConditionsGroup, EntityFilter, SerializedEntity} from "@cuba-platform/rest";
import {mainStore} from "../../../index";
import {isEmpty} from "../../../util/stringUtils";
import {contains, equal} from "../../../util/restUtils";
import {IdentificationStatus} from "../../../cuba/enums/enums";
import {deviceSortingComparator} from "../devicesCommon";

export class DeviceListStore {

  static readonly NAME = 'deviceListStore';
  private static readonly LOAD_LIMIT = 30;

  @observable deviceList: SerializedEntity<DeviceAssignment>[] = [];
  @observable hasMore = true;
  @observable.ref private devicesData = new EntityListData<DeviceAssignment>(DeviceAssignment.NAME);

  filterData: DeviceListFilterData = new DeviceListFilterData();

  @computed get loading() {
    return this.devicesData.status === "LOADING"
  }

  @action reloadDevices = (): void => {
    const client = mainStore.client;
    const office = mainStore.office;

    const filter: EntityFilter = {conditions: []};
    if (client) {
      filter.conditions.push({
        property: "client.id",
        operator: "=",
        value: client.id
      });
    }
    if (office) {
      filter.conditions.push({
        property: "office.id",
        operator: "=",
        value: office.id
      });
    }

    const idnStatusCG: ConditionsGroup = {
      group: "OR",
      conditions: [{
        property: "identificationStatus",
        operator: "<>",
        value: IdentificationStatus.INACTIVE_ASSIGNMENT
      }, {
        property: "endDateTime",
        operator: "=",
        value: ""
      }]
    };
    filter.conditions = [...filter.conditions, idnStatusCG, ...buildConditions(this.filterData)];

    this.devicesData.filter = filter;

    this.hasMore = true;
    this.devicesData.view = 'mobile-deviceAssignment-list';
    this.devicesData.sort = 'location.locationFloor';

    this.devicesData.load({limit: DeviceListStore.LOAD_LIMIT})
      .then(() => {
        this.deviceList = this.devicesData.entities ? this.devicesData.entities.slice().sort(deviceSortingComparator) : [];
        this.hasMore = this.deviceList.length === DeviceListStore.LOAD_LIMIT;
      })
      .catch(() => this.deviceList = [])
  };

  @action loadMore = () => {
    const limit = this.deviceList.length + DeviceListStore.LOAD_LIMIT;
    this.devicesData.load({limit: limit})
      .then(() => {
        const {entities} = this.devicesData;
        if (!entities || entities.length < this.deviceList.length + DeviceListStore.LOAD_LIMIT) {
          this.hasMore = false;
        }
        this.deviceList = entities ? entities.sort(deviceSortingComparator) : [];
      })
      .catch(() => this.deviceList = [])
  };

  @action applyFilter = (filter: DeviceListFilterData) => {
    this.filterData = filter;
    this.reloadDevices();
  };

  @action setSearchString = (s: string | undefined) => {
    this.filterData.searchString = s ? s : '';
  };

}

export interface DeviceListStoreInjected {
  deviceListStore: DeviceListStore
}


function buildConditions(filter: DeviceListFilterData): Array<ConditionsGroup | Condition> {

  const {searchString} = filter;
  const conditions = [];

  if (isEmpty(searchString) && !filter.isFilterSet) {
    return [];
  }

  if (!isEmpty(searchString)) {
    // PRINT-1308 search by serial or inventory number
    conditions.push(buildNumberCondition(searchString));
  }

  const {number, locationName, locationFloor, pollStatus, idnStatus} = filter;

  if (!isEmpty(number)) {
    const numberCG = buildNumberCondition(number);
    conditions.push(numberCG);
  }

  if (!isEmpty(locationName)) {
    conditions.push(contains('location.name', locationName));
  }

  if (!isEmpty(locationFloor)) {
    conditions.push(equal('location.locationFloor', locationFloor));
  }

  const filterConnectionType = filter.getConnectionType();
  if (!isEmpty(filterConnectionType)) {
    conditions.push(equal('connectionType', filterConnectionType));
  }

  if (!isEmpty(pollStatus)) {
    conditions.push(equal('pollStatus', pollStatus!));
  }

  if (!isEmpty(idnStatus)) {
    conditions.push(equal('identificationStatus', idnStatus!));
  }

  return conditions;
}

function buildNumberCondition(number: string) {
//search by deviceNumber OR serialNumber if deviceNumber not set
//todo fix after https://github.com/cuba-platform/restapi/issues/34
  const numberCG: ConditionsGroup = {
    group: 'OR',
    conditions: [
      contains('device.deviceNumber', number),
      contains('device.serialNumber', number),
    ]
  };
  return numberCG;
}


