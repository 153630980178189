import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {MainStoreInjected} from '../../../mainStore';
import {TaskListStoreInjected} from './taskListStore';
import {IonContent} from '@ionic/react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {pathToTask} from '../../../routes';
import {TaskCard} from './TaskCard';
import {action, computed, observable} from 'mobx';
import {PrinteraTask} from '../../../cuba/entities/printers_PrinteraTask';
import {EntityListData} from '../../../data/entityListData';
import {SerializedEntity} from '@cuba-platform/rest';
import {PrinteraHeader} from '../../common/PrineraHeader';

type Props = RouteComponentProps<{ parentTaskId: string }>

const mapStoresToProps = (stores: MainStoreInjected & TaskListStoreInjected) => ({
  role: stores.mainStore.role,
  taskTypesData: stores.mainStore.taskTypesData,
  clients: stores.mainStore.userInfo!.clients,
  getFilter: stores.taskListStore.getFilter,
  filterChange: stores.taskListStore.filterChange,
  filteredTasks: stores.taskListStore.filteredTasks,
  loading: stores.taskListStore.loading,
});

@inject(mapStoresToProps)
@observer
export class SubTaskList extends Component<ReturnType<typeof mapStoresToProps> & Props> {

  @observable isLoading = false;

  @observable tasksData = new EntityListData<PrinteraTask>(PrinteraTask.NAME,
    'printeraTask-mobile-list', '+deviceAssignment.location.name');

  @computed private get subTasks(): SerializedEntity<PrinteraTask>[] {
    return this.tasksData.entities ? this.tasksData.entities : [];
  }

  componentDidMount(): void {
    this.loadSubTasks(this.props.match.params.parentTaskId);
  }

  render() {
    return (<>
      <IonContent>

        <PrinteraHeader caption='Подзадачи' history={this.props.history}/>

        {this.subTasks.slice()
          .sort((a, b) => a.number - b.number)
          .map((task) => (
            <Link to={pathToTask(task.id)} key={task.id}>
              <TaskCard task={task} role={this.props.role}/>
          </Link>
        ))}
      </IonContent>
    </>);
  }

  @action private loadSubTasks = (parentTaskId: string) => {
    this.isLoading = true;

    const {tasksData} = this;
    tasksData.filter = {
      conditions: [
        {
          property: 'parentTask.id',
          operator: '=',
          value: parentTaskId
        }
      ]
    };

    tasksData.load()
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        //todo show error
        this.isLoading = false;
      })
  };

}
