import {Condition} from "@cuba-platform/rest";

export function contains(prop: string, val: string): Condition {
  return {
    property: prop,
    operator: 'contains',
    value: val
  }
}

export function equal(prop: string, val: string): Condition {
  return {
    property: prop,
    operator: '=',
    value: val
  }
}

export function isOffline(err: any) { return err && !err.response}