import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {MainStoreInjected} from "../../../mainStore";
import {IonButton, IonCheckbox, IonContent, IonItem, IonLabel} from "@ionic/react";
import {pathToTask} from "../../../routes";
import {Link} from "react-router-dom";
import {PrinteraEditItem} from "../../common/PrinteraEditItem";
import {TaskCard} from "./TaskCard";
import {PrinteraSelect} from "../../common/PrinteraSelect";
import {getEnumEntries, PrinteraTaskPriorityCaption, Role, TaskState, TaskStateCaption} from "../../../cuba/enums";
import {PrinteraListFilter} from "../../common/PrinteraListFilter";
import {PrinteraEnumSelect} from "../../common/PrinteraEnumSelect";
import {getNameById} from "../tasksCommon";
import {TaskListStoreInjected} from "./taskListStore";
import {isEmpty} from "../../../util/stringUtils";
import {TaskListFilter} from "./TaskListFilter";


const mapStoresToProps = (stores: MainStoreInjected & TaskListStoreInjected) => ({
  taskTypesData: stores.mainStore.taskTypesData,
  offices: stores.mainStore.offices,
  role: stores.mainStore.role,
  userInfo: stores.mainStore.userInfo,
  getFilter: stores.taskListStore.getFilter,
  filterChange: stores.taskListStore.filterChange,
  tasks: stores.taskListStore.tasks,
  loading: stores.taskListStore.loading,
  hasMore: stores.taskListStore.hasMore,
  loadMore: stores.taskListStore.loadMore
});

@inject(mapStoresToProps)
@observer
export class ClientTaskList extends Component<ReturnType<typeof mapStoresToProps>> {

  render() {

    const {filterChange, tasks, loading, hasMore, loadMore} = this.props;
    const {entities: taskTypes} = this.props.taskTypesData;
    const filter = this.props.getFilter();

    return (
      <>
        <IonContent>

          <PrinteraListFilter filter={filter} filterChange={filterChange} loading={loading}>

            <PrinteraSelect caption={'Офис'} value={filter.office.id} entities={this.props.offices.entities}
                            onValueChange={e => this.setFilterOffice(e, filter)}/>

            <PrinteraEditItem caption="Номер задачи" value={filter.taskNumber}
                              onValueChange={e => filter.taskNumber = e.detail.value}/>
            <PrinteraSelect caption="Вид задачи"
                            entities={taskTypes}
                            value={filter.taskType ? filter.taskType.id : null}
                            onValueChange={(e) => {
                              filter.taskType = taskTypes!.find(t => t.id === e.detail.value)
                            }}/>

            <PrinteraSelect caption='Состояние задачи' value={filter.taskState}
                            entities={getEnumEntries(TaskState, TaskStateCaption)}
                            onValueChange={e => filter.taskState = e.detail.value}/>

            <PrinteraEnumSelect caption='Приоритет' value={filter.priority}
                                captions={PrinteraTaskPriorityCaption}
                                onValueChange={e => filter.priority = e.detail.value}/>

            <PrinteraEditItem caption="Инв. номер" value={filter.deviceNumber}
                              onValueChange={e => filter.deviceNumber = e.detail.value}/>

            <PrinteraEditItem caption="Этаж" value={filter.floor}
                              onValueChange={e => filter.floor = e.detail.value}/>

            <PrinteraEditItem caption="Кабинет" value={filter.cabinet}
                              onValueChange={e => filter.cabinet = e.detail.value}/>

            {this.isClientAdmin &&
            <IonItem>
              <IonLabel>Только мои задачи</IonLabel>
              <IonCheckbox
                checked={filter.myTasksOnly}
                onIonChange={e => filter.myTasksOnly = e.detail.checked}/>
            </IonItem>}

          </PrinteraListFilter>

          {tasks.map((task) => (
            <Link to={pathToTask(task.id)} key={task.id}>
              <TaskCard task={task} role={this.props.role}/>
            </Link>
          ))}

          {hasMore && !loading &&
            <IonButton fill="clear" size="default" expand="full" onClick={() => loadMore()}>
              загрузить еще
            </IonButton>}
        </IonContent>
      </>
    );
  }

  get isClientAdmin() {
    return this.props.role === Role.CLIENT_ADMIN;
  }

  setFilterOffice = (e: CustomEvent, filter: TaskListFilter) => {
    const name = getNameById(e.detail.value, this.props.offices.entities);
    const id = e.detail.value;
    if (isEmpty(name) || isEmpty(id)) {
      filter.office = {id: '', name: ''}
    } else {
      filter.office = {
        id: e.detail.value,
        name: getNameById(e.detail.value, this.props.offices.entities)
      }
    }
  };

}

